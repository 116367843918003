import React from "react";
import { Link } from 'react-router-dom';

import styles from "./themeHero.module.less";

import InnerAgeAnimation from "./Animations/innerAgeAnimation/innerAgeAnimation";
import ApobBox from "./Animations/apobBox/apobBox";
import LdlBox from "./Animations/ldlBox/ldlBox";
import FiberFruits from "./Animations/fiberFruits/fiberFruits";

export default function Hero({
  heroTitle,
  heroText,
  heroBtn,
  heroAnimationImage,
  animationImages,
}) {
  const {
    graphImageApob,
    graphImageLdl,
    highIntensityImage,
  } = animationImages;
  return (
    <div className={`${styles.themeHero}`}>
      <div className={`${styles.themeHeroContainer}`}>
        <div className={`${styles.themeHeroContent}`}>
          <div className={`${styles.themeHeroContentInner}`}>
            <h1
              className={`${styles.themeHeroHeading}`}
              dangerouslySetInnerHTML={{ __html: heroTitle }}
              initial={{
                y: "10px",
                opacity: 0,
              }}
              animate={{
                y: "0px",
                opacity: 1,
              }}
              transition={{
                duration: 0.2,
                ease: "easeInOut",
                delay: 0.2,
              }}
            />
            <p
              className={`${styles.themeHeroText}`}
            >
              {heroText}
            </p>
            {
              heroBtn.isInternal
                ? <Link to={heroBtn.pageName} className={`${styles.themeHeroBtn}`} >{heroBtn.heroBtnLabel}</Link>
                : <a href={heroBtn.heroBtnUrl} className={`${styles.themeHeroBtn}`}>{heroBtn.heroBtnLabel}</a>
            }
          </div>
        </div>
        <div className={`${styles.themeHeroAnimation}`}>
          <img src={heroAnimationImage} alt={heroTitle} className={`${styles.heroAnimationImage}`} />
          <InnerAgeAnimation />
          <ApobBox graphImageApob={graphImageApob} />
          <LdlBox graphImageLdl={graphImageLdl} />
          <FiberFruits highIntensityImage={highIntensityImage} />
        </div>
      </div>
    </div>
  );
}

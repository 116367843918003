const deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";

const criteoPages = [
    '/ultimate',
    '/home-kit',
    '/essentials',
    '/customer/diy/upload-blood-result',
    '/public/landing/dna-details'
]

export const initCriteo = () => {
    const js = document.createElement("script");

    js.type = "text/javascript";
    js.src = '//static.criteo.net/js/ld/ld.js';
    js.async = true;

    document.body.appendChild(js);

    window.criteo_q = window.criteo_q || [];
}

export const pushCriteoRoute = () => {
    const isCriteoProductPage = criteoPages.some(route => location.pathname.startsWith(route));
    const viewItem = isCriteoProductPage ? {event: "viewItem", item: "1"} : {event: "viewHome"};
    window.criteo_q?.push(
        {event: "setAccount", account: 83854},
        {event: "setSiteType", type: deviceType},
        viewItem
    );
}

import React, { useState } from "react";

import styles from "./themeAccrdion.module.less";

export default function Accordion({ accordion }) {
  const gifImages = accordion.map((item) => item.gifImage);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeImage, setActiveImage] = useState(gifImages[activeIndex]);

  const handleAccordion = (index) => {
    setActiveIndex((prev) => (prev === index ? -1 : index));
    setActiveImage(gifImages[index]);
  };

  return (
    <div className={`${styles.accordionWrapper}`}>
      <div className={`${styles.accordionColumn} ${activeImage === undefined && styles.accordionColumnFull}`}>
        {
          accordion.map((item, index) => (
            <div key={item.question} className={`${styles.accordionItem} ${activeIndex === index && `${styles.isOpen}`}`}>
              { item.icon && <img src={item.icon} alt={item.question} className={`${styles.accordionIcon}`} /> }
              <div className={`${styles.accordionQuestion}`} onClick={() => handleAccordion(index)} aria-hidden="true">
                {item.question}
                {
                  activeIndex === index
                    ? (
                      <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.91038 0.221653L0.154951 7.29272L0.830491 7.99983L6.91038 1.63586L12.9905 8L13.666 7.29289L7.58593 0.928763L6.91038 0.221653Z" fill="#232D37"/>
                      </svg>
                    )
                    : (
                      <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.09157 8.27835L13.847 1.20728L13.1715 0.500173L7.09157 6.86414L1.01148 0.5L0.335938 1.20711L6.41602 7.57124L7.09157 8.27835Z" fill="#232D37"/>
                      </svg>
                    )
                }
              </div>
              <div className={`${styles.accordionAnswer}`} dangerouslySetInnerHTML={{ __html: item.answer }} />
            </div>
          ))
        }
      </div>
      {activeImage !== undefined
      && (
        <div className={`${styles.accordionColumn} ${styles.accordionColumnImage}`}>
          <img src={activeImage} alt="Accordion DropDown" />
        </div>
      )}
    </div>
  );
}

export const STATE_LOW = -4;
export const STATE_BORDERLINE_LOW = -3;
export const STATE_NEAR_NORMAL_LOW = -2;
export const STATE_NORMAL_LOW = -1;
export const STATE_OPTIMIZED = 0;
export const STATE_NORMAL_HIGH = 1;
export const STATE_NEAR_NORMAL_HIGH = 2;
export const STATE_BORDERLINE_HIGH = 3;
export const STATE_HIGH = 4;
export const CANNOT_DETERMINE = 7;

export const optimizationStatusNames = {
  [STATE_LOW]: 'Low',
  [STATE_BORDERLINE_LOW]: 'Borderline Low',
  [STATE_NEAR_NORMAL_LOW]: 'Near Normal',
  [STATE_NORMAL_LOW]: 'Normal',
  [STATE_OPTIMIZED]: 'Optimized',
  [STATE_NORMAL_HIGH]: 'Normal',
  [STATE_NEAR_NORMAL_HIGH]: 'Near Normal',
  [STATE_BORDERLINE_HIGH]: 'Borderline High',
  [STATE_HIGH]: 'High',
  [CANNOT_DETERMINE]: 'Cannot determine',
};

export type OptimizationState = keyof typeof optimizationStatusNames;

// Must be sequential integers starting from 0
export type ScoreState = 0 | 1 | 2 | 3;

export const scoreStateNames = {
  0: 'Optimal',
  1: 'Good',
  2: 'Fair',
  3: 'Needs work',
};
export const OPTIMIZED_STATE = 'optimized';
export const UNOPTIMIZED_STATE = 'notOptimized';
export const NEVER_TESTED_STATE = 'neverTested';

import React from 'react';

import styles from "./themeTeamCard.module.less";

export default function TeamCard({
  image,
  name,
  title,
  titleBgColor,
  titleColor,
  contentColor,
  button,
  buttonLink,
  buttonColor
}) {
  return (
    <div className={`${styles.teamCard}`} style={{ "--team-content-color": contentColor }}>
      <div className={`${styles.teamCardImage}`}>
        <img src={image} alt={name} />
      </div>
      <div className={`${styles.teamCardInfo}`}>
        <div className={`${styles.teamCardName}`} style={{ "--title-bg-color": titleBgColor, "--title-color": titleColor }}>{name}</div>
        <div className={`${styles.teamCardTitle}`}>
          {title}
          { buttonLink && <a href={buttonLink} className={`${styles.teamCardLink}`} style={{ "--team-button-color": buttonColor }}>{button}</a>}
        </div>
      </div>
    </div>
  );
}

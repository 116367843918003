import React, { useEffect, useState } from 'react';

/* Styles */
import { useDispatch, useSelector } from 'react-redux';
import {
  Route, Switch, useHistory,
} from 'react-router-dom';
import styles from './Header.module.less';

/* Components */
import LogoLink from './parts/LogoLink/LogoLink';
import Sandwich from './parts/Navigation/Sandwich/Sandwich';
import MobileMenuDots from './parts/MobileMenu/MobileMenuDots/MobileMenuDots';
import CloseBlock from '../CloseBlock/CloseBlock';
import MobileMenu from './parts/MobileMenu/MobileMenu';
import { userActivationStates } from '../../Constants/User';
import PublicNavigation from './parts/Navigation/Navigation';
import CustomerNavigation, { AskItIcon } from './parts/Navigation/CustomerNavigation';
import AuthPanel from './parts/AuthPanel/AuthPanel';
import mainActionLinks, { loginLogoutActionLinks } from './parts/AuthPanel/parts/ActionLinks';
import ActionButtons from './parts/AuthPanel/parts/ActionButtons';
import { notificationTagsSelector, showAIPopupSelector, userSelector } from '../../store/user/selectors';
import { adminSelector } from '../../store/admin/selectors';
import { configurationSelector, isRestrictedPopupOpen } from '../../store/common/selectors';
import Button from '../Buttons/Button';
import { onKeyEnter } from '../../helpers/onKeyEnter';
import AskAiBanner from "../../pages/customer/AskAi/AskAiBanner/AskAiBanner";
import Link from "../Link/Link";
import useMobile from "../../hooks/useMobile";
import IntroduceAiPopup from "../../pages/customer/AskAi/Popups/IntroduceAiPopup";
import dnaApi from "../../services/Customer/dnaApi";
import { setShowAiPopup } from "../../store/user";
import routes from "../../references/routes";
import { fetchNotificationTags } from "../../store/user/actions";
import Popup from "../Popup/Popup";
import config from '../../config';

const stopPropaganator = (e: any) => e.stopPropagation();
export const closeMobileMenuEvent = new Event('closeMobileMenu');

/**
 *  Header base component
 *  @category Header
 *  @component
 *  @hideconstructor
 */
const Header = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showPermissionPopup, setShowPermissionPopup] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const user = useSelector(userSelector);
  const hasAdminToken = localStorage.getItem('adminToken');
  const admin = useSelector(adminSelector);
  const notificationsTags = useSelector(notificationTagsSelector);
  const showAIPopup = useSelector(showAIPopupSelector);
  const history = useHistory();
  const configuration = useSelector(configurationSelector);
  const restrictedPopupOpen = useSelector(isRestrictedPopupOpen);
  const isMobile = useMobile(1100);
  /**
   * Click event handler that show sandwich
   */
  const onClickShow = (e: any) => {
    document.body.classList.add(styles.noscroll);
    setShow(true);
    e.stopPropagation();
  };

  /**
   * Click event handler that close sandwich
   */
  const onClickClose = () => {
    document.body.classList.remove(styles.noscroll);
    setShow(false);
  };

  /**
   * Click event handler that show mobile menu
   */
  const onClickShowMobileMenu = (e: any) => {
    document.body.classList.add(styles.noscroll);
    setShowMobileMenu(true);
    e.stopPropagation();
  };

  /**
   * Click event handler that close mobile menu
   */
  const onClickCloseMobileMenu = () => {
    document.body.classList.remove(styles.noscroll);
    setShowMobileMenu(false);
  };

  const handleCloseMobileMenu = () => {
    onClickCloseMobileMenu();
    onClickClose();
  };

  useEffect(() => {
    document.addEventListener('closeMobileMenu', handleCloseMobileMenu);
    return () => {
      document.body.classList.remove(styles.noscroll);
      document.removeEventListener('closeMobileMenu', handleCloseMobileMenu);
    };
  }, []);
  const publicPageUrls = [
    '/science/',
    '/about/',
    '/testimonial/',
    '/team/',
    '/innerage/',
  ];

  const isLoggedIn = user !== null;
  let showNavbar = true;
  if (isLoggedIn && user.activationState !== userActivationStates.active) {
    showNavbar = false;
  }

  const homePage = admin ? `/${admin.role.homePage}` : '/';
  const askITFirstTime = notificationsTags?.includes('suggest-to-see-ask-section');
  const updatePage = () => {
    dispatch(fetchNotificationTags());
  };

  const logoutPermissionGranted = !!localStorage.getItem('logoutPermissionGranted');
  const logout = () => {
    const shopifyLogoutUrl = config.isProduction
      ? "https://store.insidetracker.com/account/logout"
      : "https://test-segterra.myshopify.com/account/logout";

    const wnd = window.open(shopifyLogoutUrl);
    wnd.blur();
    setTimeout(() => {
      wnd.close();
    }, 1000);
    localStorage.setItem('logoutPermissionGranted', 'true');
    setShowPermissionPopup(false);
    history.push('/customer/login/logout');
  };

  const handleLogout = () => {
    history.push('/customer/login/logout');
    setShowPermissionPopup(false);
  };

  const onLogOut = () => {
    if (!logoutPermissionGranted) {
      setShowPermissionPopup(true);
    } else {
      logout();
    }
  };

  const onAllowPermission = () => {
    logout();
  };

  return (
    <>
      {/* <Spinner show={loading}/> */}
      <header className={`${styles.header} ${restrictedPopupOpen ? ` ${styles.headerOver}` : ''}`}>
        <div className={styles.headerContainer}>
          {hasAdminToken
          && (
            <Button
              id="header-dashboard"
              green
              onClick={() => {
                history.push(homePage);
              }}
              className={styles.adminDashboardBtn}
            >
              Dashboard
            </Button>
          )}
          <LogoLink/>
          {isMobile && user && (
            <Link
              id="askItMobileLink"
              onClick={(e) => {
                if (askITFirstTime) {
                  e.preventDefault();
                  dispatch(setShowAiPopup(true));
                } else {
                  history.push(routes.customer.askIt);
                }
              }}
              className={styles.askItMobile}
            >
              <AskItIcon/>
                {askITFirstTime && <span className={styles.dot}/>}
            </Link>
          )}
          <Switch>

            {/* login public desktop */}
            <Route
              path={['/customer/onboarding/choose-plan']}
              render={() => (
                <PublicNavigation user={user} mobile={false}/>
              )}
            />

            {/* login customer desktop */}
            <Route
              path="/customer*"
              render={() => (
                <>
                  {isLoggedIn && showNavbar && <CustomerNavigation user={user} mobile={false}/>}
                  {/* logout public desktop */}
                  {!isLoggedIn && <PublicNavigation user={user} mobile={false}/>}
                </>
              )}
            />

            {/* logout public desktop */}
            <Route>
              <PublicNavigation user={user}/>
            </Route>
            {/* logout public desktop end */}

          </Switch>
          <Switch>
            <Route path="/customer/login/"/>

            {/* login auth-panel desktop on public pages */}
            <Route
              path={['/customer/onboarding/choose-plan']}
              render={() => (
                <ActionButtons
                  user={user}
                  history={history}
                  isLoggedIn={isLoggedIn}
                  publicPages
                />
              )}
            />
            {/* login auth-panel desktop on public pages end */}

            {/* login auth-panel desktop */}
            <Route
              path="/customer/"
              render={() => {
                if (isLoggedIn) {
                  return <AuthPanel user={user} history={history} onLogOut={onLogOut}/>;
                }
                return (
                  <ActionButtons
                    user={user}
                    history={history}
                    isLoggedIn={isLoggedIn}
                    publicPages={false}
                  />
                );
              }}
            />
            {/* login auth-panel desktop end */}

            {/* login/logout auth-panel and action buttons desktop on public pages */}
            <Route render={() => (
              <ActionButtons
                user={user}
                history={history}
                isLoggedIn={isLoggedIn}
                publicPages
              />
            )}
            />
            {/* login/logout auth-panel desktop on public pages action buttons end */}
          </Switch>
          <Sandwich onClick={onClickShow}/>
          {/* right panel mobile menu */}
          <Route path={publicPageUrls} exact>
            <MobileMenuDots onClick={onClickShowMobileMenu}/>
            <div
              role="button"
              tabIndex={0}
              className={`${styles.overlay}${showMobileMenu ? ` ${styles.show}` : ''}`}
              onClick={onClickCloseMobileMenu}
              onKeyDown={onKeyEnter(onClickCloseMobileMenu)}
            >
              <div
                role="button"
                tabIndex={0}
                className={`${styles.sidebar} ${styles.sidebarMenu}${showMobileMenu ? ` ${styles.show}` : ''}`}
                onClick={stopPropaganator}
                onKeyDown={onKeyEnter(stopPropaganator)}
              >
                <div>
                  <CloseBlock onClick={onClickCloseMobileMenu}/>
                  <Route path="/science/">
                    <MobileMenu page="science"/>
                  </Route>
                  <Route path="/about/">
                    <MobileMenu page="about"/>
                  </Route>
                  <Route path="/testimonial/">
                    <MobileMenu page="testimonials"/>
                  </Route>
                  <Route path="/team/">
                    <MobileMenu page="team"/>
                  </Route>
                  <Route path="/innerage/">
                    <MobileMenu page="innerage"/>
                  </Route>
                </div>
              </div>
            </div>
          </Route>
          {/* right panel mobile menu end */}
        </div>
        <div
          role="button"
          tabIndex={0}
          className={`${styles.overlay}${show ? ` ${styles.show}` : ''}`}
          onClick={onClickClose}
          onKeyDown={onKeyEnter(onClickClose)}
        >
          <div
            role="button"
            tabIndex={0}
            className={`${styles.sidebar}${show ? ` ${styles.show}` : ''}`}
            onClick={stopPropaganator}
            onKeyDown={onKeyEnter(stopPropaganator)}
          >
            <div className={styles.sidebarWrapper}>
              {isLoggedIn
              && (
                <div className={styles.userInfo}>
                  <div className={styles.userInfoData}>
                    {user.photoSrc
                    && (
                      <div
                        className={styles.userInfoAvatar}
                        style={{ backgroundImage: `url(/uploads/customer_photo/${user.photoSrc}` }}
                      />
                    )}
                    {!user.photoSrc && (
                      <p className={styles.userInfoNameInitials}>
                        {user.firstName.charAt(0) + user.lastName.charAt(0)}
                      </p>
                    )}
                    <p className={styles.userInfoName}>
                      {user.firstName}
                      {' '}
                      {user.lastName}
                    </p>
                  </div>
                </div>
              )}
              <div className={styles.userInfoButtons}>
                {mainActionLinks(history, user, configuration)}
                {loginLogoutActionLinks(history, user)}
              </div>
              <div className={styles.sidebarList}>
                <Switch>
                  <Route
                    path="/customer*"
                    render={() => (
                      <>
                        {isLoggedIn && showNavbar
                        && <CustomerNavigation user={user} mobile/>}
                        {!isLoggedIn && <PublicNavigation user={user} mobile/>}
                      </>
                    )}
                  />
                  <Route>
                    <PublicNavigation noFooter={isLoggedIn} user={user} mobile/>
                    {isLoggedIn && showNavbar && <CustomerNavigation user={user} mobile/>}
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </header>
      {user && <AskAiBanner/>}
      {showAIPopup && (
      <IntroduceAiPopup
        show={showAIPopup}
        onClosePopup={async () => {
          dispatch(setShowAiPopup(false));
          await dnaApi.putNotificationsTags('suggest-to-see-ask-section');
          updatePage();
        }}
      />
      )}
      {showPermissionPopup && (
      <Popup
        onClose={handleLogout}
        open={showPermissionPopup}
        popupClasses={styles.PermissionPopup}
        maxWidth={450}
        indent
        showCloseBlock
      >
        <h2 className={styles.PermissionPopupTitle}>Permission required</h2>
        <p className={styles.PermissionPopupText}>
          Since you are logging out of your InsideTracker account, we would like
          to open a new tab so that we can also log you out of the InsideTracker store.
        </p>
        <div className={styles.PermissionPopupBtnBlock}>
          <Button
            id="allow-logout"
            onClick={onAllowPermission}
            text="Allow"
            className={styles.PermissionPopupBtn}
            green
            superLarge
          />
          <Button
            id="cancel-logout"
            onClick={handleLogout}
            text="Cancel"
            className={styles.PermissionPopupBtn}
            secondary
            superLarge
          />
        </div>
      </Popup>
      )}
    </>
  );
};

export default Header;

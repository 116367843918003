import throttle from 'lodash.throttle';
import { createBaseAxios } from '../baseAxios';
import CustomerAuthTokenProvider from './customerAuthTokenProvider';
import authApi from './authApi';
import config from '../../config';

export const fetchJwtToken = throttle(async () => {
  try {
    if (config.isWebpackDevServer) {
      const res = await authApi.getJwtTokenByRefresh(CustomerAuthTokenProvider.getRefreshToken());
      CustomerAuthTokenProvider.update(res.access_token, res.expires_in, res.refresh_token);
    } else {
      const res = await authApi.getJwtTokenByCookies();
      CustomerAuthTokenProvider.update(res.access_token, res.expires_in);
    }
  } catch {
  }
}, 5000);

const myPlanAuthorizedAxios = createBaseAxios();

myPlanAuthorizedAxios.interceptors.request.use(async (config) => {
  let token = CustomerAuthTokenProvider.get();
  const expiresIn = CustomerAuthTokenProvider.getSecondsToExpiration();

  if (expiresIn < 0) {
    await fetchJwtToken();
    token = CustomerAuthTokenProvider.get();
  } else if (expiresIn < 300) {
    // fetch new token 5 mins before expiration
    fetchJwtToken();
  }

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default myPlanAuthorizedAxios;

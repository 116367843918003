import baseAxios from './baseAxios';
import ReferralEngagements from '../interfaces/Public/ReferralEngagement';

export default {
  getLocationData: (): Promise<{
    ip: string,
    country: string,
    countryCode: string,
    city: string,
  }> => baseAxios.get('/api/pages/publicPage/userLocation', { timeout: 5000 })
    .then((response) => response.data),

  getReferralEngagements: (): Promise<ReferralEngagements> => baseAxios.get('/api/pages/publicPage/referralEngagements')
    .then((response) => response.data),
};

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Spinner from '../../../components/Spinner/Spinner';
import shopifyStoreApi from '../../../services/Customer/shopifyStoreApi';

const ShopifyStoreRedirecter = () => {
  const location = useLocation();

  const redirectByUri = async (redirectUri: string) => {
    try {
      const linkToShopify = await shopifyStoreApi.getAuthorizedLinkToShopifyStore(redirectUri);
      console.log(`Redirect to ${linkToShopify}`);
      window.location.replace(linkToShopify);
    } catch (e) {
      window.location.reload();
      console.error('Cannot redirect to shopify. Customer will be redirected by common way...');
    }
  };

  useEffect(() => {
    const redirectUri = new URLSearchParams(location.search).get('url');
    redirectByUri(redirectUri);
  }, []);

  return <Spinner mini show />;
};

export default ShopifyStoreRedirecter;

import React from "react";

export default function Heading({
  text,
  type,
  attributes,
}) {
  switch (type) {
    case "h2":
      return <h2 {...attributes}>{text}</h2>;
    case "h3":
      return <h3 {...attributes}>{text}</h3>;
    case "h4":
      return <h4 {...attributes}>{text}</h4>;
    case "h5":
      return <h5 {...attributes}>{text}</h5>;
    case "h6":
      return <h6 {...attributes}>{text}</h6>;
    default:
      return <h1 {...attributes}>{text}</h1>;
  }
}

import baseAxios from '../baseAxios';
import AuthToken from '../../interfaces/Auth/AuthToken';
import { GetJwtResponse } from '../../interfaces/Auth/JwtToken';

type LoginResponseData = {
  token: AuthToken,
  jwtToken: GetJwtResponse,
  redirect: string,
  needToAskAboutGenderIdentity: boolean,
  needToShowExplanationBanner: boolean,
};
export default {
  getJwtTokenByCookies: (): Promise<GetJwtResponse> => baseAxios.get('/api/customer/auth/jwt-token')
    .then((responseData) => responseData.data),

  getJwtTokenByRefresh: (token: string): Promise<GetJwtResponse> => baseAxios.post('/customer/oauth/token', {
    refresh_token: token,
    client_id: "mobileApp",
    grant_type: "refresh_token",
  })
    .then((responseData) => responseData.data),

  loginByEmail: (data: {
    email: string,
    password: string,
    stayLoggedIn: string,
    recaptchaToken: string,
    recaptchaAction: string,
  }): Promise<LoginResponseData> => baseAxios.post('/api/pages/customerAuth/login', data)
    .then((response: any) => response.data),

  logout: () => baseAxios.get('/api/pages/customerAuth/logout'),

  recoverPassword: (data: {
    email: string,
    recaptchaToken: string,
    recaptchaAction: string,
  }) => baseAxios.post('/api/pages/customerAuth/recoverPassword', data)
    .then((response: any) => response.data),

  securityQuestion: (data: {
    guid: string,
  }) => baseAxios.post('/api/pages/customerAuth/securityQuestion', data)
    .then((response: any) => response.data?.question),

  recoveryQuestionProcess: (data: {
    guid: string,
    securityAnswer: string,
  }) => baseAxios.post('/api/customer/auth/answer-check', data)
    .then((response: any) => response.data),

  checkGuid: (data: {
    guid: string,
  }) => baseAxios.post('/api/customer/auth/guid-check', data)
    .then((response: any) => response.data),

  resetPassword: (data: {
    guid: string,
    newPasswordConfirmed: string,
    newPassword: string,
    securityAnswer: string,
  }) => baseAxios.post('/api/customer/auth/reset-password', data)
    .then((response: any) => response.data),
};

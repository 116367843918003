import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

/**
 * WrappedLink types definitions
 * temp component to solve issue of using Link component in tpl standalone React components
 */
type WrappedLinkPropsTypes = {
  to: string,
  className?: string,
  id?: string,
  children: ReactNode,
  style?: React.CSSProperties,
};

// This component is obsolete. Use frontend/components/Link/Link.tsx
const WrappedLink = (props: WrappedLinkPropsTypes) => {
  const {
    children, className, to, id, style,
  } = props;

  return (
    <Link
      className={className}
      to={to}
      id={id}
      style={style}
    >
      {children}
    </Link>
  );
};

export default WrappedLink;

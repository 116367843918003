import { Method } from 'axios';
import userAuthorizedAxios from "./userAuthorizedAxios";

const customerAuthorizedFileDownloader = (
  url: string,
  data: any,
  fileName: string | null = null,
  method: Method = 'POST',
  type: string | null = null,
) => userAuthorizedAxios(url, {
  data,
  responseType: 'blob',
  method,
})
  .then((response) => {
    const link = document.createElement('a');

    if (type) {
      link.href = window.URL.createObjectURL(new Blob([response.data], { type }));
    } else {
      link.href = window.URL.createObjectURL(new Blob([response.data]));
    }

    if (fileName === null) {
      link.setAttribute('download', response.headers['content-disposition'].split('"')[1] || fileName);
    } else {
      link.setAttribute('download', fileName);
    }
    document.body.appendChild(link);
    link.click();
  });

export default customerAuthorizedFileDownloader;

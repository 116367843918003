import React from 'react';
/* Styles */
import { Link } from 'react-router-dom';
import styles from '../../../Header.module.less';

/**
 * AuthPanelButton props types definitions
 */
export type AuthPanelButtonPropsTypes = {
  /** CLick event handler */
  onClick?: () => void,
  /** Button visible text */
  name: React.ReactNode,
  /** Is it link */
  link?: string,
  /** Blue colored */
  blue?: boolean,
  id?: string,
};

/**
 * @category Header
 * @subcategory Auth panel
 * @borrows AuthPanelButtonPropsTypes as props
 * @component
 * @hideconstructor
 * @example
 * return <AuthPanelButton onClick={() => {alert('Click')}} name="Button"/>
 */
const AuthPanelButton = (props: AuthPanelButtonPropsTypes) => {
  const { name } = props;
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    props.onClick();
  };
  return (
    <a
      href="!#"
      className={styles.linkHeader}
      onClick={handleClick}
    >
      {name}
    </a>
  );
};

export const AuthPanelButtonLink = (props: AuthPanelButtonPropsTypes) => {
  const {
    name, link, onClick, id,
  } = props;
  return (
    <Link
      to={link}
      className={styles.linkHeader}
      onClick={onClick}
      id={id}
    >
      {name}
    </Link>
  );
};

export default AuthPanelButton;

import React from 'react';
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from './AskAiBanner.module.less';
import Button from "../../../../components/Buttons/Button";
import Icon from "../../../../components/Icons/Icon";
import useMobile from "../../../../hooks/useMobile";
import { setShowAiPopup } from "../../../../store/user";
import { notificationTagsSelector, showAIPopupSelector } from "../../../../store/user/selectors";
import IntroduceAiPopup from "../Popups/IntroduceAiPopup";
import dnaApi from "../../../../services/Customer/dnaApi";
import routes from "../../../../references/routes";
import { fetchNotificationTags } from "../../../../store/user/actions";

const TwinkleIcon = () => (
  <svg className={styles.AskAiBannerIcon} xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
    <path
      opacity="0.05"
      d="M45.8218 85.5C67.7729 85.5 85.5678 67.7051 85.5678 45.7539C85.5678 23.8027 67.7729 6.00781 45.8218 6.00781C23.8706 6.00781 6.07568 23.8027 6.07568 45.7539C6.07568 67.7051 23.8706 85.5 45.8218 85.5Z"
      fill="#A0D7AF"
    />
    <path
      opacity="0.1"
      d="M45.0717 77.1435C63.6129 77.1435 78.6435 62.1129 78.6435 43.5717C78.6435 25.0306 63.6129 10 45.0717 10C26.5306 10 11.5 25.0306 11.5 43.5717C11.5 62.1129 26.5306 77.1435 45.0717 77.1435Z"
      fill="#A0D7AF"
    />
    <path
      opacity="0.1"
      d="M45.1615 72.4153C59.8863 72.4153 71.823 60.4786 71.823 45.7538C71.823 31.0291 59.8863 19.0923 45.1615 19.0923C30.4368 19.0923 18.5 31.0291 18.5 45.7538C18.5 60.4786 30.4368 72.4153 45.1615 72.4153Z"
      fill="#A0D7AF"
    />
    <path
      d="M56.2963 71.2873C56.6797 72.7941 58.8202 72.7941 59.2037 71.2873L60.6 65.8L66.0691 64.4567C67.5921 64.0826 67.5921 61.9174 66.0691 61.5433L60.6 60.2L59.2037 54.7127C58.8202 53.2059 56.6797 53.2059 56.2963 54.7127L54.9 60.2L49.4309 61.5433C47.9079 61.9174 47.9079 64.0826 49.4309 64.4567L54.9 65.8L56.2963 71.2873Z"
      fill="white"
    />
    <path
      d="M59.5581 64.2125L59.1216 64.3216L59.0124 64.7582L57.85 69.4078L56.6876 64.7582L56.5785 64.3216L56.1419 64.2125L51.4924 63.0501L56.1419 61.8877L56.5785 61.7786L56.6876 61.342L57.85 56.6924L59.0124 61.342L59.1216 61.7786L59.5581 61.8877L64.2077 63.0501L59.5581 64.2125Z"
      fill="#A0D7AF"
      stroke="#232D37"
      strokeWidth="1.5"
    />
    <path
      d="M60.0597 45.2389C60.5647 47.2588 63.4353 47.2588 63.9403 45.2389L65.9 37.4L73.7389 35.4403C75.7588 34.9353 75.7588 32.0647 73.7389 31.5597L65.9 29.6L63.9403 21.7611C63.4353 19.7412 60.5647 19.7412 60.0597 21.7611L58.1 29.6L50.2611 31.5597C48.2412 32.0647 48.2412 34.9353 50.2611 35.4403L58.1 37.4L60.0597 45.2389Z"
      fill="white"
    />
    <path
      d="M64.8382 35.5924L64.4016 35.7015L64.2925 36.1381L62.2926 44.1375C62.2722 44.2193 62.2366 44.258 62.2056 44.2805C62.1685 44.3074 62.1141 44.3269 62.0501 44.3269C61.986 44.3269 61.9316 44.3074 61.8945 44.2805C61.8636 44.258 61.828 44.2193 61.8075 44.1375L59.8077 36.1381L59.6985 35.7015L59.262 35.5924L51.2625 33.5925C51.1807 33.5721 51.1421 33.5365 51.1196 33.5055C51.0927 33.4684 51.0732 33.4141 51.0732 33.35C51.0732 33.2859 51.0927 33.2316 51.1196 33.1945C51.1421 33.1635 51.1807 33.1279 51.2625 33.1075L59.262 31.1076L59.6985 30.9985L59.8077 30.5619L61.8075 22.5625C61.828 22.4807 61.8636 22.442 61.8945 22.4195C61.9316 22.3926 61.986 22.3731 62.0501 22.3731C62.1141 22.3731 62.1685 22.3926 62.2056 22.4195C62.2366 22.442 62.2722 22.4807 62.2926 22.5625L64.2925 30.5619L64.4016 30.9985L64.8382 31.1076L72.8376 33.1075C72.9194 33.1279 72.9581 33.1635 72.9805 33.1945C73.0075 33.2316 73.027 33.2859 73.027 33.35C73.027 33.4141 73.0075 33.4684 72.9805 33.5055C72.9581 33.5365 72.9194 33.5721 72.8376 33.5925L64.8382 35.5924Z"
      fill="#A0D7AF"
      stroke="#232D37"
      strokeWidth="1.5"
    />
    <path
      d="M34.442 66.9321C35.1428 68.5087 37.3773 68.5177 38.0907 66.9467L43.8484 54.2676L56.4623 48.5729C58.0317 47.8644 58.0317 45.6358 56.4623 44.9272L43.8484 39.2326L38.0907 26.5534C37.3773 24.9824 35.1428 24.9914 34.442 26.5681L28.8134 39.2326L16.0836 44.9243C14.5053 45.6299 14.5054 47.8702 16.0836 48.5759L28.8134 54.2676L34.442 66.9321Z"
      fill="white"
    />
    <mask id="path-9-inside-1_680_12567" fill="white">
      <path
        d="M34.894 64.049C35.4196 65.2315 37.0955 65.2383 37.6305 64.06L42.595 53.1275L53.4717 48.2172C54.6488 47.6858 54.6488 46.0143 53.4717 45.4829L42.595 40.5725L37.6305 29.64C37.0955 28.4618 35.4196 28.4685 34.894 29.651L30.04 40.5725L19.0627 45.4807C17.879 46.0099 17.879 47.6902 19.0627 48.2194L30.04 53.1275L34.894 64.049Z"
      />
    </mask>
    <path
      d="M34.894 64.049C35.4196 65.2315 37.0955 65.2383 37.6305 64.06L42.595 53.1275L53.4717 48.2172C54.6488 47.6858 54.6488 46.0143 53.4717 45.4829L42.595 40.5725L37.6305 29.64C37.0955 28.4618 35.4196 28.4685 34.894 29.651L30.04 40.5725L19.0627 45.4807C17.879 46.0099 17.879 47.6902 19.0627 48.2194L30.04 53.1275L34.894 64.049Z"
      fill="#A0D7AF"
    />
    <path
      d="M42.595 53.1275L41.9778 51.7604L41.4629 51.9929L41.2292 52.5073L42.595 53.1275ZM42.595 40.5725L41.2292 41.1927L41.4629 41.7072L41.9778 41.9397L42.595 40.5725ZM30.04 40.5725L30.6523 41.9419L31.1772 41.7072L31.4107 41.1817L30.04 40.5725ZM30.04 53.1275L31.4107 52.5183L31.1772 51.9929L30.6523 51.7582L30.04 53.1275ZM19.0627 48.2194L18.4504 49.5887L19.0627 48.2194ZM37.6305 29.64L38.9963 29.0198L37.6305 29.64ZM34.894 29.651L36.2647 30.2603L34.894 29.651ZM53.4717 48.2172L52.8545 46.85L53.4717 48.2172ZM53.4717 45.4829L54.0889 44.1158L53.4717 45.4829ZM37.6305 64.06L38.9963 64.6802L37.6305 64.06ZM34.894 64.049L36.2647 63.4398L34.894 64.049ZM38.9963 64.6802L43.9608 53.7477L41.2292 52.5073L36.2647 63.4398L38.9963 64.6802ZM43.2122 54.4947L54.0889 49.5843L52.8545 46.85L41.9778 51.7604L43.2122 54.4947ZM54.0889 44.1158L43.2122 39.2054L41.9778 41.9397L52.8545 46.85L54.0889 44.1158ZM43.9608 39.9523L38.9963 29.0198L36.2647 30.2603L41.2292 41.1927L43.9608 39.9523ZM33.5233 29.0418L28.6693 39.9633L31.4107 41.1817L36.2647 30.2603L33.5233 29.0418ZM29.4278 39.2032L18.4504 44.1113L19.6749 46.85L30.6523 41.9419L29.4278 39.2032ZM18.4504 49.5887L29.4278 54.4969L30.6523 51.7582L19.6749 46.85L18.4504 49.5887ZM28.6693 53.7367L33.5233 64.6582L36.2647 63.4398L31.4107 52.5183L28.6693 53.7367ZM18.4504 44.1113C16.0831 45.1698 16.0831 48.5303 18.4504 49.5887L19.6749 46.85H19.6749L18.4504 44.1113ZM38.9963 29.0198C37.9262 26.6634 34.5744 26.6768 33.5233 29.0418L36.2647 30.2603V30.2603L38.9963 29.0198ZM54.0889 49.5843C56.4431 48.5215 56.4431 45.1785 54.0889 44.1158L52.8545 46.85H52.8545L54.0889 49.5843ZM36.2647 63.4398L36.2647 63.4398L33.5233 64.6582C34.5744 67.0232 37.9262 67.0367 38.9963 64.6802L36.2647 63.4398Z"
      fill="#232D37"
      mask="url(#path-9-inside-1_680_12567)"
    />
    <path
      d="M10.5908 18.9725C10.8577 18.9725 11.0741 18.7561 11.0741 18.4892C11.0741 18.2223 10.8577 18.0059 10.5908 18.0059C10.3238 18.0059 10.1074 18.2223 10.1074 18.4892C10.1074 18.7561 10.3238 18.9725 10.5908 18.9725Z"
      fill="#232D37"
    />
    <path
      d="M9.10052 19.5038C9.36746 19.5038 9.58385 19.2874 9.58385 19.0204C9.58385 18.7535 9.36746 18.5371 9.10052 18.5371C8.83358 18.5371 8.61719 18.7535 8.61719 19.0204C8.61719 19.2874 8.83358 19.5038 9.10052 19.5038Z"
      fill="#232D37"
    />
    <path
      d="M7.61029 20.0353C7.87722 20.0353 8.09362 19.8189 8.09362 19.5519C8.09362 19.285 7.87722 19.0686 7.61029 19.0686C7.34335 19.0686 7.12695 19.285 7.12695 19.5519C7.12695 19.8189 7.34335 20.0353 7.61029 20.0353Z"
      fill="#232D37"
    />
    <path
      d="M8.5888 18.0382C8.85574 18.0382 9.07213 17.8218 9.07213 17.5549C9.07213 17.2879 8.85574 17.0715 8.5888 17.0715C8.32186 17.0715 8.10547 17.2879 8.10547 17.5549C8.10547 17.8218 8.32186 18.0382 8.5888 18.0382Z"
      fill="#232D37"
    />
    <path
      d="M9.59075 20.9952C9.85769 20.9952 10.0741 20.7788 10.0741 20.5119C10.0741 20.245 9.85769 20.0286 9.59075 20.0286C9.32382 20.0286 9.10742 20.245 9.10742 20.5119C9.10742 20.7788 9.32382 20.9952 9.59075 20.9952Z"
      fill="#232D37"
    />
    <path
      d="M87.0908 41.9725C87.3577 41.9725 87.5741 41.7561 87.5741 41.4892C87.5741 41.2223 87.3577 41.0059 87.0908 41.0059C86.8238 41.0059 86.6074 41.2223 86.6074 41.4892C86.6074 41.7561 86.8238 41.9725 87.0908 41.9725Z"
      fill="#232D37"
    />
    <path
      d="M85.6005 42.5038C85.8675 42.5038 86.0839 42.2874 86.0839 42.0204C86.0839 41.7535 85.8675 41.5371 85.6005 41.5371C85.3336 41.5371 85.1172 41.7535 85.1172 42.0204C85.1172 42.2874 85.3336 42.5038 85.6005 42.5038Z"
      fill="#232D37"
    />
    <path
      d="M84.1103 43.0353C84.3772 43.0353 84.5936 42.8189 84.5936 42.5519C84.5936 42.285 84.3772 42.0686 84.1103 42.0686C83.8433 42.0686 83.627 42.285 83.627 42.5519C83.627 42.8189 83.8433 43.0353 84.1103 43.0353Z"
      fill="#232D37"
    />
    <path
      d="M85.0888 41.0382C85.3557 41.0382 85.5721 40.8218 85.5721 40.5549C85.5721 40.2879 85.3557 40.0715 85.0888 40.0715C84.8219 40.0715 84.6055 40.2879 84.6055 40.5549C84.6055 40.8218 84.8219 41.0382 85.0888 41.0382Z"
      fill="#232D37"
    />
    <path
      d="M86.0908 43.9952C86.3577 43.9952 86.5741 43.7788 86.5741 43.5119C86.5741 43.245 86.3577 43.0286 86.0908 43.0286C85.8238 43.0286 85.6074 43.245 85.6074 43.5119C85.6074 43.7788 85.8238 43.9952 86.0908 43.9952Z"
      fill="#232D37"
    />
    <path
      d="M28.4638 74.901C28.7307 74.901 28.9471 74.6846 28.9471 74.4177C28.9471 74.1507 28.7307 73.9343 28.4638 73.9343C28.1969 73.9343 27.9805 74.1507 27.9805 74.4177C27.9805 74.6846 28.1969 74.901 28.4638 74.901Z"
      fill="#232D37"
    />
    <path
      d="M26.9736 75.4322C27.2405 75.4322 27.4569 75.2158 27.4569 74.9489C27.4569 74.682 27.2405 74.4656 26.9736 74.4656C26.7066 74.4656 26.4902 74.682 26.4902 74.9489C26.4902 75.2158 26.7066 75.4322 26.9736 75.4322Z"
      fill="#232D37"
    />
    <path
      d="M25.4833 75.9637C25.7503 75.9637 25.9667 75.7473 25.9667 75.4804C25.9667 75.2135 25.7503 74.9971 25.4833 74.9971C25.2164 74.9971 25 75.2135 25 75.4804C25 75.7473 25.2164 75.9637 25.4833 75.9637Z"
      fill="#232D37"
    />
    <path
      d="M26.4618 73.9667C26.7288 73.9667 26.9452 73.7503 26.9452 73.4833C26.9452 73.2164 26.7288 73 26.4618 73C26.1949 73 25.9785 73.2164 25.9785 73.4833C25.9785 73.7503 26.1949 73.9667 26.4618 73.9667Z"
      fill="#232D37"
    />
    <path
      d="M27.4638 76.9237C27.7307 76.9237 27.9471 76.7073 27.9471 76.4404C27.9471 76.1734 27.7307 75.957 27.4638 75.957C27.1969 75.957 26.9805 76.1734 26.9805 76.4404C26.9805 76.7073 27.1969 76.9237 27.4638 76.9237Z"
      fill="#232D37"
    />
    <path d="M49.1443 47.3462L47.5 48" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    <path d="M67.5 34L65.5 34.5" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M63.5 36.5L62.75 39.5" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M61.25 63.25L59.25 63.75" stroke="white" strokeLinecap="round"/>
    <path d="M45.5 49L42 50.75" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    <path d="M39.75 54.25L38.25 57.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);
const AskAiBanner = () => {
  const dispatch = useDispatch();
  const isMobile = useMobile(798);
  const includeRoutes = !!useRouteMatch([
    '/customer/bloodwork',
    '/customer/age',
    '/customer/genetics-report',
    '/customer/onboarding/test-list',
    '/customer/account',
    '/customer/settings/',
    '/customer/purchase-history/',
    '/customer/summary/dashboard',
  ]);
  const excludeRoutes = !!useRouteMatch('/customer/onbo');
  const show = includeRoutes && !excludeRoutes;
  const notificationsTags = useSelector(notificationTagsSelector);
  const showAIPopup = useSelector(showAIPopupSelector);
  const history = useHistory();
  const askITFirstTime = notificationsTags?.includes('suggest-to-see-ask-section');
  const updatePage = () => {
    dispatch(fetchNotificationTags());
  };
  return (
    <div className={`${styles.AskAiBanner} ${show ? styles.showAskAiBanner : ''}`}>
      <div className={styles.container1920}>
        <TwinkleIcon/>
        <div className={styles.AskAiBannerTextWrap}>
          <p className={styles.AskAiBannerTitle}>Ask InsideTracker AI</p>
          <p className={styles.AskAiBannerText}>
            Your go-to for science-backed answers on biomarkers, activity,
            healthspan, and more!
          </p>
        </div>
        <Button
          onClick={(e) => {
            if (askITFirstTime) {
              e.preventDefault();
              dispatch(setShowAiPopup(true));
            } else {
              e.preventDefault();
              history.push(routes.customer.askIt);
            }
          }}
          id="ask-it"
          greenGlass
          superLarge={!isMobile}
          className={styles.askItBtn}
          text={(
            <span className={styles.askItBtnText}>
              Ask now
              <Icon
                iconName="general_arrow"
                className={styles.askItBtnArrow}
              />
            </span>
            )}
        />
      </div>
      {showAIPopup && (
        <IntroduceAiPopup
          show={showAIPopup}
          onClosePopup={async () => {
            dispatch(setShowAiPopup(false));
            await dnaApi.putNotificationsTags('suggest-to-see-ask-section');
            updatePage();
          }}
        />
      )}
    </div>
  );
};

export default AskAiBanner;

import React, { ReactElement } from 'react';
import styles from './SocialIcon.module.less';

/**
 * SocialIcon component
 * @component
 * @category Components
 * @subcategory Content
 * @hideconstructor
 * @example
 * return <SocialIcon href="http://www.linkedin.com/company/insidetracker/">
 *       <svg width="30px" id="linkedin" viewBox="0 0 177 177">
 *         <g transform="translate(-310 -558)">
 *           <g fill="#fff">
 *             <path
 *               id="bnfca"
 *               d="M445.688 567.072H351.99c-18.043 0-32.67 14.632-32.67 32.671v93.702c0 18.046 14.627 32.665 32.67 32.665h93.7c18.045 0 32.665-14.619 32.665-32.665v-93.702c0-18.039-14.62-32.67-32.666-32.67z"
 *             />
 *           </g>
 *         </g>
 *         <g transform="translate(-310 -558)">
 *           <g fill="#0077b5">
 *             <path
 *               id="bnfcb"
 *               d="M450.662 558.632c19.96 0 36.133 16.184 36.133 36.138v103.65c0 19.96-16.172 36.132-36.133 36.132H347.017c-19.958 0-36.14-16.172-36.14-36.133V594.77c0-19.954 16.182-36.138 36.14-36.138zm-96.4 57.59c7.77 0 14.089-6.307 14.089-14.089 0-7.788-6.318-14.11-14.09-14.11-7.794 0-14.094 6.322-14.094 14.11 0 7.782 6.3 14.09 14.095 14.09zm12.158 88.938v-78.24H342.1v78.24zm91.095-42.914c0-21.063-4.547-37.262-29.175-37.262-11.828 0-19.762 6.48-23.006 12.636H405v-10.704h-23.33v78.244h24.31v-38.699c0-10.205 1.932-20.102 14.584-20.102 12.485 0 12.64 11.68 12.64 20.752v38.05h24.31z"
 *             />
 *           </g>
 *         </g>
 *       </svg>
 *     </SocialIcon>
 */
const SocialIcon = (props: { href: string, children: ReactElement, testId: string }) => {
  const { href, children, testId } = props;
  return (
    <a
      className={styles.contactsIconsItem}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      data-test-id={testId}
    >
      {children}
    </a>
  );
};

export default SocialIcon;

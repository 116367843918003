import React from 'react';

/* Styles */
import { AuthPanelButtonPropsTypes } from './AuthPanelButton';
import Button from '../../../../Buttons/Button';
import styles from '../../../Header.module.less';

/**
 * @component
 * @category Header
 * @subcategory Auth panel
 * @borrows AuthPanelButtonPropsTypes as props
 * @component
 * @hideconstructor
 * @example
 * return <AuthPanelActionButton onClick={() => {alert('Click')}} name="Button"/>
 */
const AuthPanelActionButton = (props: AuthPanelButtonPropsTypes) => {
  const {
    onClick, name, id,
  } = props;
  return (
    <Button
      type="button"
      green
      onClick={onClick}
      id={id}
      mr={5}
      className={styles.userInfoBtn}
    >
      {name as string}
    </Button>
  );
};

export default AuthPanelActionButton;

import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userPermissions } from '../../../../Constants/User';
import styles from './Navigation.module.less';
import NavigationLink from './NavigationLink/NavigationLink';
import NavigationDropdown from './NavigationLink/NavigationDropdown';
import NavigationCollapse from './NavigationLink/NavigationCollapse';
import { bottomContactsList, contactsList, NavigationPropsTypes } from './Navigation';
import routes from "../../../../references/routes";
import { notificationTagsSelector } from "../../../../store/user/selectors";
import { setShowAiPopup } from "../../../../store/user";

/**
 * Part of header. Customer navigation bar
 * @category Header
 * @subcategory Navigation
 * @borrows NavigationPropsTypes as props
 * @component
 * @hideconstructor
 * @example
 * const user = {
 * id: 123,
 * email: 'test@test.com',
 * firstName: 'test',
 * middleName: 'test',
 * lastName: 'test',
 * phone: 'test',
 * phoneCode: 'test',
 * activationState: 'test',
 * hasInnerAgeResult: true,
 * isDnaOnly: true,
 * hasUnusedTest: true,
 * permissions: [],
 * photoSrc: 'test',
 * hasDiyPackage: true,
 * canAddInnerAge: true,
 * }
 * return <CustomerNavigation user={user}/>
 */

export const AskItIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Twinkle">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6 8.6L17.5 13L16.4 8.6L12 7.5L16.4 6.4L17.5 2L18.6 6.4L23 7.5L18.6 8.6ZM8.5 20L10.85 14.825L16 12.5L10.85 10.175L8.5 5L6.2 10.175L1 12.5L6.2 14.825L8.5 20ZM16.5 22L17.2 19.2L20 18.5L17.2 17.8L16.5 15L15.8 17.8L13 18.5L15.8 19.2L16.5 22Z"
        fill="white"
      />
    </g>
  </svg>
);

const CustomerNavigation = (props: NavigationPropsTypes) => {
  const dispatch = useDispatch();
  /** user object from props */
  const { user, mobile, inner } = props;
  const { isDnaOnly, hasAccessToBru } = user;
  const history = useHistory();
  const location = useLocation();
  const notificationsTags = useSelector(notificationTagsSelector);
  const hasAccessToSummary = user.permissions.includes(userPermissions.summary);
  const hasAccessToBloodwork = !isDnaOnly && user.permissions.includes(userPermissions.bloodwork);
  const hasAccessToNutrition = user.permissions.includes(userPermissions.nutrition);
  const hasAccessToAge = user.permissions.includes(userPermissions.age);
  const showInnerAgeLink = hasAccessToAge && (user.allowedToSeeInnerAge || user.hasDiyOrderWithInnerAge);
  const askITFirstTime = notificationsTags?.includes('suggest-to-see-ask-section');

  return (
    <nav className={`${styles.navbar}  ${mobile ? styles.navbarMobile : ''}`}>
      <div className={styles.navbarList}>
        {hasAccessToSummary
        && <NavigationLink link="/customer/summary/dashboard" label="Dashboard"/>}
        {hasAccessToBloodwork && !isDnaOnly && !mobile
        && (
          <NavigationDropdown link={routes.customer.myResults.main} label="My results">
            <NavigationLink
              dropdownLink
              link={routes.customer.myResults.groups}
              label="Blood results"
            />
            {hasAccessToBru
            && (
            <NavigationLink
              dropdownLink
              link="/customer/diy/upload-blood-result"
              label="Add blood results"
            />
            )}
            <NavigationLink
              dropdownLink
              link="/customer/onboarding/test-list/"
              label="Orders"
            />
          </NavigationDropdown>
        )}
        {hasAccessToBloodwork && !isDnaOnly && mobile && (
          <NavigationCollapse label="My results">
            <NavigationLink link={routes.customer.myResults.groups} label="Blood results"/>
            {hasAccessToBru
            && <NavigationLink link="/customer/diy" label="Add blood results"/>}
            <NavigationLink link="/customer/onboarding/test-list/" label="Orders"/>
          </NavigationCollapse>
        )}
        {!isDnaOnly && !mobile && (
          <NavigationDropdown link="/customer/nutrition" label="Nutrition">
            {hasAccessToNutrition
            && (
              <NavigationLink
                dropdownLink
                link="/customer/nutrition"
                label="Food Recommendations"
              />
            )}
          </NavigationDropdown>
        )}
        {!isDnaOnly && mobile && (
          <NavigationCollapse label="Nutrition">
            {hasAccessToNutrition
            && (
              <NavigationLink
                dropdownLink
                link="/customer/nutrition"
                label="Food Recommendations"
              />
            )}
          </NavigationCollapse>
        )}
        {showInnerAgeLink && <NavigationLink link="/customer/age" label="InnerAge"/>}
        <NavigationLink link="/customer/genetics-report" label="DNA"/>
        {isDnaOnly
        && <NavigationLink link="/customer/blood-analysis" label="Blood analysis"/>}

        <NavigationLink
          link={routes.customer.askIt}
          label={(
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
              className={styles.askIt}
              onClick={(e) => {
                if (askITFirstTime) {
                  e.preventDefault();
                  dispatch(setShowAiPopup(true));
                } else {
                  e.preventDefault();
                  history.push(routes.customer.askIt);
                }
              }}
            >
              <svg className={styles.askItIcon} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Twinkle">
                  <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M19.0355 8.6L17.9355 13L16.8355 8.6L12.4355 7.5L16.8355 6.4L17.9355 2L19.0355 6.4L23.4355 7.5L19.0355 8.6ZM8.93555 20L11.2855 14.825L16.4355 12.5L11.2855 10.175L8.93555 5L6.63555 10.175L1.43555 12.5L6.63555 14.825L8.93555 20ZM16.9355 22L17.6355 19.2L20.4355 18.5L17.6355 17.8L16.9355 15L16.2355 17.8L13.4355 18.5L16.2355 19.2L16.9355 22Z" fill="white"/>
                </g>
              </svg>
              <span className={styles.askItText}>Ask AI</span>
              {askITFirstTime && (
              <span className={styles.dot}/>
              )}
            </div>
        )}
        />
      </div>
      {mobile && inner && (
        <div className={`${styles.navbarList} ${styles.navbarListDivider}`}>
          <NavigationLink link="/customer/settings" label="Profile"/>
          <NavigationLink link="/customer/achievements" label="Achievements"/>
        </div>
      )}
      {!inner && contactsList}
      {!inner && bottomContactsList}
    </nav>
  );
};

export default CustomerNavigation;

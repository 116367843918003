import React from "react";

import styles from "./themeProductCard.module.less";

import CategoryIcon from "./productCategoryIcon";

export default function ProductCard({
  productTitle,
  productImage,
  productCatTitle,
  productCats,
  productPrice,
  hasBloodTest,
  productUrl,
  productTag,
  productPerText,
}) {
  return (
    <div className={`${styles.productCard}`}>
      {
        productTag
        && <div className={`${styles.productCardTag}`}>{productTag}</div>
      }
      <div className={`${styles.productCardTitle}`}>{productTitle}</div>
      <img
        src={productImage}
        alt={productTitle}
        className={`${styles.productCardImage}`}
      />
      <div className={`${styles.productCardTagTitle}`}>{productCatTitle}</div>
      {
        productCats && (
          <ul className={`${styles.productCardCats}`}>
            {productCats.map((item) => (
              <li key={Math.random().toString()}>
                <CategoryIcon name={item.cat} />
                {item.cat}
              </li>
            ))}
          </ul>
        )
      }
      <div className={`${styles.productCardPrice}`}>
        {productPrice}
        <span>{productPerText}</span>
      </div>
      <div className={`${styles.productCardInfo}`}>{ hasBloodTest ? "Blood test included" : "Blood test NOT included" }</div>
      <a href={productUrl} className={`${styles.productCardButton}`}>
        Explore Now
      </a>
    </div>
  );
}

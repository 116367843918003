import React from "react";
import { Link } from 'react-router-dom';

import styles from "./themeCardNew.module.less";

export default function CardNew({
  image,
  title,
  info,
  link,
  isInternal,
  pageName,
  videoUrl,
}) {
  return (
    <div className={`${styles.themeCard}`}>
      <div className={`${styles.themeCardMedia}`}>
        {
          image
          && <img src={image} alt={title} />
        }
        {
          videoUrl
          && (
            <iframe src={videoUrl} title={title}/>
          )
        }
      </div>
      <div className={`${styles.themeCardInfo}`}>
        <h3 className={`${styles.themeCardTitle}`}>{title}</h3>
        <p className={`${styles.themeCardInfoText}`}>{info}</p>
        {
          link
          && (
            isInternal
              ? (
                <Link to={pageName} className={`${styles.themeCardBtn}`}>
                  <svg width="32" height="19" viewBox="0 0 32 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M22.4131 18.6895L21.2327 17.4994L28.3404 10.3407H0.976563V8.65837H28.3404L21.2327 1.49963L22.4131 0.310143L31.5372 9.4998L22.4131 18.6895Z" fill="white"/>
                  </svg>
                </Link>
              )
              : (
                <a href={link} className={`${styles.themeCardBtn}`}>
                  <svg width="32" height="19" viewBox="0 0 32 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M22.4131 18.6895L21.2327 17.4994L28.3404 10.3407H0.976563V8.65837H28.3404L21.2327 1.49963L22.4131 0.310143L31.5372 9.4998L22.4131 18.6895Z" fill="white"/>
                  </svg>
                </a>
              )
          )
        }
      </div>
    </div>
  );
}

import userAuthorizedAxios from './userAuthorizedAxios';
import baseAxios from "../baseAxios";
import { GenderIdentity, GenderIdentityReferences } from "../../interfaces/Profile/GenderIdentity/GenderIdentity";

export default {
  getGenderIdentityReferences: (): Promise<GenderIdentityReferences> => baseAxios.get('/api/public/references/gender-identities')
    .then((res) => res.data),

  getGenderIdentity: (): Promise<GenderIdentity> => userAuthorizedAxios.get('/api/customer/settings/gender-identity')
    .then((res) => res.data),

  patchGenderIdentity: (data: GenderIdentity): Promise<void> => userAuthorizedAxios
    .patch('/api/customer/settings/gender-identity', data),
};

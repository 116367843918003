import { Builder } from "@builder.io/react";

import themeHero from "./themeHero.jsx";

Builder.registerComponent(themeHero, {
  name: "Theme Hero",
  inputs: [
    {
      type: "text",
      name: "heroTitle",
      friendlyName: "Hero Title",
      defaultValue: "Live healthier<span>longer</span>",
    },
    {
      type: "longText",
      name: "heroText",
      friendlyName: "Hero Text",
      defaultValue: "Personalized biomarker analysis + data-driven wellness guide",
    },
    {
      type: "object",
      name: "heroBtn",
      friendlyName: "Hero Button",
      defaultValue: {
        heroBtnLabel: "Tour InsideTracker",
        heroBtnUrl: "https://www.insidetracker.com",
      },
      subFields: [
        {
          name: "heroBtnLabel",
          type: "string",
          friendlyName: "Button Label",
          defaultValue: "Tour InsideTracker",
        },
        {
          name: "heroBtnUrl",
          type: "url",
          friendlyName: "Button Link",
          defaultValue: "https://www.insidetracker.com",
        },
        {
          name: "isInternal",
          type: "boolean",
          friendlyName: "Check if the Link is Internal",
          defaultValue: false,
        },
        {
          name: "pageName",
          type: "string",
          friendlyName: "Enter Page Name for Internal Link",
          helperText: "Only Applicable when the link is internal"
        }
      ]
    },
    {
      type: "file",
      name: "heroAnimationImage",
      friendlyName: "Hero Animation Image",
      allowedFileTypes: ["jpg", "jpeg,", "png"]
    },
    {
      type: "object",
      name: "animationImages",
      friendlyName: "Hero Animation Images",
      defaultValue: {
        graphImageApob: "https://placehold.co/75x40",
        graphImageLdl: "https://placehold.co/75x40",
        highIntensityImage: "https://placehold.co/75x40"
      },
      subFields: [
        {
          name: "graphImageApob",
          type: "file",
          friendlyName: "Graph Image ApoB",
          allowedFileTypes: ["png", "svg"],
          defaultValue: "https://placehold.co/75x40"
        },
        {
          name: "graphImageLdl",
          type: "file",
          friendlyName: "Graph Image LDL",
          allowedFileTypes: ["png", "svg"],
          defaultValue: "https://placehold.co/75x40"
        },
        {
          name: "highIntensityImage",
          type: "file",
          friendlyName: "High Intesity Card Image",
          allowedFileTypes: ["jpeg", "jpg", "png"],
          defaultValue: "https://placehold.co/75x40"
        }
      ]
    },
  ]
})
import React from 'react';
import styles from './CloseBlock.module.less';
/**
 * CloseBlock props types definitions
 */
type CloseBlockPropsTypes = {
  /**
   * Click event handler function
   */
  onClick: () => void,
  /**
   * Additional classes
   */
  classes?: string
};
/**
 * CloseBlock component
 * Is used for popups
 * @component
 * @category Components
 * @subcategory Utils
 * @borrows CloseBlockPropsTypes as props
 * @example
 * return <div style={{ position: 'relative', width: '100px', height: '100px' }}><CloseBlock onClick={() => { console.log('close'); }}/></div>
 */
const CloseBlock = (props: CloseBlockPropsTypes) => {
  const { classes, onClick } = props;
  return (
    <button
      type="button"
      tabIndex={0}
      className={`${styles.closeBlock} ${classes || ''}`}
      onClick={onClick}
    >
      <svg className={styles.closeBlockIcon} id="close" viewBox="0 0 16 16">
        <title>close</title>
        <path
          d="M16 1.611l-1.611-1.611-6.389 6.389-6.389-6.389-1.611 1.611 6.389 6.389-6.389 6.389 1.611 1.611 6.389-6.389 6.389 6.389 1.611-1.611-6.389-6.389z"
        />
      </svg>
    </button>
  );
};

export default CloseBlock;

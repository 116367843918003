import { createAsyncThunk } from '@reduxjs/toolkit';
import dnaApi from '../../services/Customer/dnaApi';

export const fetchUserDnaReport = createAsyncThunk(
  'userDna/fetchUserDnaReport',
  async () => {
    const data = await dnaApi.getDnaReport();
    if (data.status === 202) {
      return {
        dnaReport: null,
      };
    }
    return {
      dnaReport: data.data,
    };
  },
);

export const fetchDnaKit = createAsyncThunk(
  'userDna/fetchDnaKit',
  async () => await dnaApi.getDnaKit(),
);

export default {
  fetchUserDnaReport,
};

import React, { useLayoutEffect, useRef } from "react";

import { Builder } from "@builder.io/react";

import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function ThemeScrollSection({
  children,
  attributes,
  circleColor,
  circleBorderColor,
}) {
  const scrollRef = useRef();
  useLayoutEffect(() => {
    if (!Builder.isEditing) {
      const ctx = gsap.context(() => {
        ScrollTrigger.defaults({
          markers: false,
        });
        const sections = gsap.utils.toArray(scrollRef.current.querySelectorAll(".scrollingSection"));
        const indicators = gsap.utils.toArray(scrollRef.current.querySelectorAll(".scrollingIndicator"));
        const height = 100 * sections.length;
        gsap.set('.scrollingIndicators', { display: "flex" });
        const tl = gsap.timeline({
          duration: sections.length,
          scrollTrigger: {
            trigger: ".scrollingSection",
            start: "top center",
            end: `+=${height}%`,
            scrub: true,
            id: "sections",
          },
        });
        const pinner = gsap.timeline({
          scrollTrigger: {
            trigger: scrollRef.current,
            start: "top top",
            end: `+=${height}%`,
            scrub: true,
            pin: scrollRef.current,
            pinSpacing: true,
            id: "pinning",
            markers: false,
          },
        });
        sections.forEach((elem, i) => {
          gsap.set(elem, { position: "absolute", top: 0 });
          tl.to(indicators[i], { borderColor: `${circleBorderColor}`, duration: 0.25 }, i);
          if (i != 0) {
            tl.from(elem.querySelector('.builder-column:last-child'), { autoAlpha: 0 }, i);
            tl.from(elem.querySelector('.builder-column:first-child'), { autoAlpha: 0, translateY: 100 }, i);
          }
          tl.to(elem.querySelector('.builder-column:first-child'), { position: "relative", zIndex: 1 }, i);
          if (i != sections.length - 1) {
            tl.to(indicators[i], { borderColor: "transparent", duration: 0.25 }, i + 0.75);
            tl.to(elem.querySelector('.builder-column:first-child'), { autoAlpha: 0, translateY: -100 }, i + 0.75);
            tl.to(elem.querySelector('.builder-column:last-child'), { autoAlpha: 0 }, i + 0.75);
          }
        });
      });
      return () => ctx.revert();
    }
  });
  if (children.length > 0) {
    return (
      <section
        className="scrollingSection"
        id="scrollingSection"
        {...attributes}
      >
        <div
          className="scrollingSectionWrapper"
          id="scrollingSectionWrapper"
          style={{
            height: Builder.isEditing ? "auto" : "100vh",
            width: "100%",
          }}
          ref={scrollRef}
        >
          <div
            className="scrollingIndicators"
            id="scrollingIndicators"
            style={{
              left: "1.5rem",
              top: "0",
              margin: "10vh 0",
              height: "80vh",
              width: "27px",
              display: "none",
              flexDirection: "column",
              justifyContent: "center",
              position: "absolute",
            }}
          >
            {
              children.map(() => (
                <div
                  key={Math.random().toString()}
                  className="scrollingIndicator"
                  style={{
                    width: "27px",
                    height: "27px",
                    border: "2px solid transparent",
                    margin: "2px 0",
                    borderRadius: "50%",
                    transition: ".2s all ease-in-out",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      width: "13px",
                      height: "13px",
                      backgroundColor: `${circleColor}`,
                      borderRadius: "50%",
                    }}
                  />
                </div>
              ))
            }
          </div>
          {
            children.map((child) => (
              <div
                key={Math.random().toString()}
                className="scrollingSection"
                style={{
                  height: Builder.isEditing ? "auto" : "100vh",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {child}
              </div>
            ))
          }
        </div>
      </section>
    );
  }
  return (
    <div {...attributes} style={{ color: "#fff", fontSize: "30px", textAlign: "center" }}>Please Insert the Scrolling Sections</div>
  );
}

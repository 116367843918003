import {
  GeneralIconType, OnboardingIconType, PagesIconType, DnaIconType,
} from '../../components/Icons/types';

const createDictionary = <K>() => <T extends Record<string, K>>(collection: T) => collection;

export default class IconNames {
  static general = createDictionary<GeneralIconType>()({
    arrow: 'general_arrow',
    add: 'general_add',
    itLogoInlineGray: 'general_itLogoInlineGray',
    itLogoInlineWhite: 'general_itLogoInlineWhite',
    close: 'general_close',
    cart: 'general_cart',
    graph: 'general_graph',
    menuSub: 'general_menuSub',
    menu: 'general_menu',
    tick: 'general_tick',
    display404: 'general_display404',
    info: 'general_info',
    addNote: 'general_addNote',
    edit: 'general_edit',
    trash: 'general_trash',
    warning: 'general_warning',
    avatar: 'general_avatar',
    earth: 'general_earth',
    book: 'general_book',
    message: 'general_message',
    cursor: 'general_cursor',
    tel: 'general_tel',
    infoSign: 'general_infoSign',
  });

  static pages = {
    proof: 'pages_proof',
    proofSolid: 'pages_proofSolid',
    dnaData1: 'pages_dnaData1',
    dnaData2: 'pages_dnaData2',
    ordersList: 'pages_ordersList',
    testActivating: 'pages_testActivating',
  } as Record<string, PagesIconType>;

  static onboarding = createDictionary<OnboardingIconType>()({
    dnaPersonalize: 'onboarding_dnaPersonalize',
    dnaCongratulation: 'onboarding_dnaCongratulation',
  });

  static dna = {
    step1: 'dna_step1',
    step2: 'dna_step2',
    step3: 'dna_step3',
    step4: 'dna_step4',
    step5: 'dna_step5',
    kitStep1: 'dna_kitStep1',
    kitStep2: 'dna_kitStep2',
    kitStep3: 'dna_kitStep3',
    kitStep4: 'dna_kitStep4',
    instruction1: 'dna_instruction1',
    instruction2: 'dna_instruction2',
    instruction3: 'dna_instruction3',
    instruction4: 'dna_instruction4',
    instruction5: 'dna_instruction5',
  } as Record<string, DnaIconType>;
}

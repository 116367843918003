import userAuthorizedAxios from './userAuthorizedAxios';
import { InnerageResults } from '../../interfaces/Innerage/Results';
import { InnerageBloodMarkers } from '../../interfaces/Innerage/InnerageBloodMarkers';
import { DailyActions } from '../../interfaces/Innerage/DailyActions';
import { MarkersList } from '../../interfaces/Innerage/MarkersList';

export default {
  getResults: (): Promise<InnerageResults> => userAuthorizedAxios.get('/api/pages/age/results')
    .then((responseData) => responseData.data),
  getMarkersList: (): Promise<MarkersList> => userAuthorizedAxios.get('/api/pages/age/markers-list')
    .then((responseData) => responseData.data),
  getBloodMarkers: (): Promise<InnerageBloodMarkers> => userAuthorizedAxios.get('/api/customer/v1/inner-age/markers')
    .then((responseData) => responseData.data),
  getDailyActiveActions: (): Promise<DailyActions> => userAuthorizedAxios.get('/api/pages/age/active-daily-actions')
    .then((responseData) => responseData.data.dailyActions),
  postCheckInData: (day: number, isChecked: boolean, cardId: number): Promise<{ message: string }> => userAuthorizedAxios
    .post('/api/pages/age/set-intervention-status', {
      day,
      isChecked,
      interventionId: cardId,
    })
    .then((response) => response.data),
};

import userAuthorizedAxios from './userAuthorizedAxios';
import {
  AddressData,
  BloodResultsUploadData,
  FitbitData,
  ProfileData,
  ResultsSharedWith,
  RosettaData,
  SecurityData,
  UserData,
} from '../../interfaces/Data/responceDataTypes';
import {
  AddressPost,
  AllowSharingPost,
  BirthDatePost,
  CountSystemPost,
  EthnicityPost,
  GenderPost,
  HeightPost,
  HipPost,
  ITPermissionsPost,
  NamesPost,
  NewEmailPost,
  PasswordPost,
  PhonePost,
  PhoneVerificationCodePost,
  SecurityQuestionPost,
  TeamPermissionsPost,
  VerifiedPhonePost,
  WaistPost,
  WeightPost,
} from '../../interfaces/Profile/Account/postBody';
import { AccountReferences } from '../../interfaces/Profile/Account/References';
import { WomanHealth } from '../../interfaces/Profile/Account/womanHealth';

const addVersionToAvatar = (src: string | null) => {
  const version = localStorage.getItem('avatar-version');
  return (version && src) ? `${src}?v=${version}` : src;
};

type FullProfileResponseType = {
  customer: UserData,
  settings: ProfileData,
  address: AddressData,
  rosetta: RosettaData,
};

export default {
  getFullProfile: (): Promise<FullProfileResponseType> => userAuthorizedAxios.get('/api/pages/account/full')
    .then((responseData) => ({
      ...responseData.data,
      customer: {
        ...responseData.data.customer,
        photoSrc: addVersionToAvatar(responseData.data.customer.photoSrc),
      },
    })),
  getUserData: (): Promise<UserData> => userAuthorizedAxios.get('/api/pages/account/customer')
    .then((responseData) => ({
      ...responseData.data,
      photoSrc: addVersionToAvatar(responseData.data.photoSrc),
    })),
  getUserSettings: (): Promise<ProfileData> => userAuthorizedAxios.get('/api/pages/account/settings')
    .then((responseData) => responseData.data),
  getWomanHealth: (): Promise<WomanHealth> => userAuthorizedAxios.get('/api/customer/profile/woman-health')
    .then((responseData) => responseData.data),
  getUserAddresses: (): Promise<AddressData> => userAuthorizedAxios.get('/api/pages/account/address')
    .then((responseData) => responseData.data),
  getAllReferences: (): Promise<AccountReferences> => userAuthorizedAxios.get('/api/pages/account/references')
    .then((responseData) => responseData.data),

  getFitbitStatus: (): Promise<FitbitData> => userAuthorizedAxios.get('/api/pages/fitbit/connection-status')
    .then((responseData) => responseData.data),

  getInnerAgeUpgradableTests: (): Promise<{
    resultIdForAddInnerAge: number | null,
    actualInnerAgePackageId: number,

    resultsForAddInnerAge: {
      id: string,
      orderId: string,
      packageId: string,
      isPublished: boolean,
      packageName: string,
      purchasedDate: string,
      usedDate: string,
    }[]
  }> => userAuthorizedAxios.get('/api/pages/account/getInnerAgeUpgradableTests')
    .then((responseData) => responseData.data),

  checkAuthExpiration: (): Promise<{ value: number }> => userAuthorizedAxios.get('/customer/login/check-session-expiration')
    .then((responseData) => responseData.data),

  getSecuritySettings: (): Promise<SecurityData> => userAuthorizedAxios.get('/api/pages/account/security')
    .then((res) => res.data),

  getSharedResults: (): Promise<ResultsSharedWith> => userAuthorizedAxios.get('/api/pages/account/sharing-history')
    .then((res) => res.data),

  getBloodResultsUploadData: (): Promise<BloodResultsUploadData> => userAuthorizedAxios.get('/api/pages/subscriptions/bloodResultsUpload')
    .then((responseData) => responseData.data),

  postNames: (data: NamesPost) => userAuthorizedAxios.post('/api/pages/account/names-post', data)
    .then((res) => res.data),
  postAddress: (data: AddressPost) => userAuthorizedAxios.post('/api/pages/account/address-post', data)
    .then((res) => res.data),
  postPhone: (data: PhonePost) => userAuthorizedAxios.post('/api/pages/account/phone-post', data)
    .then((res) => res.data),
  postSaveVerifiedPhone: (data: VerifiedPhonePost) => userAuthorizedAxios.post('/api/pages/account/save-verified-phone', data)
    .then((res) => res.data),
  postVerificationCode: (data: PhoneVerificationCodePost) => userAuthorizedAxios.post('/api/pages/account/phone-verification-code-post', data)
    .then((res) => res.data),
  postNewEmail: (data: NewEmailPost) => userAuthorizedAxios.patch('/api/customer/account/change-email', data)
    .then((res) => res.data),
  postBirthDate: (data: BirthDatePost) => userAuthorizedAxios.post('/api/pages/account/birth-date-post', data)
    .then((res) => res.data),
  postGender: (data: GenderPost) => userAuthorizedAxios.post('/api/pages/account/gender-post', data)
    .then((res) => res.data),
  postEthnicity: (data: EthnicityPost) => userAuthorizedAxios.post('/api/pages/account/ethnicity-post', data)
    .then((res) => res.data),
  postWeight: (data: WeightPost) => userAuthorizedAxios.put('/api/customer/profile/weight', data)
    .then((res) => res.data),
  postHeight: (data: HeightPost) => userAuthorizedAxios.post('/api/pages/account/height-post', data)
    .then((res) => res.data),
  postWaist: (data: WaistPost) => userAuthorizedAxios.post('/api/pages/account/waist-post', data)
    .then((res) => res.data),
  postHip: (data: HipPost) => userAuthorizedAxios.post('/api/pages/account/hip-post', data)
    .then((res) => res.data),
  postCountSystem: (data: CountSystemPost) => userAuthorizedAxios.post('/api/pages/account/count-system-post', data)
    .then((res) => res.data),
  postPassword: (data: PasswordPost) => userAuthorizedAxios.post('/api/pages/account/password-post', data)
    .then((res) => res.data),
  postSecurityQuestion: (data: SecurityQuestionPost) => userAuthorizedAxios.post('/api/pages/account/security-question-post', data)
    .then((res) => res.data),
  postITpermissions: (data: ITPermissionsPost) => userAuthorizedAxios.post('/api/pages/account/permissions-for-inside-tracker-team', data)
    .then((res) => res.data),
  postAllowSharing: (data: AllowSharingPost) => userAuthorizedAxios.post('/api/pages/account/sharing-results-post', data)
    .then((res) => res.data),
  postTeamPermissions: (data: TeamPermissionsPost, teamId: string) => userAuthorizedAxios.post(`/api/pages/account/permissions-for-team-post/${teamId}`, data)
    .then((res) => res.data),
  getGiftCardBalance: (code: string) => userAuthorizedAxios.get(`/api/pages/shopifyGiftCard/getBalanceByCode/${code}`)
    .then((res) => res.data?.balance),
  patchWomanHealth: (data: Partial<WomanHealth>) => userAuthorizedAxios.patch('/api/customer/profile/woman-health', data),
};

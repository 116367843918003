import React, { useEffect } from "react";

import Splide from "@splidejs/splide";
import "@splidejs/splide/css";

import styles from "./themeSlider.module.less";

export default function ThemeSlider({
  children,
  sliderID,
  desktopConfig,
  tabletConfig,
  mobileConfig,
  defaultConfig,
}) {
  const sliderOptions = {
    direction: "ltr",
    type: defaultConfig.type,
    pagination: mobileConfig.showPagination,
    arrows: mobileConfig.showArrows,
    perPage: mobileConfig.slidesToShow,
    perMove: mobileConfig.showArrows,
    mediaQuery: "min",
    gap: defaultConfig.slideGap,
    fixedWidth: mobileConfig.sliderWidth,
    focus: defaultConfig.focusCenter ? 'center' : 0,
    start: defaultConfig.startIndex,
    padding: {
      left: defaultConfig.paddingLeft,
      right: defaultConfig.paddingRight,
    },
    autoplay: defaultConfig.autoPlay,
    interval: defaultConfig.autoPlayInterval,
    pauseOnHover: false,
    breakpoints: {
      992: {
        perPage: desktopConfig.slidesToShow,
        perMove: desktopConfig.slidesToMove,
        pagination: desktopConfig.showPagination,
        arrows: desktopConfig.showArrows,
        fixedWidth: desktopConfig.sliderWidth,
        destroy: desktopConfig.disableCarousel,
      },
      552: {
        perPage: tabletConfig.slidesToShow,
        perMove: tabletConfig.slidesToMove,
        pagination: tabletConfig.showPagination,
        arrows: tabletConfig.showArrows,
        fixedWidth: tabletConfig.sliderWidth,
      },
    },
  };
  useEffect(() => {
    if (children.length > 0) {
      const slider = new Splide(`#${sliderID}`, sliderOptions);
      slider.mount();
    }
  }, [sliderID, sliderOptions]);
  if (children.length > 0) {
    return (
      <div
        className={`splide ${styles.themeSlider}`}
        aria-label="Theme Slider with Default Components"
        id={sliderID}
      >
        <div className="splide__arrows">
          <button
            type="button"
            className="splide__arrow splide__arrow--prev"
            style={{
              "--prev-arrow-url": `url(${defaultConfig.leftArrow})`,
            }}
          >
            Prev
          </button>
          <button
            type="button"
            className="splide__arrow splide__arrow--next"
            style={{
              "--next-arrow-url": `url(${defaultConfig.rightArrow})`,
            }}
          >
            Next
          </button>
        </div>
        <div className="splide__track">
          <div className={`splide__list ${desktopConfig.disableCarousel ? styles.themeFlex : ''}`}>
            {
              children.map((child) => (
                <div key={Math.random().toString()} className="splide__slide">{child}</div>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
  return (
    <div style={{ color: "#fff", fontSize: "30px", textAlign: "center" }}>Please Insert the Slider Items</div>
  );
}

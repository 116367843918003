import * as React from 'react';
import styles from './PageNotFound.module.less';
import ObfuscatedEmail from '../../../components/ObfuscatedEmail/ObfuscatedEmail';
import Link from '../../../components/Link/Link';

const icon = (
  <svg className={styles.errorPageImage} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 138 149">
    <g>
      <path
        fill="#37414b"
        d="M1.003 147.773a1 1 0 0 1-1-.989c-.023-2.283.07-4.565.277-6.78a60.958 60.958 0 0 1 1.145-7.379 58.355 58.355 0 0 1 1.255-4.765 56.646 56.646 0 0 1 5.278-11.97 54.327 54.327 0 0 1 5.918-8.217 1 1 0 0 1 1.504 1.317 52.369 52.369 0 0 0-5.699 7.914 54.632 54.632 0 0 0-5.09 11.548 56.676 56.676 0 0 0-1.213 4.6 59.455 59.455 0 0 0-1.107 7.138 63.685 63.685 0 0 0-.268 6.572 1 1 0 0 1-.99 1.011z"
      />
    </g>
    <g>
      <path
        fill="#37414b"
        d="M16.55 148.996h-.023a1 1 0 0 1-.977-1.022c.292-12.922 5.415-23.531 10.363-28.312a.999.999 0 1 1 1.39 1.438c-4.654 4.497-9.474 14.565-9.754 26.918a1 1 0 0 1-.999.978"
      />
    </g>
    <g>
      <path
        fill="#e1e4e6"
        d="M61.57 48.259a8.001 8.001 0 0 1-5.631-13.683L88.49 2.316a8.001 8.001 0 0 1 11.263 11.365l-32.552 32.26a7.976 7.976 0 0 1-5.631 2.318"
      />
    </g>
    <g>
      <path
        fill="#e1e4e6"
        d="M96.61 83.03a8.001 8.001 0 0 1-5.631-13.683l32.551-32.26a8.005 8.005 0 0 1 11.314.05 8.002 8.002 0 0 1-.051 11.314l-32.552 32.261a7.976 7.976 0 0 1-5.631 2.317"
      />
    </g>
    <g>
      <path
        fill="#e1e4e6"
        d="M16.353 76.78L4.924 88.218a11.913 11.913 0 0 0 0 16.877l27.023 27.045c4.62 4.624 12.229 4.638 16.864-.001l11.429-11.438z"
      />
    </g>
    <g>
      <path
        fill="#fff"
        d="M90.602 113.131l23.721-23.738a6.443 6.443 0 0 0 0-9.104L56.68 22.604a6.42 6.42 0 0 0-9.087 0l-6.463 6.469"
      />
    </g>
    <g>
      <path
        fill="#fff"
        d="M24.85 45.36L9.454 60.763a6.442 6.442 0 0 0 0 9.105l57.642 57.693a6.42 6.42 0 0 0 9.087 0l4.326-4.33"
      />
    </g>
    <g>
      <path
        fill="#00825a"
        d="M76.576 81.353l-5.952-5.956 12.866-3.855L72.225 60.27l-23.681 7.182 7.281 7.286L36.81 85.671z"
      />
    </g>
    <g>
      <path fill="#e1e4e6" d="M18.221 92.724L9.33 83.81"/>
    </g>
    <g>
      <path
        fill="#37414b"
        d="M115.03 79.582L57.387 21.896a7.375 7.375 0 0 0-5.25-2.176 7.378 7.378 0 0 0-5.252 2.176l-6.463 6.47a1 1 0 0 0 1.415 1.414l6.463-6.469a5.382 5.382 0 0 1 3.836-1.591c1.45 0 2.811.564 3.836 1.591l57.643 57.685a5.448 5.448 0 0 1 0 7.69l-23.72 23.738a1 1 0 0 0 1.414 1.414L115.03 90.1c2.896-2.9 2.896-7.619 0-10.518"
      />
    </g>
    <g>
      <path
        fill="#37414b"
        d="M79.802 122.515a1 1 0 1 1 1.415 1.414l-4.326 4.33a7.372 7.372 0 0 1-5.25 2.176 7.375 7.375 0 0 1-5.252-2.177l-6.146-6.15-6.355 6.36c-.002.001-.003.005-.005.007l-.008.005-4.353 4.357c-2.428 2.43-5.673 3.767-9.139 3.767h-.007c-3.465 0-6.708-1.339-9.133-3.766L4.22 105.793c-5.039-5.043-5.039-13.248 0-18.29L14.942 76.77l-6.195-6.199c-2.897-2.9-2.897-7.618 0-10.518l15.397-15.4a.999.999 0 1 1 1.414 1.413L10.161 61.468a5.449 5.449 0 0 0 .001 7.69l6.9 6.904.002.002 43.887 43.921.001.002 6.852 6.857a5.384 5.384 0 0 0 3.836 1.591c1.45 0 2.812-.564 3.836-1.59zm-20.973-1.829L16.356 78.19l-5.615 5.619 8.187 8.206a1 1 0 1 1-1.416 1.411l-8.185-8.204-3.693 3.695c-4.26 4.263-4.26 11.199 0 15.46l27.024 27.038c2.047 2.048 4.79 3.178 7.72 3.179h.005c2.932 0 5.674-1.129 7.724-3.18l3.655-3.657-23.55-23.605a1 1 0 1 1 1.416-1.412l23.548 23.602z"
      />
    </g>
  </svg>
);

const ErrorPage = () => (
  <div className={styles.errorPage}>
    <div className={styles.errorPageContainer}>
      {icon}
      <h1 className={styles.errorPageTitle}>Oops!</h1>
      <h3 className={styles.errorPageSubtitle}>
        We encountered an unexpected error, please refresh your browser.
      </h3>
      <p className={styles.errorPageText}>
        Return to our
        {' '}
        <Link href="/" id="home-page" className={styles.linkPrimary}>
          home page
        </Link>
        {' '}
        or
        {' '}
        <ObfuscatedEmail
          emailDomain="insidetracker.com"
          emailName="sales"
          className={styles.linkPrimary}
        >
          contact support
        </ObfuscatedEmail>
        {' '}
        if the problem persists.
      </p>
    </div>
  </div>
);

export default ErrorPage;

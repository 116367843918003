import React from 'react';

/* Styles */
import { useSelector } from 'react-redux';
import styles from '../../../Header.module.less';

/* Components */
import mainActionLinks, { loginLogoutActionLinks } from './ActionLinks';
import { UserData } from '../../../../../interfaces/Data/responceDataTypes';
import Link from '../../../../Link/Link';
import { configurationSelector } from '../../../../../store/common/selectors';

/**
 * Action buttons props types definitions
 */
type ActionButtonsProps = {
  /** Is user logged in */
  isLoggedIn: boolean,
  /** Router history */
  history: any,
  /** User data */
  user: UserData,
  /**
   * is public pages flag
   */
  publicPages: boolean
};

/**
 * Auth panel action buttons
 * @category Header
 * @subcategory Auth panel
 * @borrows ActionButtonsProps as props
 * @component
 * @hideconstructor
 * @example
 * const user = {
 * id: 123,
 * email: 'test@test.com',
 * firstName: 'test',
 * middleName: 'test',
 * lastName: 'test',
 * phone: 'test',
 * phoneCode: 'test',
 * activationState: 'test',
 * hasInnerAgeResult: true,
 * isDnaOnly: true,
 * hasUnusedTest: true,
 * permissions: [],
 * photoSrc: '',
 * hasDiyPackage: true,
 * canAddInnerAge: true,
 * }
 * return <ActionButtons user={user} history={{}} isLoggedIn={true}/>
 */
const ActionButtons = (props: ActionButtonsProps) => {
  const {
    history, user, publicPages, isLoggedIn,
  } = props;

  const configuration = useSelector(configurationSelector);
  return (
    <div className={styles.authpanelActions}>
      <div className={styles.authpanelActionsInner}>
        {mainActionLinks(history, user, configuration, publicPages)}
        {isLoggedIn && (
          <Link
            id="my-account"
            className={`${styles.linkHeader} ${styles.linkAccount}`}
            href="/customer/account"
          >
            My Account
          </Link>
        )}
        {loginLogoutActionLinks(history, user)}
      </div>
    </div>
  );
};

export default ActionButtons;

import React from 'react';
/* Components */
import { userActivationStates } from '../../../../../Constants/User';
import { AuthPanelButtonLink } from './AuthPanelButton';
import AuthPanelActionButton from './AuthPanelActionButton';
import { UserData } from '../../../../../interfaces/Data/responceDataTypes';
import { closeMobileMenuEvent } from '../../../Header';
import { Configuration } from '../../../../../interfaces/Store/Configuration';

/**
 * @component
 * @category Header
 * @subcategory Auth panel
 * @hideconstructor
 * @returns React fragment with needed links
 */
export const loginLogoutActionLinks = (history: any, user: UserData) => {
  const isLoggedIn = user !== null;
  const handleClick = () => {
    document.dispatchEvent(closeMobileMenuEvent);
  };
  return (
    <>
      {!isLoggedIn && (
        <AuthPanelButtonLink
          link="/customer/login/"
          name="Log In"
          onClick={handleClick}
          id="logInLink"
        />
      )}

      {isLoggedIn && (
        <AuthPanelButtonLink
          link="/customer/login/logout"
          name="Log Out"
          id="logOutLink"
          onClick={handleClick}
        />
      )}
    </>
  );
};

/**
 * @component
 * @category Header
 * @subcategory Auth panel
 * @hideconstructor
 * @returns React fragment with needed links
 */
const mainActionLinks = (history: any, user: UserData, configuration: Configuration, publicPages?: boolean) => {
  const isLoggedIn = user !== null;
  const redirectToChoosePlan = () => {
    const storeUrl = configuration.shopifyUrl;

    // todo create choose plan page on react and change to history push
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    [...document.body.classList].filter((item) => item.includes('referralBanner')).length
      ? history.push('/bloodresultsupload')
      // @ts-ignore
      : document.location = storeUrl;
  };
  return (
    <>
      {!isLoggedIn && (
        <AuthPanelActionButton
          onClick={redirectToChoosePlan}
          id="shopNowBtn"
          name="Shop now"
        />
      )}

      {isLoggedIn && publicPages && (
        <AuthPanelActionButton
          onClick={redirectToChoosePlan}
          id="shopNowBtn"
          name="Shop now"
        />
      )}

      {!publicPages && isLoggedIn
      && user.activationState === userActivationStates.active && (
        <AuthPanelActionButton
          onClick={() => {
            // @ts-ignore
            history.push('/customer/onboarding/test-list/');
          }}
          name="My orders"
        />
      )}
    </>
  );
};

export default mainActionLinks;

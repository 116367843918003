import { createAsyncThunk } from '@reduxjs/toolkit';
import { FullStoryAPI } from 'react-fullstory';
import accountApi from '../../services/Customer/accountApi';
import authApi from '../../services/Customer/authApi';
import innerageApi from '../../services/Customer/innerageApi';
import subscriptionsApi from '../../services/Customer/SubscriptionsApi';
import TableViewApi from '../../services/Customer/TableViewApi';
import dnaApi from "../../services/Customer/dnaApi";

export const fetchUserProfile = createAsyncThunk(
  'user/fetchUserProfile',
  async () => {
    const data = await accountApi.getFullProfile();

    FullStoryAPI('identify', data.customer.id.toString(), {
      Environment_str: 'Production',
    });
    return {
      customer: data.customer,
      settings: data.settings,
      address: data.address,
      rosetta: data.rosetta,
    };
  },
);

export const fetchUserData = createAsyncThunk(
  'user/fetchUserData',
  async () => await accountApi.getUserData(),
);

export const fetchUserAddresses = createAsyncThunk(
  'user/fetchUserAddresses',
  async () => await accountApi.getUserAddresses(),
);

export const fetchUserSettings = createAsyncThunk(
  'user/fetchUserSettings',
  async () => await accountApi.getUserSettings(),
);

export const fetchAccountReferences = createAsyncThunk(
  'user/fetchAccountReferences',
  async () => await accountApi.getAllReferences(),
);

export const fetchAccountSecurity = createAsyncThunk(
  'user/fetchAccountSecurity',
  async () => await accountApi.getSecuritySettings(),
);

export const fetchLogoutUser = createAsyncThunk(
  'admin/fetchLogoutUser',
  async () => {
    try {
      await authApi.logout();
    } catch (e) {
      // unauthorized
    }
  },
);

export const fetchInnerageResults = createAsyncThunk(
  'user/fetchInnerageResults',
  () => innerageApi.getResults(),
);

export const fetchInnerageBloodMarkers = createAsyncThunk(
  'user/fetchInnerageBloodMarkers',
  () => innerageApi.getBloodMarkers(),
);

export const fetchInnerageDailyActions = createAsyncThunk(
  'user/fetchInnerageDailyActions',
  () => innerageApi.getDailyActiveActions(),
);

export const fetchBloodResultsUploadSubscriptionActions = createAsyncThunk(
  'user/fetchBloodResultsUploadSubscription',
  () => subscriptionsApi.bloodResultUpload(),
);

export const fetchWomanHealth = createAsyncThunk(
  'user/fetchWomanHealth',
  () => accountApi.getWomanHealth(),
);

export const fetchBloodResults = createAsyncThunk(
  'user/fetchBloodResults',
  () => TableViewApi.getResults(),
);

export const fetchBloodRanges = createAsyncThunk(
  'user/fetchBloodRanges',
  () => TableViewApi.getRanges(),
);

export const fetchNotificationTags = createAsyncThunk(
  'user/fetchNotificationTags',
  () => dnaApi.getNotificationsTags(),
);

export const fetchCategoryResults = createAsyncThunk(
  'user/fetchCategoryResults',
  () => Promise.all([
    TableViewApi.getUserMarkerCategories(),
    TableViewApi.getCategoriesForResults(),
  ]),
);

export default {
  fetchUserProfile,
  fetchLogoutUser,
  fetchInnerageDailyActions,
  fetchBloodResultsUploadSubscriptionActions,
  fetchNotificationTags,
};

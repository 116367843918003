import React from 'react';
import { IntBannerPropsTypes } from './IntBanner';
import Spinner from '../../../../../components/Spinner/Spinner';

export default (props: IntBannerPropsTypes) => {
  const IntBanner = React.lazy(() => import('./IntBanner'));

  return (
    <React.Suspense fallback={<Spinner show/>}>
      <IntBanner {...props} />
    </React.Suspense>
  );
};

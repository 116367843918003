import adminAuthorizedAxios from './adminAuthorizedAxios';
import { AdminData } from '../../store/admin/types';

export default {
  getProfile: (): Promise<AdminData | null> => adminAuthorizedAxios.get('/api/admin/settings')
    .then((responseData) => responseData.data),

  getAuthData: (): Promise<AdminData | null> => adminAuthorizedAxios.get('/api/admin/auth-profile')
    .then((responseData) => responseData.data),
};

import { Builder, withChildren } from "@builder.io/react";

import ThemeSlider from "./themeSlider";

const themeSliderWithChildren = withChildren(ThemeSlider);

Builder.registerComponent(themeSliderWithChildren, {
  name: 'Theme Slider',
  inputs: [
    {
      type : "text",
      name: "sliderID",
      friendlyName: "Slider ID",
      defaultValue: "teamSlider",
      helperText: "Please add the ID without any space. For Example: teamSlider"
    },
    {
      name : "defaultConfig",
      type: "object",
      defaultValue: {
        slideGap: "20px",
        focusCenter: false,
        paddingLeft: "0",
        paddingRight: "0",
        type: "slide",
        direction: "horizontal",
        startIndex: "0",
      },
      subFields: [
        {
          type: "boolean",
          name : "autoPlay",
          defaultValue: false,
          friendlyName: "Auto Play"
        },
        {
          type: "text",
          name : "autoPlayInterval",
          defaultValue: "2000",
          friendlyName: "Auto Play Interval"
        },
        {
          type: "text",
          name : "slideGap",
          defaultValue: "20px",
          friendlyName: "Each Slide Gap"
        },
        {
          type: "boolean",
          name : "focusCenter",
          defaultValue: false,
          friendlyName: "Center Mode"
        },
        {
          type: "text",
          name : "paddingLeft",
          defaultValue: "0",
          friendlyName: "Slider Padding Left"
        },
        {
          type: "text",
          name : "paddingRight",
          defaultValue: "0",
          friendlyName: "Slider Padding Right"
        },
        {
          name: "type",
          type: "string",
          defaultValue: "slide",
          friendlyName: "Slider Type",
          enum: ["slide", "loop"],
        },
        {
          type: "number",
          name : "startIndex",
          defaultValue: "0",
          friendlyName: "Slider Start Index",
          helperText: "First Slider Number is 0 so if you want to have the third slide to first then the value should be 2"
        },
        {
          type: "file",
          name: "leftArrow",
          friendlyName: "Left Arrow Icon SVG File",
          allowedFileTypes: ["svg"]
        },
        {
          type: "file",
          name: "rightArrow",
          friendlyName: "Right Arrow Icon SVG File",
          allowedFileTypes: ["svg"]
        }
      ]
    },
    {
      name: "desktopConfig",
      type: "object",
      defaultValue: {
        slidesToShow: "4",
        slidesToMove: "1",
        showPagination: false,
        showArrows: false,
        sliderWidth: "auto",
        disableCarousel: false
      },
      subFields: [
        {
          type : "number",
          name: "slidesToShow",
          friendlyName: "Slides To Show",
          defaultValue: "4"
        },
        {
          type : "number",
          name: "slidesToMove",
          friendlyName: "Slides To Move",
          defaultValue: "1"
        },
        {
          type : "boolean",
          name: "showPagination",
          friendlyName: "Show Pagination",
          defaultValue: false
        },
        {
          type : "boolean",
          name: "showArrows",
          friendlyName: "Show Arrows",
          defaultValue: false
        },
        {
          type : "number",
          name: "sliderWidth",
          friendlyName: "Slider Width",
          defaultValue: "auto"
        },
        {
          type: "boolean",
          name : "disableCarousel",
          defaultValue: false,
          friendlyName: "Disable Carousel"
        }
      ]
    },
    {
      name: "tabletConfig",
      type: "object",
      defaultValue: {
        slidesToShow: "3",
        slidesToMove: "1",
        showPagination: false,
        showArrows: false,
        sliderWidth: "auto",
      },
      subFields: [
        {
          type : "number",
          name: "slidesToShow",
          friendlyName: "Slides To Show",
          defaultValue: "3"
        },
        {
          type : "number",
          name: "slidesToMove",
          friendlyName: "Slides To Move",
          defaultValue: "1"
        },
        {
          type : "boolean",
          name: "showPagination",
          friendlyName: "Show Pagination",
          defaultValue: false
        },
        {
          type : "boolean",
          name: "showArrows",
          friendlyName: "Show Arrows",
          defaultValue: false
        },
        {
          type : "number",
          name: "sliderWidth",
          friendlyName: "Slider Width",
          defaultValue: "auto"
        },
      ]
    },
    {
      name: "mobileConfig",
      type: "object",
      defaultValue: {
        slidesToShow: "2",
        slidesToMove: "1",
        showPagination: false,
        showArrows: false,
        sliderWidth: "auto",
      },
      subFields: [
        {
          type : "number",
          name: "slidesToShow",
          friendlyName: "Slides To Show",
          defaultValue: "2"
        },
        {
          type : "number",
          name: "slidesToMove",
          friendlyName: "Slides To Move",
          defaultValue: "1"
        },
        {
          type : "boolean",
          name: "showPagination",
          friendlyName: "Show Pagination",
          defaultValue: false
        },
        {
          type : "boolean",
          name: "showArrows",
          friendlyName: "Show Arrows",
          defaultValue: false
        },
        {
          type : "number",
          name: "sliderWidth",
          friendlyName: "Slider Width",
          defaultValue: "auto"
        },
      ]
    }
  ],
  defaultChildren: [
    {
        '@type' : '@builder.io/sdk:Element',
        component: {
            name: 'Theme Team Card',
            options: {
              image: 'https://placehold.co/330x330',
              name: 'Gill Blander, PhD',
              titleBgColor: '#EBF5F0',
              title: 'Founder, Chief Scientific Officer',
              contentColor: '#005550'
            }
        }
    },
    {
      '@type' : '@builder.io/sdk:Element',
      component: {
          name: 'Theme Team Card',
          options: {
            image: 'https://placehold.co/330x330',
            name: 'Gill Blander, PhD',
            titleBgColor: '#EBF5F0',
            title: 'Founder, Chief Scientific Officer',
            contentColor: '#005550'
          }
      }
    },
    {
      '@type' : '@builder.io/sdk:Element',
      component: {
          name: 'Theme Team Card',
          options: {
            image: 'https://placehold.co/330x330',
            name: 'Gill Blander, PhD',
            titleBgColor: '#EBF5F0',
            title: 'Founder, Chief Scientific Officer',
            contentColor: '#005550'
          }
      }
    },
    {
      '@type' : '@builder.io/sdk:Element',
      component: {
          name: 'Theme Team Card',
          options: {
            image: 'https://placehold.co/330x330',
            name: 'Gill Blander, PhD',
            titleBgColor: '#EBF5F0',
            title: 'Founder, Chief Scientific Officer',
            contentColor: '#005550'
          }
      }
    }
  ]
})
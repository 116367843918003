import { Builder } from "@builder.io/react";

import themeParagraph from "./themeParagraph.jsx";

Builder.registerComponent(themeParagraph, {
    name: 'Theme Paragraph',
    inputs: [
        {
            type: "richText",
            name: "content",
            friendlyName: "Content",
            defaultValue: "<p>Hello From Paragraph Content</p>"
        }
    ],
    noWrap: true,
    defaultStyles: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: "400",
        color: "#232D37",
    },
    defaults: {
        responsiveStyles: {
            large: {
                fontSize: "24px",
                lineHeight: "36px",
                letterSpacing: "0.36px",
            },
            medium: {
                fontSize: "24px",
                lineHeight: "36px",
                letterSpacing: "0.36px",
            },
            small: {
                fontSize: "15px",
                lineHeight: "20px",
                letterSpacing: "0.22px",
            }
        }
    }
})
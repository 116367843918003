import { Builder } from "@builder.io/react";

import themeStepCard from "./themeStepCard";

Builder.registerComponent(themeStepCard, {
  name: "Theme Step Card",
  inputs: [
    {
      name: "image",
      type: "file",
      friendlyName: "Image",
      allowedFileTypes: ["jpeg", "jpg", "png"],
      defaultValue: "https://placehold.co/340x210"
    },
    {
      name: "title",
      type: "text",
      friendlyName: "Title",
      defaultValue: "Explore our plans"
    },
    {
      name: "info",
      type: "longText",
      friendlyName: "Info",
      defaultValue: "Take the first step down your personalized path to living healthier longer."
    },
    {
      name: "step",
      type: "string",
      friendlyName: "Step Text",
      defaultValue: "Step 1"
    },
    {
      name: "showRepeatSteps",
      friendlyName: "Show Repeat Steps Tag",
      type: "boolean",
      defaultValue: false
    }
  ]
})
import React, { ReactElement } from 'react';
import styles from './FooterMenuList.module.less';

const FooterMenuList = (props: { title: string, children: ReactElement | (ReactElement | string)[] }) => {
  const { title, children } = props;
  return (
    <div className={styles.footerMenuList}>
      <p className={styles.footerTitle}>{title}</p>
      {children}
    </div>
  );
};

export default React.memo(FooterMenuList);

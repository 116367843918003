import React from 'react';
import { useHistory } from "react-router-dom";
import Popup from '../../../../components/Popup/Popup';
import styles from './Popups.module.less';
import Button from "../../../../components/Buttons/Button";
import Link from "../../../../components/Link/Link";
import useMobile from "../../../../hooks/useMobile";
import routes from "../../../../references/routes";

const StarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
    <path
      d="M60.5286 86.0141C60.7767 87.0317 62.2237 87.0317 62.4718 86.0141L65.4002 74L77.2224 70.9686C78.2246 70.7117 78.2246 69.2883 77.2224 69.0313L65.4002 66L62.4718 53.9858C62.2237 52.9683 60.7767 52.9683 60.5286 53.9858L57.6002 66L45.778 69.0313C44.7758 69.2883 44.7758 70.7117 45.778 70.9686L57.6002 74L60.5286 86.0141Z"
      fill="white"
    />
    <path
      d="M64.0245 71.8708L63.5871 71.9761L63.4741 72.4115L61.5 80.0153L59.5259 72.4115L59.4129 71.9761L58.9755 71.8708L51.2044 70L58.9755 68.1292L59.4129 68.0239L59.5259 67.5885L61.5 59.9847L63.4741 67.5885L63.5871 68.0239L64.0245 68.1292L71.7956 70L64.0245 71.8708Z"
      fill="#A0D7AF"
      stroke="#232D37"
      strokeWidth="1.5"
    />
    <path
      d="M64.0447 50.1791C64.4234 51.694 66.5764 51.694 66.9551 50.1791L70.7999 34.7999L86.1791 30.9551C87.694 30.5764 87.694 28.4234 86.1791 28.0447L70.7999 24.1999L66.9551 8.82076C66.5764 7.30584 64.4234 7.30584 64.0447 8.82077L60.1999 24.1999L44.8208 28.0447C43.3058 28.4234 43.3058 30.5764 44.8208 30.9551L60.1999 34.7999L64.0447 50.1791Z"
      fill="white"
    />
    <path
      d="M69.4223 32.2714L68.9852 32.378L68.8735 32.8138L65.7422 45.0335C65.7214 45.1144 65.6859 45.1529 65.6548 45.1754C65.6177 45.2022 65.5636 45.2215 65.5 45.2215C65.4364 45.2215 65.3823 45.2022 65.3452 45.1754C65.3141 45.1529 65.2786 45.1144 65.2578 45.0335L62.1265 32.8138L62.0148 32.378L61.5777 32.2714L49.1609 29.2429C49.0782 29.2227 49.0394 29.187 49.0169 29.1562C48.9899 29.1192 48.9702 29.0645 48.9702 29C48.9702 28.9355 48.9899 28.8808 49.0169 28.8438C49.0394 28.813 49.0782 28.7773 49.1609 28.7571L61.5777 25.7286L62.0148 25.622L62.1265 25.1862L65.2578 12.9665C65.2785 12.8856 65.3141 12.8471 65.3452 12.8246C65.3823 12.7978 65.4364 12.7785 65.5 12.7785C65.5636 12.7785 65.6177 12.7978 65.6548 12.8246C65.6859 12.8471 65.7214 12.8856 65.7422 12.9665L68.8735 25.1862L68.9852 25.622L69.4223 25.7286L81.8391 28.7571C81.9218 28.7773 81.9606 28.813 81.983 28.8438C82.0101 28.8808 82.0298 28.9355 82.0298 29C82.0298 29.0645 82.0101 29.1192 81.9831 29.1562C81.9606 29.187 81.9218 29.2227 81.8391 29.2429L69.4223 32.2714Z"
      fill="#A0D7AF"
      stroke="#232D37"
      strokeWidth="1.5"
    />
    <path
      d="M30.4166 76.5206C31.1173 78.0973 33.3519 78.1063 34.0652 76.5353L42.6062 57.727L61.3197 49.2787C62.8891 48.5702 62.8891 46.3415 61.3197 45.633L42.6062 37.1847L34.0652 18.3764C33.3518 16.8054 31.1173 16.8144 30.4166 18.3911L22.0639 37.1847L3.17535 45.63C1.59715 46.3357 1.59715 48.576 3.17535 49.2817L22.0639 57.727L30.4166 76.5206Z"
      fill="white"
    />
    <mask id="path-6-inside-1_812_793" fill="white">
      <path
        d="M30.8686 72.2086C31.3942 73.3911 33.07 73.3979 33.6051 72.2196L40.8938 56.169L56.8639 48.9592C58.0409 48.4278 58.041 46.7563 56.8639 46.2249L40.8938 39.0151L33.6051 22.9645C33.07 21.7863 31.3941 21.793 30.8686 22.9755L23.7399 39.0151L7.61967 46.2227C6.43602 46.7519 6.43602 48.4322 7.61967 48.9614L23.7399 56.169L30.8686 72.2086Z"
      />
    </mask>
    <path
      d="M30.8686 72.2086C31.3942 73.3911 33.07 73.3979 33.6051 72.2196L40.8938 56.169L56.8639 48.9592C58.0409 48.4278 58.041 46.7563 56.8639 46.2249L40.8938 39.0151L33.6051 22.9645C33.07 21.7863 31.3941 21.793 30.8686 22.9755L23.7399 39.0151L7.61967 46.2227C6.43602 46.7519 6.43602 48.4322 7.61967 48.9614L23.7399 56.169L30.8686 72.2086Z"
      fill="#A0D7AF"
    />
    <path
      d="M40.8938 56.169L40.2766 54.8019L39.7616 55.0344L39.528 55.5488L40.8938 56.169ZM40.8938 39.0151L39.528 39.6353L39.7616 40.1498L40.2766 40.3823L40.8938 39.0151ZM23.7399 39.0151L24.3521 40.3845L24.8771 40.1498L25.1106 39.6243L23.7399 39.0151ZM23.7399 56.169L25.1106 55.5598L24.8771 55.0344L24.3521 54.7997L23.7399 56.169ZM7.61967 46.2227L8.23193 47.5921L7.61967 46.2227ZM7.61967 48.9614L7.00741 50.3308L7.61967 48.9614ZM33.6051 22.9645L34.9709 22.3443L33.6051 22.9645ZM30.8686 22.9755L32.2393 23.5847L30.8686 22.9755ZM56.8639 48.9592L56.2467 47.5921L56.8639 48.9592ZM56.8639 46.2249L56.2467 47.5921L56.8639 46.2249ZM33.6051 72.2196L32.2393 71.5994L33.6051 72.2196ZM30.8686 72.2086L29.4979 72.8178L30.8686 72.2086ZM34.9709 72.8398L42.2595 56.7892L39.528 55.5488L32.2393 71.5994L34.9709 72.8398ZM41.511 57.5361L57.4811 50.3263L56.2467 47.5921L40.2766 54.8019L41.511 57.5361ZM57.4811 44.8578L41.511 37.648L40.2766 40.3823L56.2467 47.5921L57.4811 44.8578ZM42.2595 38.3949L34.9709 22.3443L32.2393 23.5847L39.528 39.6353L42.2595 38.3949ZM29.4979 22.3663L22.3692 38.4059L25.1106 39.6243L32.2393 23.5847L29.4979 22.3663ZM23.1276 37.6458L7.00741 44.8534L8.23193 47.5921L24.3521 40.3845L23.1276 37.6458ZM7.00741 50.3308L23.1276 57.5384L24.3521 54.7997L8.23193 47.5921L7.00741 50.3308ZM22.3692 56.7782L29.4979 72.8178L32.2393 71.5994L25.1106 55.5598L22.3692 56.7782ZM7.00741 44.8534C4.6401 45.9118 4.64011 49.2723 7.00741 50.3308L8.23193 47.5921L8.23193 47.5921L7.00741 44.8534ZM34.9709 22.3443C33.9008 19.9878 30.549 20.0013 29.4979 22.3663L32.2393 23.5847V23.5847L34.9709 22.3443ZM57.4811 50.3263C59.8352 49.2636 59.8352 45.9206 57.4811 44.8578L56.2467 47.5921L56.2467 47.5921L57.4811 50.3263ZM32.2393 71.5994L32.2393 71.5994L29.4979 72.8178C30.549 75.1829 33.9008 75.1963 34.9709 72.8398L32.2393 71.5994Z"
      fill="#232D37"
      mask="url(#path-6-inside-1_812_793)"
    />
    <path d="M49.8418 48.2703L47.5952 49.1636" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M71.7324 30L68.9998 30.6831" stroke="white" strokeLinecap="round"/>
    <path d="M67.3322 32.0001L66.3075 36.099" stroke="white" strokeLinecap="round"/>
    <path d="M66.3818 69.9995L63.6492 70.6827" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
    <path d="M44.8623 50.53L40.0803 52.9211" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M37.0068 57.7029L34.9574 62.1433" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
);
const IntroduceAiPopup = (props: { onClosePopup: () => void, show: boolean }) => {
  const {
    onClosePopup, show,
  } = props;
  const history = useHistory();
  const isMobile = useMobile();

  return (
    <Popup
      onClose={onClosePopup}
      open={show}
      showCloseBlock
      popupClasses={styles.IntroduceAiPopup}
      maxWidth={450}
    >
      <div>
        <StarIcon/>
        <h3 className={styles.heading24}>Ask InsideTracker AI</h3>
        <p className={styles.text16}>
          Your go-to for science-backed answers
          on biomarkers, activity, healthspan, and more!
        </p>
        <p className={`${styles.text16} ${styles.hideBigMobile}`}>
          Tip: For easy access, click the
          {' '}
          <svg className={styles.IntroduceAiPopupIcon} xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.8201 8.6L15.8253 13L14.8306 8.6L10.8517 7.5L14.8306 6.4L15.8253 2L16.8201 6.4L20.799 7.5L16.8201 8.6ZM7.68659 20L9.81171 14.825L14.4689 12.5L9.81171 10.175L7.68659 5L5.60669 10.175L0.904297 12.5L5.60669 14.825L7.68659 20ZM14.921 22L15.5541 19.2L18.0861 18.5L15.5541 17.8L14.921 15L14.288 17.8L11.756 18.5L14.288 19.2L14.921 22Z"
              fill="#232D37"
            />
          </svg>
          {' '}
          icon at the top of the page.
        </p>
        <Button
          onClick={() => {
            history.push(routes.customer.askIt);
            onClosePopup();
          }}
          green
          superLarge={!isMobile}
          id="ask-it-start"
          text="Let’s start"
          className={styles.IntroduceAiPopupBtn}
        />
        <Link
          onClick={() => {
            onClosePopup();
          }}
          id="ask-it-later"
          className={styles.IntroduceAiPopupLink}
        >
          Maybe later
        </Link>
      </div>
    </Popup>
  );
};

export default IntroduceAiPopup;

import React, { useEffect } from 'react';

// Styles
import styles from '../../pages/customer/Authentication/Authentication.module.less';

// Components
import Popup from './Popup';
import Button, { ButtonLink } from '../Buttons/Button';
import CustomerAuthTokenProvider from "../../services/Customer/customerAuthTokenProvider";

const ExpiredSessionPopup = (props: { opened: boolean, onClose: () => void }) => {
  const {
    opened,
    onClose,
  } = props;

  useEffect(() => {
    CustomerAuthTokenProvider.remove();
  }, []);

  return (
    <Popup
      onClose={onClose}
      open={opened}
      showCloseBlock
      popupClasses={styles.sessionPopup}
      popupCloseBlockClasses={styles.mobileCloseBlock}
    >
      <>
        <h2 className={`${styles.heading20} ${styles.popupTitle}`}>
          Your session has expired
        </h2>
        <p className={`${styles.text14} ${styles.popupText}`}>
          For security reasons, please log in again.
        </p>
        <div className={`${styles.bottomButtonsRight}`}>
          <Button
            id="expired-session-close"
            secondary
            className={styles.btnSecondary}
            text="Close window"
            onClick={onClose}
          />
          <ButtonLink
            id="expired-session-log-in"
            ml={5}
            className={styles.secondBtn}
            href="/customer/login"
            text="Log in"
            type="button"
            green
            external
            target="_self"
          />
        </div>
      </>
    </Popup>
  );
};

export default ExpiredSessionPopup;

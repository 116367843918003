import React from 'react';

/* Styles */
import { Link } from 'react-router-dom';
import styles from './LogoLink.module.less';
import Icon from '../../../Icons/Icon';

const LogoIcon = <Icon iconName="general_itLogoInlineWhite" className={styles.logoLinkIcon}/>;

/* Components */

/**
 * LogoLink props types definitions
 */
type LogoLinkPropsTypes = {
  /**
   * Additional className
   */
  className?: string,
  /**
   * Link destination
   */
  to?: string,
  /**
   * Push
   */
  push?: boolean,
  /**
   * Is external
   */
  external?: boolean
};

/**
 * Go to home page =)
 * @category Header
 * @component
 * @borrows LogoLinkPropsTypes as props
 * @hideconstructor
 * @example
 * return <LogoLink />
 */

const LogoLink = (props: LogoLinkPropsTypes) => {
  const {
    className, to,
  } = props;
  const destination = to ?? '/';
  return (
    <Link
      className={`${styles.logoLink}${className ? ` ${className}` : ''}`}
      to={destination}
    >
      {LogoIcon}
    </Link>
  );
};

export default LogoLink;

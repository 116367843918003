import { AxiosProgressEvent } from 'axios';
import userAuthorizedAxios from './userAuthorizedAxios';
import { DnaReportResponse } from '../../interfaces/Dna/DnaReport';
import customerAuthorizedFileDownloader from "./customerAuthorizedFileDownloader";
import {
  SuccessUploadDnaResponse,
  FailUploadDnaResponse,
  SubmitDnaResponse,
} from '../../interfaces/Dna/UploadDna';
import { DnaKitResponse } from '../../interfaces/Dna/DnaKit';

export default {
  getDnaReportPageData: (): Promise<{
    hasDnaPdfReport: boolean,
    hasDnaOnlyPdfReport: boolean,
    state: string,
  }> => userAuthorizedAxios.get('/api/pages/account/dnaReportPageData').then((response) => response.data),

  getDnaReport: (): Promise<DnaReportResponse> => userAuthorizedAxios.get('/api/customer/v1/dna/report'),

  postIdentityToDownloadPdf: (email: string, password:string) => customerAuthorizedFileDownloader(
    '/api/pages/dna/download-pdf-report',
    { email, password },
    'Genetic_report.pdf',
  ),

  downloadPdf: () => customerAuthorizedFileDownloader(
    '/api/pages/dna/download-pdf-report',
    {},
    'Genetic_report.pdf',
  ),

  postIdentityToDownloadRawData: () => customerAuthorizedFileDownloader(
    '/api/pages/dna/download-raw-data',
    { },
    'Dna_Raw_Data_report.txt',
  ),

  uploadDna: (
    file: File,
    onUploadProgress: (e: AxiosProgressEvent) => void,
  ): Promise<SuccessUploadDnaResponse | FailUploadDnaResponse> => {
    const formData = new FormData();
    formData.append('result_file', file);

    return userAuthorizedAxios.post(
      '/customer/diy/upload-dna-post',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      },
    )
      .then((res) => res.data);
  },

  submitDna: (fileId: string): Promise<SubmitDnaResponse> => {
    const formData = new FormData();
    formData.append('files[]', fileId);

    return userAuthorizedAxios.post(
      '/customer/diy/submit-dna-post',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
      .then((res) => res.data);
  },
  downloadHealthspanReport: () => customerAuthorizedFileDownloader(
    '/api/customer/v1/healthspan-dna-report/download',
    {},
    'healthspan-dna.pdf',
    'GET'),
  getDnaReportStatus: () => userAuthorizedAxios.get('/api/customer/v1/healthspan-dna-report/status').then((response) => response.data),

  getDnaKit: (): Promise<DnaKitResponse> => userAuthorizedAxios.get('/api/pages/dnaKit/currentKitData')
    .then((response) => response.data),

  sendDnaKit: (dnaKitId: number) => userAuthorizedAxios.post('/api/pages/dnaKit/markSampleAsMailed', { dnaKitId })
    .then((response) => response.data),

  registerDnaKit: (dnaKitId: number, serialNumber: string) => userAuthorizedAxios
    .post('/api/pages/dnaKit/registerDnaKit', { dnaKitId, serialNumber })
    .then((response) => response.data),

  postSampleFailedPopup: (dnaKitId: number) => userAuthorizedAxios.post('/api/pages/dnaKit/mark-sample-fail-popup-as-viewed', { dnaKitId })
    .then((res) => res.data),

  getNotificationsTags: (): Promise<string[]> => userAuthorizedAxios.get('/api/customer/v2/notifications/list')
    .then((response) => response.data.tags),

  putNotificationsTags: (tag: string) => userAuthorizedAxios.put('/api/customer/v1/notifications/closed', { tag }),
};

import adminAuthorizedAxios from './adminAuthorizedAxios';
import adminJwtAuthorizedAxios from "./adminJwtAuthorizedAxios";
import config from "../../config";
import coachJwtAuthorizedAxios from "./coachJwtAuthorizedAxios";

export type InventorySummaryData = {
  ASSIGNED: number,
  UNASSIGNED: number,
  USED: number,
};

export default {
  loginByGuid: (guid: string): Promise<any> => adminAuthorizedAxios.get(`/api/coach/loginByGuid/${guid}`)
    .then((responseData) => responseData.data),
  getCoach: () => adminAuthorizedAxios.get('/api/coach/settings'),
  getUsers: (): Promise<any> => adminAuthorizedAxios.get('/api/coach/getUsers')
    .then((responseData) => responseData.data),
  getTeams: (): Promise<any> => adminAuthorizedAxios.get('/api/coach/getTeams')
    .then((responseData) => responseData.data),
  addTeam: (name: string) => adminAuthorizedAxios.post('/api/coach/addTeam', { name }),
  editTeam: (teamId: number, name: string) => adminAuthorizedAxios.put(`/api/coach/editTeam/${teamId}`, { name }),
  deleteTeam: (teamId: number) => adminAuthorizedAxios.delete(`/api/coach/deleteTeam/${teamId}`),
  uploadTeamLogo: (teamId: number, data: FormData) => adminAuthorizedAxios.post(
    `/api/coach/uploadTeamLogo/${teamId}`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  deleteTeamLogo: (teamId: number) => adminAuthorizedAxios.delete(`/api/coach/deleteTeamLogo/${teamId}`),
  getAvailablePlans: () => adminAuthorizedAxios.get('api/coach/availablePlans'),
  addNewMember: (teamId: number, customerEmail: string, customerEmailRetype: string) => adminJwtAuthorizedAxios.post(`/api/admin/v1/coach/teams/${teamId}/invite-customer`, {
    customerEmail,
    customerEmailRetype,
  }),
  getOrders: () => adminAuthorizedAxios.get('/api/coach/tests'),
  requestPermissions: (customerId: number, teamId: number) => adminAuthorizedAxios.get(`/api/coach/request-permissions/${customerId}/${teamId}`),
  moveToTeam: (customerId: number, teamIdFrom: number, teamIdTo: number) => adminAuthorizedAxios.get(`/api/coach/move-to-team/${customerId}/${teamIdFrom}/${teamIdTo}`),
  assignTestToUser: (testId: number, userId: number) => adminAuthorizedAxios.post(`/api/coach/assignTestToUser/${testId}/${userId}`),
  deleteUserFromTeam: (userId: number, teamId: number) => adminAuthorizedAxios.delete(`/api/coach/deleteUser/${userId}/${teamId}`),
  addUserToTeam: (userId: number, teamId: number) => adminAuthorizedAxios.get(`api/coach/addUserToTeam/${userId}/${teamId}`),

  getTestsCount: ():Promise<InventorySummaryData> => coachJwtAuthorizedAxios.get(`${config.apiGatewayUrl}/it-pro/user/v1/company/inventory/summary`)
    .then((responseData) => responseData.data.countByStatus),
  getInventory: (sort: string):Promise<any> => coachJwtAuthorizedAxios.get(`${config.apiGatewayUrl}/it-pro/user/v1/company/inventory?sort=${sort}`)
    .then((responseData) => responseData.data.items),
  getUpgradeLink: (inventoryItemId: number, addonTag: string):Promise<any> => coachJwtAuthorizedAxios.get(`${config.apiGatewayUrl}/it-pro/user/v1/store/blood-test-upgrade-url?inventoryItemId=${inventoryItemId}&addonTag=${addonTag}`)
    .then((responseData) => responseData.data.url),
  getMultipassUrl: (companyId: number, returnTo: string):Promise<any> => coachJwtAuthorizedAxios.get(`${config.apiGatewayUrl}/it-pro/user/v1/store/multipass-url?companyId=${companyId}&returnTo=${returnTo}`)
    .then((responseData) => responseData.data.url),
  unassignTest: (itemId: number) => coachJwtAuthorizedAxios.delete(`${config.apiGatewayUrl}/it-pro/user/v1/company/inventory/${itemId}/assignment`),
  assignTest: (customerId: number, itemId: number) => coachJwtAuthorizedAxios.post(`${config.apiGatewayUrl}/it-pro/user/v1/company/inventory/${itemId}/assignment`, { customerId }),
  assignTestToNewUser: (email: string, firstName: string, lastName: string, itemId: number) => coachJwtAuthorizedAxios.post(`${config.apiGatewayUrl}/it-pro/user/v1/company/inventory/${itemId}/assignment/new-customer`, { email, firstName, lastName }),
};

import React from 'react';
import Spinner from '../../components/Spinner/Spinner';

export default () => {
  const AdminSection = React.lazy(() => import('./AdminSection'));

  return (
    <React.Suspense fallback={<Spinner show/>}>
      <AdminSection/>
    </React.Suspense>
  );
};

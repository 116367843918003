type Config = {
  apiHostName: string,
  builderIOAPIKey: string,
  hostName: string,
  reCaptchaSiteKey: string,
  googleTagsKey: string,
  facebookPixelKey: string,
  buildVersion: string,
  isWebpackDevServer: boolean,
  fullStoryOrgId: string,
  isProduction: boolean,
  ddwebviewApiUrl: string,
  apiGatewayUrl: string,
};

const config: Config = {
  apiHostName: process.env.REACT_APP_API_HOST || '',
  builderIOAPIKey: process.env.REACT_APP_BUILDER_API_KEY || 'af828c5e99054ae89dd789088e60e44d',
  hostName: process.env.REACT_APP_API_HOST || '',
  reCaptchaSiteKey: process.env.REACT_APP_RECATCHA_V3_SITE_KEY || '6LezqJAUAAAAAJ69gTnOOSrXqnwxU84w_KA4n9Xj',
  googleTagsKey: process.env.REACT_APP_GOOGLE_TAG_KEY,
  facebookPixelKey: process.env.REACT_APP_FACEBOOK_PIXEL_KEY,
  buildVersion: process.env.BUILD_VERSION || '%BUILD_VERSION%',
  isWebpackDevServer: process.env.REACT_APP_IS_WEBPACK_DEV_SERVER === 'true',
  fullStoryOrgId: process.env.REACT_APP_FULL_STORY_ORG_ID,
  isProduction: process.env.REACT_APP_ENVIRONMENT === 'prod',
  ddwebviewApiUrl: process.env.REACT_APP_DDWEBVIEW_URL,
  apiGatewayUrl: process.env.REACT_APP_API_GATEWAY_URL,
} as Config;

export default config;

import React from 'react';
import { useSelector } from 'react-redux';
import FooterMenuList from '../FooterMenuList/FooterMenuList';
import { ExternalFooterMenuItem, FooterMenuItem, FooterMenuItemHash } from '../FooterMenuItem/FooterMenuItem';
import ReferralProgram from '../../../../Constants/ReferralProgram';
import AccountStatus from '../../../../Constants/ActivationState';
import RosettaStatus from '../../../../Constants/RosettaStatus';
import { rosettaSelector, userSelector } from '../../../../store/user/selectors';
import { configurationSelector, referralEngagementsSelector } from '../../../../store/common/selectors';
import { adminSelector } from '../../../../store/admin/selectors';
import routes from "../../../../references/routes";

const FooterMenu = () => {
  const user = useSelector(userSelector);
  const admin = useSelector(adminSelector);
  const rosetta = useSelector(rosettaSelector);
  const referralEngagements = useSelector(referralEngagementsSelector);
  const path = window.location.pathname;
  const configuration = useSelector(configurationSelector);
  const adminsExistedPaths = ['admin/coach'];
  const isAdminPath = admin
    && !!adminsExistedPaths.filter((adminPath) => path.includes(adminPath)).length;

  const isNotAccountStates = user && [
    AccountStatus.PURCHASE,
    AccountStatus.REQUIRED_DETAILS,
    AccountStatus.ACCOUNT,
    AccountStatus.ONBOARDING_REQUIRED,
  ].indexOf(user.activationState) === -1;

  const isActiveAccount = user && AccountStatus.ACTIVE === user.activationState;

  const hasReferrals = referralEngagements
    && referralEngagements.length;

  let showStore = !hasReferrals
    || (!referralEngagements.includes(ReferralProgram.blue365)
      && !referralEngagements.includes(ReferralProgram.massBio));

  if (user) {
    showStore = !user.isInvolvedBlue365;
  }

  let companyColumn = null;
  let accountColumn = null;
  let productColumn = null;

  companyColumn = (
    <FooterMenuList title="Company">
      <FooterMenuItem testId="company-about-us" link="/about" label="About us"/>
      <FooterMenuItem testId="company-terms-of-service" link="/terms-of-service/" label="Terms of Service"/>
      <FooterMenuItem testId="company-team" link="/team/" label="Team"/>
      <ExternalFooterMenuItem testId="company-careers" link="https://info.insidetracker.com/careers/" label="Careers" inNewTab/>
      <ExternalFooterMenuItem
        testId="product-InsideTrackerPro"
        link="https://info.insidetracker.com/professionals"
        label="InsideTracker for Professionals"
        inNewTab
      />
      <FooterMenuItem testId="company-in-the-press" link="/press-page/" label="In the press"/>
      <ExternalFooterMenuItem testId="company-security" link="https://info.insidetracker.com/security" label="Security" inNewTab/>
      <FooterMenuItem testId="company-privacy-policy" link="/policy-privacy/" label="Privacy Policy"/>
      <FooterMenuItem testId="company-dna-research-consent" link="/research-consent/" label="DNA Research Consent"/>
      <FooterMenuItem testId="company-disclosure-policy" link="/responsible-disclosure-policy/" label="Responsible Disclosure Policy"/>
      <FooterMenuItem testId="company-product-consent" link="/product-consent/" label="Product Consent"/>
      <FooterMenuItemHash testId="company-how-it-works" link="/tour/" label="How it works"/>
      <ExternalFooterMenuItem
        testId="company-hipaa"
        link="https://insidetracker-faq.gorgias.help/en-US/is-insidetracker-hipaa-compliant-288846"
        label="HIPAA"
        inNewTab
      />
      <ExternalFooterMenuItem
        testId="company-faq"
        link="https://insidetracker-faq.gorgias.help"
        label="FAQ"
        inNewTab
      />
    </FooterMenuList>
  );

  if (isNotAccountStates) {
    if (isActiveAccount) {
      if (path.indexOf('health-decoder') === -1
        || (path.indexOf('health-decoder/set-reminders') > -1 && rosetta.status !== RosettaStatus.COMPLETE)
      ) {
        accountColumn = (
          <FooterMenuList title="Account">
            <ExternalFooterMenuItem testId="account-dna" link="/customer/genetics-report/" label="DNA"/>
            <ExternalFooterMenuItem testId="account-profile" link="/customer/settings/" label="Profile"/>
            <ExternalFooterMenuItem testId="account-achievements" link="/customer/achievements/" label="Achievements"/>
            <ExternalFooterMenuItem testId="account-dashboard" link="/customer/summary/dashboard/" label="Dashboard"/>
            {!user.isDnaOnly
              ? (
                <>
                  <ExternalFooterMenuItem testId="account-bloodwork" link={routes.customer.myResults.groups} label="My results"/>
                  <ExternalFooterMenuItem testId="account-nutrition" link="/customer/nutrition/" label="Nutrition"/>
                </>
              )
              : <ExternalFooterMenuItem testId="account-blood-analysis" link="/customer/blood-analysis/" label="Blood analysis"/>}
            <ExternalFooterMenuItem
              link={configuration.shopifyUrl}
              label="Store"
              testId="store-account"
            />
          </FooterMenuList>
        );
      }
    }
  } else {
    productColumn = (
      <FooterMenuList title="Product">
        <ExternalFooterMenuItem
          link={configuration.shopifyUrl}
          label="Store"
          testId="store"
        />
        <ExternalFooterMenuItem
          testId="product-faq"
          link="https://insidetracker-faq.gorgias.help/"
          label="FAQ"
          inNewTab
        />
        <FooterMenuItem testId="product-tour" link="/tour/" label="Tour"/>
        <FooterMenuItem testId="product-science" link="/science/" label="Science"/>
        <ExternalFooterMenuItem testId="product-blog" link="https://www.insidetracker.com/a/articles/" label="Blog" inNewTab/>
        <ExternalFooterMenuItem testId="product-insideguide" link="https://blog.insidetracker.com/" label="InsideGuide" inNewTab/>
        <FooterMenuItem testId="product-testimonial" link="/testimonial/" label="Testimonials"/>
        <ExternalFooterMenuItem
          testId="product-InsideTrackerPro"
          link="https://info.insidetracker.com/professionals"
          label="InsideTracker for Professionals"
          inNewTab
        />
        <ExternalFooterMenuItem
          testId="product-partnerships"
          link="https://info.insidetracker.com/partnerships/"
          label="Affiliates and Creators"
          inNewTab
        />
      </FooterMenuList>
    );
  }

  if (isAdminPath) {
    accountColumn = (
      <FooterMenuList title="Account">
        <ExternalFooterMenuItem testId="account-profile" link="/admin/coach/profile" label="Profile"/>
      </FooterMenuList>
    );
  }

  return (
    <>
      {companyColumn}
      {accountColumn}
      {!isAdminPath && productColumn}
    </>
  );
};

export default FooterMenu;

import React from "react";
import { Link } from 'react-router-dom';

export default function Button({ buttonInfo, attributes }) {
  const {
    label,
    url,
    isInternal,
    pageName,
  } = buttonInfo;
  if (isInternal) {
    return <Link to={pageName} {...attributes}>{label}</Link>;
  }
  return <a href={url} {...attributes}>{label}</a>;
}

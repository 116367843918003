/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { RecommendationsState } from "./types";
import {
  fetchAvailableMarkersAbbr,
  fetchBloodRecommendations,
  fetchCategoryExtraInformation,
  fetchMarkerExtraInformation,
} from "./actions";

export const initialState: RecommendationsState = {
  markerRecommendations: null,
  markerExtraInformation: null,
  categoryExtraInformation: null,
  availableMarkersAbbr: null,
};

export const recommendationsSlice = createSlice({
  name: 'recommendations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBloodRecommendations.fulfilled, ((state, {
      payload: markerRecommendations,
    }) => {
      state.markerRecommendations = markerRecommendations;
    }));
    builder.addCase(fetchMarkerExtraInformation.fulfilled, ((state, {
      payload: markerExtraInformation,
    }) => {
      state.markerExtraInformation = markerExtraInformation;
    }));
    builder.addCase(fetchAvailableMarkersAbbr.fulfilled, (state, action) => {
      state.availableMarkersAbbr = action.payload.availableMarkersAbbr;
    });
    builder.addCase(fetchCategoryExtraInformation.fulfilled, ((state, {
      payload: categoryExtraInformation,
    }) => {
      state.categoryExtraInformation = categoryExtraInformation;
    }));
  },
});
export default recommendationsSlice.reducer;

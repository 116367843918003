import React from 'react';
import styles from './NavigationLink.module.less';
import { NavigationLinkPropsTypes } from './NavigationLink';

/**
 * Returns HTML Anchor element
 * @category Header
 * @subcategory Navigation Link
 * @borrows NavigationLinkPropsTypes as props
 * @component
 * @hideconstructor
 * @example
 * return <ContactsLinkHash link={"#!"} label="Mobile nav link"/>
 */
const ContactsLinkHash = (props: NavigationLinkPropsTypes) => {
  const { link, label } = props;
  return (
    <a href={link} className={`${styles.navlink}`}>
      {label}
    </a>
  );
};

export default ContactsLinkHash;

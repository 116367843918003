import { Builder, withChildren } from "@builder.io/react";

import themeContainer from "./themeContainer.jsx";

const themeContainerWithChildren = withChildren(themeContainer);

Builder.registerComponent(themeContainerWithChildren, {
    name: 'Theme Container',
    noWrap: true,
    defaultStyles: {
        maxWidth: "1400px",
        minWidth: "310px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        display: "block",
        paddingLeft: "16px",
        paddingRight: "16px"
    },
    defaultChildren: [
        {
            '@type' : '@builder.io/sdk:Element',
            component: {
                name: 'Theme Heading',
                options: {
                    text: 'Get started with InsideTracker',
                    type: 'h2',
                }
            }
        }
    ]
})
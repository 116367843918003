import { Builder } from "@builder.io/react";

import themeAccordion from "./themeAccordion";

Builder.registerComponent(themeAccordion, {
  name: "Theme Accordion",
  inputs: [
    {
      type: "list",
      name: "accordion",
      friendlyName: "Accordion",
      defaultValue: [
        {
          question: "Comprehensive insight into your body's data",
          answer: "<p>Track and analyze your health data over time in one place. InsideTracker is the only platform that combines data from blood, DNA, and fitness trackers.</p>",
        }
      ],
      subFields: [
        {
            name: "question",
            type: "string",
            required: true,
            friendlyName: "Question"
        },
        {
            name: "answer",
            type: "richText",
            required: true,
            friendlyName: "Answer"
        },
        {
          name: "icon",
          type: "file",
          friendlyName: "Icon",
          allowedFileTypes: ['jpg', 'jpeg', 'png', 'svg']
        },
        {
          name: "gifImage",
          type: "file",
          friendlyName: "Gif Image",
          allowedFileTypes: ['jpg', 'jpeg', 'png', 'gif']
        }
      ]
    }
  ]
})
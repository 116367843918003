import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from './types';
import {
  fetchAccountReferences,
  fetchAccountSecurity,
  fetchLogoutUser,
  fetchUserAddresses,
  fetchUserData,
  fetchUserProfile,
  fetchUserSettings,
  fetchInnerageBloodMarkers,
  fetchInnerageDailyActions,
  fetchInnerageResults,
  fetchBloodResultsUploadSubscriptionActions,
  fetchWomanHealth,
  fetchBloodResults,
  fetchBloodRanges,
  fetchCategoryResults, fetchNotificationTags,
} from './actions';
import CustomerAuthTokenProvider from '../../services/Customer/customerAuthTokenProvider';
import { MarkerSortingState } from '../../interfaces/Blood/MarkerSortingState';

export const initialUserAuthState: UserState = {
  user: null,
  address: null,
  profile: null,
  rosetta: null,
  references: null,
  security: null,
  innerageResults: null,
  innerageBloodMarkers: null,
  innerageDailyActions: null,
  subscriptions: {
    bloodResultUpload: null,
  },
  bloodRanges: null,
  bloodResults: null,
  categoryResults: null,
  categoriesForResults: null,
  biomarkersSortingState: MarkerSortingState.UNOPTIMIZED,
  womanHealth: null,
  showCompleteHealthIaPopup: false,
  notificationTags: null,
  showAIPopup: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserAuthState,
  reducers: {
    logout: () => initialUserAuthState,
    setSortingState: (state, { payload }: PayloadAction<MarkerSortingState>) => {
      state.biomarkersSortingState = payload;
    },
    setShowAiPopup: (state, { payload }: PayloadAction<boolean>) => {
      state.showAIPopup = payload;
    },
    purgeBloodResults: (state) => {
      state.bloodResults = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfile.fulfilled, ((state, { payload }) => {
      state.user = payload.customer;
      state.address = payload.address;
      state.profile = payload.settings;
      state.rosetta = payload.rosetta;
      state.showCompleteHealthIaPopup = !payload.settings.heightFeet || !payload.settings.weight;
    }));
    builder.addCase(fetchUserProfile.rejected, () => {
      CustomerAuthTokenProvider.remove();
      return initialUserAuthState;
    });
    builder.addCase(fetchLogoutUser.pending, () => {
      localStorage.removeItem('mobile-app-splash-shown');
      CustomerAuthTokenProvider.remove();
      return initialUserAuthState;
    });
    builder.addCase(fetchUserData.fulfilled, ((state, { payload }) => {
      state.user = payload;
    }));
    builder.addCase(fetchUserAddresses.fulfilled, ((state, { payload }) => {
      state.address = payload;
    }));
    builder.addCase(fetchUserSettings.fulfilled, ((state, { payload }) => {
      state.profile = payload;
      state.showCompleteHealthIaPopup = !payload.heightFeet || !payload.weight;
    }));
    builder.addCase(fetchAccountReferences.fulfilled, ((state, { payload }) => {
      state.references = payload;
    }));
    builder.addCase(fetchAccountSecurity.fulfilled, ((state, { payload }) => {
      state.security = payload;
    }));
    builder.addCase(fetchInnerageResults.fulfilled, ((state, { payload }) => {
      state.innerageResults = payload;
    }));
    builder.addCase(fetchInnerageBloodMarkers.fulfilled, ((state, { payload }) => {
      state.innerageBloodMarkers = payload;
    }));
    builder.addCase(fetchInnerageBloodMarkers.rejected, ((state) => {
      if (!state.profile.heightFeet || !state.profile.weight) {
        state.showCompleteHealthIaPopup = true;
      }
    }));
    builder.addCase(fetchInnerageDailyActions.fulfilled, ((state, { payload }) => {
      state.innerageDailyActions = payload;
    }));
    builder.addCase(fetchBloodResults.fulfilled, ((state, { payload }) => {
      state.bloodResults = payload;
    }));
    builder.addCase(fetchBloodRanges.fulfilled, ((state, { payload }) => {
      state.bloodRanges = payload;
    }));
    builder.addCase(fetchBloodResultsUploadSubscriptionActions.fulfilled, ((state, { payload }) => {
      state.subscriptions.bloodResultUpload = payload;
    }));
    builder.addCase(fetchWomanHealth.fulfilled, ((state, { payload }) => {
      state.womanHealth = payload;
    }));
    builder.addCase(fetchCategoryResults.fulfilled, ((state, { payload }) => {
      // eslint-disable-next-line prefer-destructuring
      state.categoryResults = payload[0];
      // eslint-disable-next-line prefer-destructuring
      state.categoriesForResults = payload[1];
    }));
    builder.addCase(fetchNotificationTags.fulfilled, ((state, { payload }) => {
      state.notificationTags = payload;
    }));
  },
});

export default userSlice.reducer;

export const { setSortingState, setShowAiPopup, purgeBloodResults } = userSlice.actions;

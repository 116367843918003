import React from 'react';
import Icons from './Icons';
import { IconName } from './types';
import styles from './Icon.module.less';
import CachedLazy from '../CachedLazy/CachedLazy';

/**
 * Icon wrapper props types definitions
 */
type IconPropsTypes = {
  /**
   *  Must be in spriteName_iconName format,
   *   where spriteName is a key of Icons object
   *   and iconName is a key of nested objects
   */
  iconName: IconName,
  /**
   * Additional classes
   */
  className?: string,
  onClick?: (e: React.MouseEvent) => void
};

/**
 * Icon wrapper component
 * @component
 * @category Components
 * @subcategory Content
 * @borrows IconPropsTypes as props
 * @hideconstructor
 * @example
 * return <div style={{width: "140px"}}><Icon iconName="general_itLogoInlineGray" /></div>;
 */
const Icon = React.memo((props: IconPropsTypes) => {
  const {
    iconName, className,
  } = props;
  const spriteName = iconName.split('_')[0];
  const icon = iconName.split('_')[1];

  try {
    const fakeIconModule = Icons[spriteName]()
      .then((module) => module.default)
      .then((icons) => ({
        // eslint-disable-next-line react/no-unstable-nested-components
        default: () => {
          try {
            return icons[icon](className);
          } catch {
            // eslint-disable-next-line no-console
            console.error(`failed to load icon: ${spriteName}/${iconName}`);
            return <span className={className}>X</span>;
          }
        },
      }));

    const LazyIcon = CachedLazy(() => fakeIconModule, <svg className={className}>.</svg>);

    return <LazyIcon/>;
  } catch {
    // eslint-disable-next-line no-console
    console.error(`failed to load icon: ${spriteName}/${iconName}`);
    return <svg className={className}>X</svg>;
  }
});

const IconWrapper = ({ onClick, ...props }: IconPropsTypes) => {
  if (onClick) {
    return (
      <button
        onClick={onClick}
        className={styles.button}
        type="button"
      >
        <Icon {...props} />
      </button>
    );
  }

  return <Icon {...props}/>;
};

export default IconWrapper;

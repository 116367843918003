import React from 'react';
import styles from '../Footer.module.less';

const nowDate = new Date();
// eslint-disable-next-line import/prefer-default-export
export const FooterCopyright = () => (
  <p className={styles.copyright}>
    © 2009-
    {nowDate.getFullYear()}
    {' '}
    Segterra, Inc.
    All rights reserved. InsideTracker is a personalized nutrition model by Segterra.
    <br/>
    Technology protected by U.S. Patent 8762167 and other patent pending applications.
  </p>
);

import React from 'react';
// Styles
import styles from '../../pages/customer/Authentication/Authentication.module.less';
// Components
import Popup from './Popup';
import Button, { ButtonLink } from '../Buttons/Button';

const TimeoutSessionPopup = (props: { opened: boolean, secondsToExpire: number, onContinue: () => void }) => {
  const {
    opened,
    secondsToExpire,
    onContinue,
  } = props;

  return (
    <Popup
      onClose={onContinue}
      open={opened}
      showCloseBlock
      popupClasses={styles.sessionPopup}
      popupCloseBlockClasses={styles.mobileCloseBlock}
    >
      <>
        <h2 className={`${styles.heading20} ${styles.popupTitle}`}>
          Your session is about to expire
        </h2>
        <p className={`${styles.text14} ${styles.popupText}`}>
          You will be logged out in
          {` ${secondsToExpire} `}
          seconds. Do you want to continue your session?
        </p>
        <div className={`${styles.bottomButtonsRight}`}>
          <ButtonLink
            id="log-out"
            className={styles.btnSecondary}
            href="/customer/login/logout"
            text="Log out"
            type="submit"
            external
            secondary
            target="_self"
          />
          <Button
            id="continue-session"
            green
            className={styles.secondBtn}
            ml={5}
            text="Continue session"
            onClick={onContinue}
          />
        </div>
      </>
    </Popup>
  );
};

export default TimeoutSessionPopup;

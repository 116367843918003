import { createSlice } from '@reduxjs/toolkit';
import { UserDna } from './types';
import { fetchUserDnaReport, fetchDnaKit } from './actions';

export const initialUserDnaState: UserDna = {
  dnaReport: null,
  dnaKit: null,
};

const userDnaSlice = createSlice({
  name: 'userDna',
  initialState: initialUserDnaState,
  reducers: {
    clean: () => initialUserDnaState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserDnaReport.fulfilled, ((state, { payload }) => {
      state.dnaReport = payload.dnaReport;
    }));

    builder.addCase(fetchDnaKit.fulfilled, ((state, { payload }) => {
      state.dnaKit = payload;
    }));
  },
});

export default userDnaSlice.reducer;

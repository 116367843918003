import { FormikValues } from 'formik';
import baseAxios from './baseAxios';

export default {
  getCareerList: () => baseAxios.get('/api/pages/careers/list'),
  getCareer: (jobId: number) => baseAxios.get(`/api/pages/careers/job/${jobId}`).then((response) => response.data),
  getInnerAgeEBook: (data: FormikValues) => baseAxios.post('/api/pages/PromoForm/innerAge', data),
  getTourEBook: (data: FormikValues) => baseAxios.post('/api/pages/PromoForm/tour', data),
  getPress: () => baseAxios.get('/api/pages/press/published'),
  getPressReleases: () => baseAxios.get('/api/pages/press/releases'),
  getInnerAgeData: () => baseAxios.get('/api/pages/innerage')
    .then((responseData) => responseData.data),

  getEnduranceEBook: (data: { firstName: string, lastName: string, email: string, training: string }) => baseAxios.post('/api/pages/publicPage/getEnduranceEbook', data),
  getFitnessEBook: (data: { firstName: string, lastName: string, email: string, goal: string }) => baseAxios.post('/api/pages/publicPage/getFitnessEbook', data),

  postMilitaryProSports: (data: {
    firstname: string,
    lastname: string,
    email: string,
    team_name: string,
    fromPage: string
  }) => baseAxios.post('/api/pages/publicPage/getMoreInfoAboutMilitary', data),
  getLiveDemo: (data: FormikValues) => baseAxios.post(
    '/api/pages/publicPage/getDemo',
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  getHomeEBook: (data: FormikValues) => baseAxios.post('/api/pages/publicPage/downloadEBook', data),
};

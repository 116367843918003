import userAuthorizedAxios from './userAuthorizedAxios';
import { Diet } from '../../interfaces/Profile/Nutrition/Diet';
import { FoodFrequencyValue } from '../../interfaces/Profile/Nutrition/FoodFrequencyValue';
import { Food } from '../../interfaces/Profile/Nutrition/Food';
import {
  SupplementsTake,
  SupplementsTakePost,
} from '../../interfaces/Profile/Supplements/SupplementsTake';
import { Supplement } from '../../interfaces/Profile/Supplements/Supplement';
import { Dosage } from '../../interfaces/Profile/Supplements/Dosage';
import { SportCategoriesDto, SportCategory } from '../../interfaces/Profile/Exercise/SportCategory';
import { Sport, SportsDto } from '../../interfaces/Profile/Exercise/Sport';
import {
  ExerciseDto,
  ExercisesDto,
  ExercisesPostResponse,
} from '../../interfaces/Profile/Exercise/Exercise';
import { LifestyleValues } from '../../interfaces/Profile/Lifestyle/LifestyleValues';
import { LifestyleReferences } from '../../interfaces/Profile/Lifestyle/LifestyleReferences';

export default {
  getDiets: (): Promise<{
    reference: Diet[],
    values: string[],
  }> => userAuthorizedAxios
    .get('/api/pages/nutrition/diets')
    .then((response) => response.data.data),
  getFoods: (): Promise<{
    reference: Food[],
    values: FoodFrequencyValue[],
  }> => userAuthorizedAxios
    .get('/api/pages/nutrition/foods')
    .then((response) => response.data.data),
  getBeverages: (): Promise<{
    reference: Food[],
    values: FoodFrequencyValue[],
  }> => userAuthorizedAxios
    .get('/api/pages/nutrition/beverages')
    .then((response) => response.data.data),
  getWillingToTake: (): Promise<SupplementsTake> => userAuthorizedAxios.get('/api/pages/supplement/dosage')
    .then((responseData) => responseData.data),
  getSupplements: (): Promise<Supplement[]> => userAuthorizedAxios.get('/api/pages/supplement/supplements')
    .then((responseData) => responseData.data.data),
  getLifestyle: (): Promise<LifestyleValues> => userAuthorizedAxios.get('/api/pages/lifestyle')
    .then((responseData) => responseData.data),
  getLifestyleReference: (): Promise<LifestyleReferences> => userAuthorizedAxios
    .get('/api/pages/lifestyle/lifestyleReferences')
    .then((response) => response.data),
  saveSelectedDiets: (diets: string[]) => userAuthorizedAxios.post('/api/pages/nutrition/diets-post', {
    diets,
  }),
  saveSelectedFood: (frequencies: FoodFrequencyValue[]) => userAuthorizedAxios.post('/api/pages/nutrition/foods-post', {
    frequencies,
  }),
  saveSelectedBeverages: (frequencies: FoodFrequencyValue[]) => userAuthorizedAxios.post('/api/pages/nutrition/beverages-post', {
    frequencies,
  }),
  saveWillingToTake: (
    value: string,
  ): Promise<string> => userAuthorizedAxios
    .post('/api/pages/supplement/willing-to-take-post', { value })
    .then((response) => response.data),
  saveNsfOnly: (
    value: boolean,
  ): Promise<string> => userAuthorizedAxios
    .post('/api/pages/supplement/nsf-post', { value })
    .then((response) => response.data),
  saveSupplementsTake: (supplementsTake: SupplementsTakePost) => userAuthorizedAxios
    .post('/api/pages/supplement/supplements-post', supplementsTake)
    .then((responseData) => responseData.data),
  saveSupplementDosage: (dosages: Dosage[]) => userAuthorizedAxios
    .post('/api/pages/supplement/dosage-post', { supplements: dosages })
    .then((responseData) => responseData.data),
  getSportCategories: (): Promise<SportCategory[]> => userAuthorizedAxios
    .get<SportCategoriesDto>('/api/pages/exercise/sport-categories')
    .then((response) => response.data.categories),
  getSports: (): Promise<Sport[]> => userAuthorizedAxios
    .get<SportsDto>('/api/pages/exercise/sports')
    .then((response) => response.data.sports),
  getExercises: (): Promise<ExerciseDto[]> => userAuthorizedAxios
    .get<ExercisesDto>('/api/pages/exercise/exercises')
    .then((response) => response.data.values),
  saveExercises: (exercises: ExercisesDto): Promise<ExercisesPostResponse> => userAuthorizedAxios
    .post<ExercisesPostResponse>('/api/pages/exercise/exercises-post', exercises)
    .then((response) => response.data),
  saveSmokingStatus: (
    value: string,
  ): Promise<string> => userAuthorizedAxios
    .post('/api/pages/lifestyle/smoking-status-post', { value }),
  saveSunExposure: (
    value: string,
  ): Promise<string> => userAuthorizedAxios
    .post('/api/pages/lifestyle/daily-sun-exposure-post', { value }),
  saveWeightGoal: (
    value: string,
  ): Promise<string> => userAuthorizedAxios
    .post('/api/pages/lifestyle/weight-goal-post', { value }),
  saveRhrValue: (
    value: number,
  ): Promise<number> => userAuthorizedAxios
    .post('/api/pages/lifestyle/rhr-post', { value }),
  saveBodyFatValue: (
    value: number,
  ): Promise<number> => userAuthorizedAxios
    .post('/api/pages/lifestyle/body-fat-percent-post', { value }),
  saveWakeUpTime: (
    value: string,
  ): Promise<string> => userAuthorizedAxios
    .post('/api/pages/lifestyle/wake-up-time-post', { value }),
  saveSleepDuration: (
    value: number,
  ): Promise<number> => userAuthorizedAxios
    .post('/api/pages/lifestyle/sleep-duration-post', { value }),
};

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { dashUnderlineToCamelCase } from '../../helpers/dash2camel';

export const adminSelector = ({ admin }: RootState) => admin.admin;
export const loggedInAdminSelector = ({ admin }: RootState) => admin.isLoggedIn;
export const adminRoleAccessSelector = ({ admin }: RootState) => admin.admin?.roleAccesses;

export const adminRoleAccessRegexpsSelector = createSelector(adminRoleAccessSelector, (roleAccesses) => roleAccesses?.map((roleAccess) => {
  if (roleAccess) {
    let link = '';
    const { action, controller, area } = roleAccess;

    if (area && controller && action) {
      link = `^/${area}(/${controller}(/${action}(/.*)?)?)?$`;
    } else if (area && controller) {
      link = `^/${area}(/${controller}(/.*)?)?$`;
    } else if (area) {
      link = `^/${area}(/.*)?$`;
    } else {
      link = '(.*)';
    }

    return new RegExp(dashUnderlineToCamelCase(link));
  }
  return null;
})
  ?.filter(Boolean));

export default {
  adminSelector,
  loggedInAdminSelector,
};

import { Builder } from "@builder.io/react";

import themeHeading from "./themeHeading";

Builder.registerComponent(themeHeading, {
  name: "Theme Heading",
  inputs: [
    {
      type: "longText",
      name: "text",
      friendlyName: "Heading Text",
      defaultValue: "Get started with InsideTracker",
      required: true,
    },
    {
      type: "string",
      name: "type",
      required: true,
      friendlyName: "Please Select the Heading Tag",
      enum: [
        {
          label: "Heading 1",
          value: "h1",
        },
        {
          label: "Heading 2",
          value: "h2",
        },
        {
          label: "Heading 1",
          value: "h1",
        },
        {
          label: "Heading 3",
          value: "h3",
        },
        {
          label: "Heading 4",
          value: "h4",
        },
        {
          label: "Heading 5",
          value: "h5",
        },
        {
          label: "Heading 6",
          value: "h6",
        },
      ],
      defaultValue: "h1",
    },
  ],
  noWrap: true,
  defaultStyles: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "700",
    color: "#232D37",
    marginTop: "0px",
    marginRight: "0px",
    marginBottom: "0px",
    marginLeft: "0px",
  },
  defaults: {
    responsiveStyles: {
      large: {
        fontSize: "56px",
        lineHeight: "60px",
      },
      medium: {
        fontSize: "50px",
        lineHeight: "62px",
      },
      small: {
        fontSize: "30px",
        lineHeight: "32px",
      }
    }
  }
});

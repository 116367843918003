export interface SportCategory {
  id: number;
  name: string;
  icon: string;
  step: string;
  sort: number;
}

export interface SportCategoriesDto {
  categories: SportCategory[]
}

export const ENDURANCE_ID = 1;
export const RESISTANCE_ID = 2;
export const FLEXIBILITY_ID = 3;
export const SPORTS_ID = 4;
export const NO_EXERCISE_ID = 5;

export type ExerciseId = 1 | 2 | 3 | 4;

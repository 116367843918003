import React from 'react';

/* Styles */
import styles from './MobileMenu.module.less';

/* Components */
import MobileNavigationlink from '../Navigation/NavigationLink/MobileNavigationLink';

/**
 * Mobile menu props types definitions
 */
type MobileMenuPropsTypes = {
  page: 'science' | 'testimonials' | 'about' | 'team' | 'innerage'
};

/**
 * Mobile menu with links
 * @category Header
 * @subcategory Mobile menu
 * @borrows MobileMenuPropsTypes as props
 * @component
 * @hideconstructor
 * @example
 * return <MobileMenu page="science" />
 */
const MobileMenu = (props: MobileMenuPropsTypes) => {
  switch (props.page) {
    case 'about':
      return (
        <nav className={styles.mobileMenu}>
          <MobileNavigationlink link="#who-section" label="Who we are"/>
          <MobileNavigationlink link="#do-section" label="What we do"/>
          <MobileNavigationlink link="#how-section" label={"How we're different"}/>
          <MobileNavigationlink link="#timeline-section" label="Company timeline"/>
        </nav>
      );
    case 'science':
      return (
        <nav className={styles.mobileMenu}>
          <MobileNavigationlink link="#leading" label="Leading with science"/>
          <MobileNavigationlink link="#scientific" label="Scientific approach"/>
          <MobileNavigationlink link="#our-technology" label="Our technology"/>
        </nav>
      );
    case 'innerage':
      return (
        <nav className={styles.mobileMenu}>
          <MobileNavigationlink link="#what-innerage" label="InnerAge 2.0"/>
          <MobileNavigationlink link="#how-work" label="How it works"/>
          <MobileNavigationlink link="#what-new" label="What's new"/>
          <MobileNavigationlink link="#what-get" label="What you'll get"/>
          <MobileNavigationlink link="#success-stories" label="What people are saying"/>
        </nav>
      );
    case 'team':
      return (
        <nav className={styles.mobileMenu}>
          <MobileNavigationlink
            link="#scientific-advisory-board"
            label="Scientific Advisory Board"
          />
          <MobileNavigationlink link="#executive-team" label="Executive Team"/>
          <MobileNavigationlink link="#board-members" label="Board Members"/>
          <MobileNavigationlink link="#strategic-advisors" label="Strategic Advisors"/>
        </nav>
      );
    case 'testimonials':
      return (
        <nav className={styles.mobileMenu}>
          <MobileNavigationlink link="#overall-health" label="Wellness"/>
          <MobileNavigationlink link="#endurance" label="Endurance"/>
          <MobileNavigationlink link="#strength-and-fitness" label="Strength & fitness"/>
          <MobileNavigationlink link="#healthy-aging" label="Healthy aging"/>
        </nav>
      );
    default:
      return null;
  }
};

export default MobileMenu;

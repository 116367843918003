import { CoachState, Order, Team } from './types';
import config from '../../config';

export const undoTeamDeletionFunction = (state: CoachState) => {
  const {
    teams, markedToDeleteTeamId, markedToArchiveUsersIds, users,
  } = state;
  const team = teams.find((team: Team) => team.id === markedToDeleteTeamId);
  state.teams = [
    ...teams.filter((team: Team) => team.id !== markedToDeleteTeamId),
    {
      ...team,
      deleted: false,
    },
  ];
  const usersToRevertArchived = markedToArchiveUsersIds.map((id) => {
    const user = users.find((user) => user.id === id);
    return {
      ...user,
      status: user.initialStatus,
      hidden: false,
      teamIds: user.teamIds.concat(`${markedToDeleteTeamId}`),
    };
  });
  const usersToRevertArchivedIds = usersToRevertArchived.map((user) => user.id);
  state.users = [
    ...users.filter((user) => !usersToRevertArchivedIds.includes(user.id)),
    ...usersToRevertArchived,
  ];
  state.selectedTeam = team;
  state.markedToArchiveUsersIds = null;
  state.markedToDeleteTeamId = null;
};

export const orderWithImagePath = (order: Order) => {
  const baseUrl = `${config.apiHostName}/skin/img/coach/coach_dashboard/plans/`;
  let imagePath: string;
  switch (+order.package.id) {
    case 38:
      imagePath = `${baseUrl}DNAKit.png`;
      break;
    case 37:
      imagePath = `${baseUrl}DNA.png`;
      break;
    case 15:
    case 16:
    case 41:
      imagePath = `${baseUrl}HomeKit.png`;
      break;
    case 10:
    case 21:
      imagePath = `${baseUrl}DIY.png`;
      break;
    default:
      imagePath = `${baseUrl}default.png`;
      break;
  }

  return {
    ...order,
    imagePath,
  };
};

export const orderWithStatus = (order: Order) => {
  let status: string;
  if (order.isAvailable) status = 'Available';
  if (!order.isAvailable && order.usedOrderData?.resultReceived) status = 'Used';
  if (!order.isAvailable && !order.usedOrderData?.resultReceived) status = 'Assigned';

  return {
    ...order,
    status,
  };
};

export default {
  undoTeamDeletionFunction,
  orderWithImagePath,
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AvailablePopups, CoachState, Order, Team, UserInTeam,
} from './types';
import {
  editTeam, fetchAddUserToTeam,
  fetchCoachInitialData, fetchDeleteTeam,
  fetchDeleteTeamLogo, fetchOrders,
  fetchRequestPermissions,
  fetchTeams,
  fetchUploadTeamLogo,
  fetchUsers,
} from './actions';
import { orderWithImagePath, orderWithStatus, undoTeamDeletionFunction } from './helpers';

const initialReferenceState: CoachState = {
  profile: null,
  selectedUserIds: [],
  users: [],
  selectedTeam: null,
  teams: [],
  orders: [],
  popup: null,
  markedToDeleteTeamId: null,
  markedToArchiveUsersIds: null,
  availablePlans: null,
  searchString: null,
  selectedOrder: null,
  showSearch: false,
};

const archivedTeam: Team = {
  id: 0,
  branchId: 0,
  deleted: false,
  imagePath: '',
  nutritionCategoryId: null,
  title: 'Archived users',
};

export const coachSlice = createSlice({
  name: 'coach',
  initialState: initialReferenceState,
  reducers: {
    setSelectedUsersIds: (state, action: PayloadAction<number[]>) => {
      state.selectedUserIds = action.payload;
    },
    setSelectedOrder: ((state, action: PayloadAction<Order>) => {
      state.selectedOrder = action.payload;
    }),
    dropSelectedOrder: ((state) => {
      state.selectedOrder = null;
    }),
    setUsers: (state, action) => {
      state.users = action.payload.users;
    },
    setTeams: ((state, action: PayloadAction<Team[]>) => {
      state.teams = action.payload;
    }),
    setSelectedTeam: (state, action: PayloadAction<Team>) => {
      state.selectedTeam = action.payload;
    },
    setActivePopup: (state, action: PayloadAction<AvailablePopups>) => {
      state.popup = action.payload;
    },
    closePopup: (state) => {
      state.popup = null;
      state.selectedUserIds = [];
    },
    prepareToDeleteTeam: ((state) => {
      const { teams, selectedTeam, users } = state;
      state.selectedTeam = archivedTeam;
      state.teams = [
        ...teams.filter((team: Team) => team.id !== selectedTeam.id),
        {
          ...selectedTeam,
          deleted: true,
        },
      ];

      const isInvitedToTeam = (user: UserInTeam, teamId: number) => {
        const teamStatus = user.teams
          .filter((item) => item.teamId === teamId)[0] || null;

        return teamStatus && teamStatus.status === 'invited';
      };

      const markedToArchiveUsers = users
        .filter((user) => user.teamIds.includes(`${selectedTeam.id}`))
        .map((user) => {
          const teamIds = user.teamIds.filter((id) => id !== `${selectedTeam.id}`);
          return {
            ...user,
            teamIds,
            status: !teamIds.length ? 'Archived' : user.status,
            hidden: isInvitedToTeam(user, selectedTeam.id),
          };
        });
      const markedToArchiveUsersIds = markedToArchiveUsers.map((user) => user.id);
      state.users = [
        ...users.filter((user) => !markedToArchiveUsersIds.includes(user.id)),
        ...markedToArchiveUsers,
      ];
      state.markedToArchiveUsersIds = markedToArchiveUsersIds;
      state.markedToDeleteTeamId = selectedTeam.id;
      state.popup = null;
    }),
    undoDeleteTeam: undoTeamDeletionFunction,
    setSearchString: ((state, { payload }) => {
      state.searchString = payload;
    }),
    setShowSearch: ((state, { payload }) => {
      state.showSearch = payload;
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(fetchTeams.fulfilled, ((state, action) => {
      state.teams = [...action.payload, archivedTeam];
      state.selectedTeam = action.payload ? action.payload[action.payload.length - 1] : null;
    }));
    builder.addCase(fetchCoachInitialData.fulfilled, (state, {
      payload: {
        profile,
        users,
        teams,
        availablePlans,
        orders,
      },
    }) => {
      state.users = users;
      state.teams = [...teams, archivedTeam];
      state.orders = orders.map(orderWithImagePath)
        .map(orderWithStatus);
      state.selectedTeam = teams ? teams[0] : null;
      state.availablePlans = availablePlans;
      state.profile = profile;
    });
    builder.addCase(fetchCoachInitialData.rejected, (_, action) => {
      window.message(action?.error?.message ?? 'Error on loading coach dashboard initial data', 'error');
      document.location.href = '/admin/login';
    });
    builder.addCase(fetchUploadTeamLogo.fulfilled, ((state, action) => {
      state.popup = null;
      state.teams = [
        ...state.teams.filter((team: Team) => team.id !== action.payload),
        {
          ...state.teams.find((team: Team) => team.id === action.payload),
          imagePath: `${action.payload}.jpg`,
        },
      ];
    }));
    builder.addCase(fetchOrders.fulfilled, ((state, action) => {
      state.orders = action.payload.map(orderWithImagePath)
        .map(orderWithStatus);
    }));
    builder.addCase(fetchDeleteTeamLogo.fulfilled, ((state, action) => {
      const team: Team = {
        ...state.teams.find((team: Team) => team.id === action.payload),
        imagePath: null,
      };
      state.teams = [
        ...state.teams.filter((team: Team) => team.id !== action.payload),
        team,
      ];
      state.selectedTeam = team;
    }));
    builder.addCase(editTeam.fulfilled, ((state, action) => {
      const { team } = action.payload;
      state.teams = [
        ...state.teams.filter((item) => item.id !== team.id),
        team,
      ];
      state.selectedTeam = team;
      state.popup = null;
    }));
    builder.addCase(editTeam.rejected, (_, action) => {
      window.message(action?.error?.message ?? 'Error on editing team', 'error');
    });
    builder.addCase(fetchUploadTeamLogo.rejected, (_, action) => {
      window.message(action?.error?.message ?? 'Error on uploading team logo', 'error');
    });
    builder.addCase(fetchRequestPermissions.fulfilled, (state, action) => {
      window.message(action.payload.data.message);
    });
    builder.addCase(fetchRequestPermissions.rejected, (_, action) => {
      window.message(action?.error?.message ?? 'Unable to send permission request', 'error');
    });
    builder.addCase(fetchDeleteTeam.fulfilled, (state) => {
      state.markedToArchiveUsersIds = null;
      state.markedToDeleteTeamId = null;
    });
    builder.addCase(fetchDeleteTeam.rejected, (state, action) => {
      window.message(action?.error?.message ?? 'Error on team deletion, changes reverted', 'error');
      undoTeamDeletionFunction(state);
    });
    builder.addCase(fetchAddUserToTeam.rejected, (state, action) => {
      window.message(action?.error?.message ?? 'Error on moving user from archive', 'error');
    });
  },
});

export const {
  setActivePopup,
  closePopup,
  setSelectedTeam,
  setSelectedUsersIds,
  setTeams,
  setUsers,
  prepareToDeleteTeam,
  undoDeleteTeam,
  setShowSearch,
  setSearchString,
  setSelectedOrder,
  dropSelectedOrder,
} = coachSlice.actions;

export default coachSlice.reducer;

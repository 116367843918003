import React from "react";

import styles from "./themeStepCard.module.less";

export default function StepCard({
  image,
  title,
  info,
  step,
  showRepeatSteps,
}) {
  return (
    <div className={`${styles.themeStepCard}`}>
      {
        showRepeatSteps
        && (
        <div className={`${styles.themeStepCardTag}`}>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.7595 6.26543C6.02909 4.87529 7.74937 3.95722 9.62973 3.66628C11.5102 3.37532 13.4355 3.72951 15.0803 4.66875L13.5255 5.17727H13.5253C13.163 5.29533 12.8923 5.594 12.8156 5.96063C12.7387 6.32726 12.8675 6.70615 13.1529 6.95454C13.4386 7.2031 13.8378 7.28333 14.2004 7.16527L17.4038 6.11898C17.6169 6.04927 17.8023 5.91561 17.9335 5.73677C18.0647 5.55792 18.1348 5.34299 18.1341 5.12282V1.9838C18.1341 1.60999 17.9307 1.26456 17.6003 1.07765C17.2699 0.890743 16.8627 0.890743 16.5324 1.07765C16.202 1.26456 15.9986 1.60998 15.9986 1.9838V2.77487C13.821 1.58075 11.278 1.19326 8.83325 1.68339C6.38843 2.17353 4.20511 3.50829 2.68071 5.44444C1.15671 7.38058 0.393731 9.78916 0.531024 12.2306C0.54604 12.4979 0.6651 12.7496 0.863886 12.9335C1.06249 13.1174 1.32546 13.2197 1.59881 13.2194C1.61884 13.2204 1.63868 13.2204 1.6587 13.2194C1.94133 13.2042 2.20609 13.0798 2.39523 12.8734C2.58419 12.6669 2.68179 12.3956 2.66678 12.1186C2.60296 11.0557 2.75509 9.99123 3.11441 8.98643C3.47373 7.98167 4.03289 7.05678 4.75958 6.26534L4.7595 6.26543Z" fill="#F8F8F8"/>
            <path d="M20.9425 9.72794C20.7298 9.54157 20.4488 9.44838 20.1644 9.47028C19.8821 9.48569 19.6174 9.61023 19.4286 9.81658C19.2398 10.0229 19.1424 10.2942 19.1574 10.571C19.2679 12.5278 18.6509 14.4574 17.4207 16.0023C16.1903 17.5474 14.4302 18.603 12.4665 18.9738C10.5027 19.3445 8.46828 19.0052 6.74037 18.0186L8.29412 17.5101C8.65645 17.3919 8.92691 17.093 9.0036 16.7264C9.08029 16.3596 8.95141 15.9807 8.66558 15.7323C8.37992 15.484 7.98057 15.4039 7.61823 15.5221L4.41492 16.5684C4.20203 16.6385 4.01701 16.7723 3.88633 16.9511C3.75566 17.13 3.68577 17.3446 3.68666 17.5646V20.7035C3.68666 21.0773 3.89009 21.4228 4.22042 21.6097C4.55095 21.7966 4.95796 21.7966 5.28832 21.6097C5.61867 21.4228 5.82208 21.0773 5.82208 20.7035V19.9072C7.36905 20.762 9.11494 21.2115 10.8911 21.2121C11.0854 21.2121 11.2797 21.2121 11.4751 21.1963C14.2297 21.047 16.8113 19.8317 18.6514 17.8179C20.4921 15.804 21.4411 13.1565 21.2898 10.457C21.2805 10.1776 21.155 9.91417 20.9425 9.72794Z" fill="#F8F8F8"/>
          </svg>
          Repeat steps
        </div>
        )
      }
      <div className={`${styles.themeStepCardMedia}`}>
        {
          image
          && <img src={image} alt={title} />
        }
      </div>
      <div className={`${styles.themeStepCardInfo}`}>
        <div className={`${styles.themeStepCardStep}`}>{step}</div>
        <h3 className={`${styles.themeStepCardTitle}`}>{title}</h3>
        <p className={`${styles.themeStepCardInfoText}`}>{info}</p>
      </div>
    </div>
  );
}

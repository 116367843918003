import { Builder } from "@builder.io/react";

import themeTeamCard from "./themeTeamCard";

Builder.registerComponent(themeTeamCard, {
  name: 'Theme Team Card',
  inputs: [
    {
      name: "image",
      friendlyName: "Image",
      type: "file",
      allowedFileTypes: ["jpeg", "jpg", "png"],
      defaultValue: "https://placehold.co/330x330",
      required: true
    },
    {
      name: "name",
      friendlyName: "Name",
      type: "string",
      defaultValue: "Gill Blander, PhD",
      required: true
    },
    {
      name: "titleBgColor",
      friendlyName: "Title Background Color",
      type: "color",
      defaultValue: "#EBF5F0"
    },
    {
      name: "titleColor",
      friendlyName: "Title Color",
      type: "color",
      defaultValue: "#005550"
    },
    {
      name: "title",
      friendlyName: "Title",
      type: "longText",
      defaultValue: "Founder, Chief Scientific Officer"
    },
    {
      name: "button",
      friendlyName: "Button",
      type: "string",
      defaultValue: "Learn More"
    },
    {
      name: "buttonLink",
      friendlyName: "Button Link",
      type: "url"
    },
    {
      name: "buttonColor",
      friendlyName: "Button Color",
      type: "color",
      defaultValue: "#00A050"
    },
    {
      name: "contentColor",
      friendlyName: "Content Color",
      type: "color",
      defaultValue: "#005550"
    },
  ]
})
import config from '../../config';

export default class AdminAuthTokenProvider {
  static token: string = '';

  static tokenExpires: number = -1;

  static get(): string | null {
    if (config.isWebpackDevServer) {
      return localStorage.getItem('adminJwtToken');
    }
    return this.token;
  }

  static update(token: string, expiresIn: number): void {
    const expiresAt = Date.now() + expiresIn * 1000;

    if (config.isWebpackDevServer) {
      localStorage.setItem('adminJwtToken', token);
      localStorage.setItem('adminJwtTokenExpires', `${expiresAt}`);
    } else {
      this.token = token;
      this.tokenExpires = expiresAt;
    }
  }

  static remove(): void {
    if (config.isWebpackDevServer) {
      localStorage.removeItem('adminJwtToken');
      localStorage.removeItem('adminJwtTokenExpires');
    } else {
      this.token = '';
      this.tokenExpires = -1;
    }
  }

  static getSecondsToExpiration(): number {
    let expiresAt;
    if (config.isWebpackDevServer) {
      expiresAt = localStorage.getItem('adminJwtTokenExpires');
    } else {
      expiresAt = this.tokenExpires;
    }

    if (!expiresAt) return -1;
    return (+expiresAt - Date.now()) / 1000;
  }

  static isExpired(): boolean {
    const token = AdminAuthTokenProvider.get();
    if (!token) {
      return true;
    }

    // check token.expired
    // using this in the future we can show expiration popup without making request to backend in SessionPopupWidget.tx

    return true;
  }
}

import React from 'react';
import Button, { ButtonPropsTypes } from '../Buttons/Button';

/**
 * ObfuscatedEmail props types definitions
 */
type ObfuscatedEmailPropsTypes = {
  /**
   * children
   */
  children: string,
  /**
   * Additional classes
   */
  className?: string,
  /**
   * Email name
   */
  emailName: string,
  /**
   * Email domain
   */
  emailDomain: string,
  /**
   * Email subject
   */
  subject?: string,
  /**
   * Email body
   */
  body?: string,
  /**
   * Is it button
   */
  button?: boolean,
  testId?: string,
};

/**
 * ObfuscatedEmail component
 * @component
 * @category Components
 * @subcategory Content
 * @borrows ObfuscatedEmailPropsTypes as props
 * @hideconstructor
 * @example
 * return <ObfuscatedEmail emailDomain="insidetracker.com" emailName="contactus">@</ObfuscatedEmail>
 */

const ObfuscatedEmail = (props: ObfuscatedEmailPropsTypes & Partial<ButtonPropsTypes>) => {
  const {
    children,
    className,
    emailName,
    emailDomain,
    subject,
    body,
    button,
    primary,
    green,
    greenGlass,
    glass,
    large,
    testId,
  } = props;

  const onClickObfuscatedEmail = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    e.preventDefault();

    const linkSubject = subject ? `?subject=${subject}` : '';
    const linkBody = body ? `&body=${body}` : '';

    window.location.href = `mailto:${emailName}@${emailDomain}${linkSubject}${linkBody}`;
  };

  const linkText = (children === '@') ? `${emailName}@${emailDomain}` : children;

  return button
    ? (
      <Button
        primary={primary}
        glass={glass}
        greenGlass={greenGlass}
        green={green}
        large={large}
        target="_top"
        className={className}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClickObfuscatedEmail(e)}
        text={linkText}
        data-test-id={testId}
        id={testId}
      />
    )
    : (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        data-test-id={testId}
        className={className}
        target="_top"
        href=""
        onClick={onClickObfuscatedEmail}
      >
        {linkText}
      </a>
    );
};

export default ObfuscatedEmail;

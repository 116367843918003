import React, { useEffect } from "react";

import {
  motion,
  animate,
  useMotionValue,
  useTransform,
} from "framer-motion";

import styles from "./apobBox.module.less";

export default function ApobBox({ graphImageApob }) {
  const apobCount = useMotionValue(0);
  const apobRounded = useTransform(apobCount, (latest) => Math.round(latest));
  useEffect(() => {
    const controls = animate(apobCount, 99, { duration: 2.8, delay: 0.5, ease: "easeInOut" });
    return controls.stop;
  }, []);
  return (
    <motion.div
      className={`${styles.apobBox}`}
      initial={{
        scale: 0,
        opacity: 0,
      }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
        delay: 0.5,
        ease: "easeInOut",
      }}
    >
      <motion.div
        className={`${styles.apobPill}`}
        initial={{
          y: "5px",
          opacity: 0,
        }}
        animate={{
          y: "0px",
          opacity: 1,
        }}
        transition={{
          duration: 0.5,
          delay: 0.5,
          ease: "easeInOut",
        }}
      >
        <span>Normal</span>
      </motion.div>
      <motion.div
        className={`${styles.apobInfo}`}
        initial={{
          y: "5px",
          opacity: 0,
        }}
        animate={{
          y: "0px",
          opacity: 1,
        }}
        transition={{
          duration: 0.5,
          delay: 0.5,
          ease: "easeInOut",
        }}
      >
        <svg
          width="43"
          height="42"
          viewBox="0 0 43 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.7497 41.5858C10.2705 41.5858 0.964844 32.3696 0.964844 21.0009C0.964844 9.63216 10.2705 0.416016 21.7497 0.416016C33.2288 0.416016 42.5345 9.63216 42.5345 21.0009C42.5345 32.3696 33.2288 41.5858 21.7497 41.5858Z"
            fill="#FFC800"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7458 15.2634C12.0014 17.1421 12.0014 20.0762 13.7458 21.9548L21.6515 30.1731C24.3261 27.4724 26.9997 24.7728 29.5582 21.9548C31.3015 20.0773 31.3015 17.1421 29.5582 15.2634C27.8138 13.3848 24.9069 13.3848 23.0473 15.2634L21.5353 16.7892L20.0243 15.2634C18.3961 13.5021 15.4901 13.5021 13.7458 15.2634Z"
            fill="white"
          />
        </svg>
        <h3>ApoB</h3>
        <p>
          <motion.span>{apobRounded}</motion.span>
          mg/dl
        </p>
      </motion.div>
      <div className={`${styles.apobGraph}`}>
        <img src={graphImageApob} alt="ApoB Graph" className={`${styles.apobGraphImage}`} />
        <motion.div
          className={`${styles.apobGraphDot} ${styles.apobGraphDotOne}`}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.2,
            delay: 0.5,
            ease: "easeInOut",
          }}
        >
          <motion.span
            className={`${styles.apobGraphLine} ${styles.apobGraphLineOne}`}
            initial={{
              scale: 0,
              rotate: "-14deg",
            }}
            animate={{
              scale: 1,
              rotate: "-14deg",
            }}
            transition={{
              duration: 1,
              delay: 0.5,
              ease: "easeInOut",
            }}
          />
        </motion.div>
        <motion.div
          className={`${styles.apobGraphDot} ${styles.apobGraphDotTwo}`}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.2,
            ease: "easeInOut",
            delay: 1.5,
          }}
        >
          <motion.span
            className={`${styles.apobGraphLine} ${styles.apobGraphLineTwo}`}
            initial={{
              scale: 0,
              rotate: "15deg",
            }}
            animate={{
              scale: 1,
              rotate: "15deg",
            }}
            transition={{
              duration: 1,
              ease: "easeInOut",
              delay: 1.5,
            }}
          />
        </motion.div>
        <motion.div
          className={`${styles.apobGraphDot} ${styles.apobGraphDotThree}`}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.2,
            ease: "easeInOut",
            delay: 2.5,
          }}
        >
          <motion.span
            className={`${styles.apobGraphLine} ${styles.apobGraphLineThree}`}
            initial={{
              scale: 0,
              rotate: "-31deg",
            }}
            animate={{
              scale: 1,
              rotate: "-31deg",
            }}
            transition={{
              duration: 1,
              ease: "easeInOut",
              delay: 2.5,
            }}
          />
        </motion.div>
        <motion.div
          className={`${styles.apobGraphDot} ${styles.apobGraphDotFour}`}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.2,
            ease: "easeInOut",
            delay: 3.5,
          }}
        />
      </div>
    </motion.div>
  );
}

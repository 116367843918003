import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { datadogLogs } from '@datadog/browser-logs';
import config from '../config';

const parseRequestBody = (config: AxiosRequestConfig) => {
  try {
    return config?.data
      ? {
        requestData: JSON.parse(config.data),
      } : {};
  } catch {
    return {
      requestData: config.data,
    };
  }
};

export function createBaseAxios() {
  const baseAxios = axios.create({
    baseURL: `${config.apiHostName}`,
  });

  baseAxios.defaults.headers.post['Content-Type'] = 'application/json';
  baseAxios.interceptors.response.use(
    (response) => {
      if (['dev', 'qa', 'test'].some((env) => config.apiHostName.startsWith(`https://${env}`))) {
        datadogLogs.logger.debug(
          `${response.status} ${response.config?.method?.toUpperCase()} ${response.config?.baseURL}${response.config?.url}`,
          {
            responseData: response.data,
            ...parseRequestBody(response.config),
          },
        );
      }
      return response;
    },
    (err: AxiosError<any>) => {
      if (['dev', 'qa', 'test'].some((env) => config.apiHostName.startsWith(`https://${env}`))) {
        datadogLogs.logger.debug(
          err
            ? `${err.response?.status || `${err.name} ${err.code}`} ${err.config?.method?.toUpperCase()} ${err.config?.baseURL}${err.config?.url}`
            : "Axios didn't send any error",
          {
            responseData: err?.response?.data,
            ...parseRequestBody(err?.config),
            error: {
              stack: err?.stack,
            },
          },
        );
      } else if ([400, 502, 503].includes(err?.response?.status)) {
        datadogLogs.logger.error(
          `${err.response.status} ${err.config?.method?.toUpperCase()} ${err.config?.baseURL}${err.config?.url} ${err.response.data?.message || ''}`,
          {
            responseData: err.response.data,
            error: {
              stack: err.stack,
            },
          },
        );
      } else {
        datadogLogs.logger.debug(
          err
            ? `${err.response?.status || `${err.name} ${err.code}`} ${err.config?.method?.toUpperCase()} ${err.config?.baseURL}${err.config?.url}`
            : "Axios didn't send any error",
          {
            error: {
              stack: err?.stack,
            },
          },
        );
      }
      throw err;
    },
  );
  return baseAxios;
}

const baseAxios = createBaseAxios();

export default baseAxios;

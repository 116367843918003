export const userActivationStates = {
  active: 'active',
};

export const userPermissions = {
  bloodwork: 'bloodwork',
  summary: 'summary',
  nutrition: 'nutrition',
  food: 'food',
  age: 'age',
};

import userAuthorizedAxios from './userAuthorizedAxios';
import { MarkerRecommendations } from "../../store/bloodwork/recommendations/types";

export default {
  getMarkerRecommendations: (): Promise<Record<string, MarkerRecommendations>> => userAuthorizedAxios.get('/api/customer/recommendations/distributed-by-marker-abbrs')
    .then((responseData) => responseData.data.items),
  getMarkerExtraInformation: (markerAbbr: string): Promise<null> => userAuthorizedAxios.get(`/api/public/blood/markers/${markerAbbr}/extra-information`)
    .then((responseData) => responseData.data),
  getCategoryExtraInformation: (categoryAbbr: string): Promise<null> => userAuthorizedAxios.get(`/api/public/marker-categories/${categoryAbbr}/extra-information`)
    .then((responseData) => responseData.data),
  getAllRecommendations: (): Promise<any[]> => userAuthorizedAxios.get('/api/customer/recommendations/all')
    .then((responseData) => responseData.data),
};

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { userSelector } from '../../../store/user/selectors';
import { fetchUserProfile } from '../../../store/user/actions';
import onboardingApi from '../../../services/Customer/onboardingApi';
import styles from './ShopifyGateway.module.less';
import ActivationState from '../../../Constants/ActivationState';
import CustomerAuthTokenProvider from '../../../services/Customer/customerAuthTokenProvider';

const ShopifyGateway = () => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        const purchaserId = new URLSearchParams(location.search).get('purchaserId');
        const orderId = new URLSearchParams(location.search).get('orderId');
        if (purchaserId && !orderId) {
          const { jwtToken } = await onboardingApi.postPurchase(parseInt(purchaserId, 10));
          CustomerAuthTokenProvider.update(
            jwtToken.access_token,
            jwtToken.expires_in,
          );
          const dataLayer = window.dataLayer || [];
          dataLayer.push({ event: 'Log In' });
          await dispatch(fetchUserProfile());
          history.push('/customer/onboarding');
        } else {
          try {
            const { jwtToken } = await onboardingApi.postPurchaseOrderId(parseInt(orderId, 10));
            CustomerAuthTokenProvider.update(
              jwtToken.access_token,
              jwtToken.expires_in,
            );
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ event: 'Log In' });
            await dispatch(fetchUserProfile());
            history.push('/customer/onboarding');
          } catch (e) {
            if (e.response.status === 417) {
              history.push('/admin/coach/orders-inventory');
              return;
            }
            throw e;
          }
        }
      } catch {
        if (!user) {
          history.push('/customer/login');
        } else if (user.activationState === ActivationState.ACTIVE) {
          history.push('/customer/summary/dashboard');
        } else {
          history.push('/customer/onboarding');
        }
      }
    })();
  });

  return (
    <div className={styles.loaderContainer}>
      <span className={styles.loader}/>
      <p className={styles.text}>
        Please wait while your purchase is being processed...
      </p>
    </div>
  );
};

export default ShopifyGateway;

import React, { ReactElement, useEffect, useState } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import styles from './NavigationLink.module.less';
import { closeMobileMenuEvent } from '../../../Header';
import Icon from '../../../../Icons/Icon';
import { onKeyEnter } from '../../../../../helpers/onKeyEnter';

/**
 * NavigationLinkPropsTypes props types definitions
 */
export type NavigationLinkPropsTypes = {
  /** Link go to */
  link: string,
  /** Link visible name */
  label: string | ReactElement,
  /** Link extra classes */
  className?: string,
  /** Add contactLink class */
  contactLink?: boolean,
  /** Add dropdownLink class */
  dropdownLink?: boolean,
  /** Used for NavigationDropdown (list of inner links) */
  children?: ReactElement | ReactElement[],
  /** Opens in new tab */
  inNewTab?: boolean,
  /** Is external */
  external?: boolean,
  /** Is mobile */
  mobile?: boolean,
};

/**
 * @category Header
 * @subcategory Navigation Link
 * @borrows NavigationLinkPropsTypes as props
 * @component
 * @hideconstructor
 * @example
 * return <NavigationLink link="#!" label="Navigation link"/>
 */
type NavigationCollapsePropsTypes = {
  label: string,
  className?: string,
  onCollapse?: () => void,
  show?: boolean,
  children: any,
};

type ExternalNavigationLinkPropsTypes = {
  link: string,
  label: string,
  contactLink?: boolean,
  className?: string,
  inNewTab?: boolean,
};

type NavigationDropdownPropsTypes = {
  children: any,
  label: string,
  active?: boolean,
  onClick?: () => void,
  className?: string,
};

const NavigationLink = (props: NavigationLinkPropsTypes) => {
  const {
    link, contactLink, dropdownLink, className, label, external, mobile,
  } = props;
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(window.location.pathname === link);
  }, [window.location.pathname]);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    document.dispatchEvent(closeMobileMenuEvent);
  };
  return external
    ? (
      <a
        href={link}
        className={`${styles.navlink}${active ? ` ${styles.active}` : ''}${contactLink ? ` ${styles.contactLink}` : ''}${dropdownLink ? ` ${styles.dropdownLink}` : ''}${mobile ? ` ${styles.navlinkMobile}` : ''}${className ? ` ${className}` : ''}`}
      >
        {label}
      </a> // temp crutch kmp
    )
    : (
      <NavLink
        to={link}
        exact
        className={`${styles.navlink}${contactLink ? ` ${styles.contactLink}` : ''}${dropdownLink ? ` ${styles.dropdownLink}` : ''}${mobile ? ` ${styles.navlinkMobile}` : ''}${className ? ` ${className}` : ''}`}
        activeClassName={styles.active}
        onClick={handleClick}
      >
        {label}
      </NavLink>
    );
};

export const MobileNavigationlink = (props: NavigationLinkPropsTypes) => {
  const { link, label } = props;
  const scrollBehavior = (el: any) => {
    window.scrollTo({ behavior: 'smooth', top: el.offsetTop - 50 });
  };
  return (
    <NavLink
      to={link}
      className={`${styles.navlink} ${styles.hashLink}`}
      exact
      isActive={(_, location) => location.hash === link}
      activeClassName={styles.active}
      scroll={scrollBehavior}
    >
      {label}
    </NavLink>
  );
};

export const ExternalNavigationLink = (props: ExternalNavigationLinkPropsTypes) => {
  const {
    link, contactLink, className, label, inNewTab,
  } = props;

  const openInNewTab: boolean = inNewTab === undefined ? true : inNewTab;
  const targetVal = openInNewTab ? '_blank' : '';
  return (
    <a
      href={link}
      className={`${styles.navlink}${contactLink ? ` ${styles.contactLink}` : ''}${className ? ` ${className}` : ''}`}
      target={targetVal}
      rel="noopener noreferrer"
    >
      {label}
    </a>
  );
};

export const ContactsLinkHash = (props: NavigationLinkPropsTypes) => {
  const { link, label } = props;
  return (
    <a href={link} className={`${styles.navlink} ${styles.contactsLink}`}>
      {label}
    </a>
  );
};

export const NavigationDropdown = (props: Partial<NavigationLinkPropsTypes>) => {
  const { link, label, children } = props;
  return (
    <NavLink
      to={link}
      exact
      className={`${styles.navlink} ${styles.dropdown}`}
      activeClassName={styles.active}
    >
      {label}
      <Icon className={styles.dropdownIcon} iconName="general_arrow"/>
      <ul className={styles.dropdownList}>
        {React.Children.map(children, (item) => (
          <li className={styles.dropdownItem}>
            {item}
          </li>
        ))}
      </ul>
    </NavLink>
  );
};

export const NavigationCollapse = (props: NavigationCollapsePropsTypes) => {
  const { children, label } = props;
  const [show, setShow] = useState(false);

  const onCollapse = () => {
    setShow(!show);
  };

  return (
    <div
      onClick={onCollapse}
      onKeyDown={onKeyEnter(onCollapse)}
      role="button"
      tabIndex={0}
      className={`${styles.collapse}${show ? ` ${styles.show}` : ''}`}
    >
      <div className={`${styles.navlink}${show ? ` ${styles.active}` : ''}`}>
        <Icon className={styles.collapseIcon} iconName="general_arrow" />
        {label}
      </div>
      <ul className={styles.collapseList}>
        {React.Children.map(children, (item) => (
          <li className={styles.collapseItem}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

// admin header
export const AdminNavigationLink = (props: NavigationLinkPropsTypes) => {
  const {
    link, dropdownLink, className, label,
  } = props;
  return (
    <NavLink
      to={link}
      exact
      className={`${styles.adminNavlink}${dropdownLink ? ` ${styles.dropdownLink}` : ''}${className ? ` ${className}` : ''}`}
      activeClassName={styles.active}
    >
      {label}
    </NavLink>
  );
};

export const AdminNavigationDropdown = (props: NavigationDropdownPropsTypes) => {
  const { className, label, children } = props;
  return (
    <div className={`${styles.adminNavlink} ${styles.dropdown}${className ? ` ${className}` : ''}`}>
      {label}
      <Icon className={styles.dropdownIcon} iconName="general_arrow" />
      <ul className={styles.dropdownList}>
        {React.Children.map(children, (item) => (
          <li className={styles.dropdownItem}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavigationLink;

/* eslint-disable no-console,prefer-destructuring */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OnboardingState } from './types';
import {
  fetchSupplements,
  fetchOnboardingFlow,
  fetchOnboardingStatus,
  fetchQuestFlow,
  fetchPhysioMarkers,
  fetchSportCategories,
  fetchExercises,
  fetchDiets,
  fetchFoods,
  fetchBeverages,
  fetchTakeout,
  fetchLifeStyle,
  fetchDesiredFocusGoal,
  fetchDesiredFocusValues,
  fetchWomensHealth,
} from './actions';
import { SelectOption } from '../../../components/NewSelect/Select';
import { SupplementsTakePost } from '../../../interfaces/Profile/Supplements/SupplementsTake';

import {
  ExerciseId,
  ENDURANCE_ID,
  RESISTANCE_ID,
  FLEXIBILITY_ID,
  SPORTS_ID,
} from '../../../interfaces/Profile/Exercise/SportCategory';

export const initialOrdersState: OnboardingState = {
  supplements: null,
  supplementAnswers: null,
  supplementDosages: null,
  physioMarkers: null,
  sportCategories: null,
  lifeStyle: null,
  desiredFocusValues: [],
  desiredFocusGoal: null,
  selectedSportCategories: [],
  sports: null,
  selectedSports: null,
  exercises: null,
  exercisesInput: null,
  diets: null,
  selectedDiets: [],
  foods: null,
  omittedFoodIds: [],
  foodsValues: [],
  beverages: null,
  beveragesValues: [],
  takeout: null,
  takeoutValue: null,
  onboardingFlow: null,
  onboardingStatus: null,
  questFlow: null,
  questFlowControlledByFrontend: false,
  womensHealth: null,
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: initialOrdersState,
  reducers: {
    updateStore: <T extends keyof OnboardingState>(state: OnboardingState, action: PayloadAction<{
      field: T,
      value: OnboardingState[T]
    }>) => {
      state.questFlowControlledByFrontend = true;
      state[action.payload.field] = action.payload.value;
    },

    saveSports: (state, action: PayloadAction<{
      categoryId: ExerciseId,
      sportIds: number[]
    }>) => {
      state.selectedSports[action.payload.categoryId] = action.payload.sportIds;
    },

    clearStore: (state) => {
      state = initialOrdersState;
    },

    controlFlowByFrontend: (state) => {
      state.questFlowControlledByFrontend = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSupplements.fulfilled, ((state, action) => {
      const supplements = action.payload[0].map((supplement) => ({
        ...supplement,
        dosages: supplement.dosages.map<SelectOption>((dose) => ({
          value: dose.name,
          text: dose.title,
        })),
      }));

      const { dosage, ...answers } = action.payload[1];

      state.supplementDosages = dosage.map((dose) => {
        const supplement = supplements.find((supplement) => supplement.name === dose.name);

        return ({
          supplement,
          dosageOption: supplement
            .dosages
            .find((dosageOption) => dosageOption.value === dose.dosage),
        });
      });

      state.supplementAnswers = answers as SupplementsTakePost;
      state.supplements = supplements;
    }));

    builder.addCase(fetchPhysioMarkers.fulfilled, ((state, action) => {
      state.physioMarkers = action.payload;
    }));

    builder.addCase(fetchSportCategories.fulfilled, ((state, action) => {
      state.sportCategories = action.payload;
    }));

    builder.addCase(fetchExercises.fulfilled, ((state, action) => {
      const exercises = action.payload[0];
      const sports = action.payload[1];

      state.exercises = exercises;
      state.sports = sports;

      state.selectedSports = {
        [ENDURANCE_ID]: [],
        [RESISTANCE_ID]: [],
        [FLEXIBILITY_ID]: [],
        [SPORTS_ID]: [],
      };

      const selectedCategories = new Set<ExerciseId>();

      exercises.forEach((exercise) => {
        const { sport_id: sportId } = exercise;
        const sport = sports.find((sport) => sport.id === sportId);

        if (sport) {
          const categoryId = sport.exercise;
          state.selectedSports[categoryId].push(sportId);
          selectedCategories.add(categoryId);
        }
      });

      state.selectedSportCategories = Array.from(selectedCategories);
    }));

    builder.addCase(fetchWomensHealth.fulfilled, ((state, action) => {
      state.womensHealth = action.payload;
    }));

    builder.addCase(fetchLifeStyle.fulfilled, ((state, action) => {
      state.lifeStyle = action.payload;
    }));

    builder.addCase(fetchDiets.fulfilled, ((state, action) => {
      state.diets = action.payload[0];
      state.selectedDiets = action.payload[1];
    }));

    builder.addCase(fetchFoods.fulfilled, ((state, action) => {
      state.foods = action.payload.foods;
      state.foodsValues = action.payload.values;
      state.omittedFoodIds = action.payload.omittedIds;
    }));

    builder.addCase(fetchBeverages.fulfilled, ((state, action) => {
      state.beverages = action.payload[0];
      state.beveragesValues = action.payload[1];
    }));

    builder.addCase(fetchTakeout.fulfilled, ((state, action) => {
      state.takeout = action.payload[0];
      state.takeoutValue = action.payload[1];
    }));

    builder.addCase(fetchDesiredFocusGoal.fulfilled, ((state, action) => {
      state.desiredFocusGoal = action.payload;
    }));

    builder.addCase(fetchDesiredFocusValues.fulfilled, ((state, action) => {
      state.desiredFocusValues = action.payload;
    }));

    builder.addCase(fetchOnboardingFlow.fulfilled, ((state, action) => {
      state.onboardingFlow = action.payload.onboardingStatusFlow;
    }));

    builder.addCase(fetchOnboardingStatus.fulfilled, ((state, action) => {
      state.onboardingStatus = action.payload;
    }));

    builder.addCase(fetchQuestFlow.fulfilled, ((state, action) => {
      state.questFlow = action.payload;
    }));
  },
});

export const {
  updateStore,
  saveSports,
  controlFlowByFrontend,
  clearStore,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './NavigationLink.module.less';
import { NavigationLinkPropsTypes } from './NavigationLink';
import Icon from '../../../../Icons/Icon';
import { onKeyEnter } from '../../../../../helpers/onKeyEnter';

/**
 * Navigation dropdown component
 * @category Header
 * @subcategory Navigation Link
 * @borrows NavigationLinkPropsTypes as props
 * @component
 * @hideconstructor
 * @example
 * return (<NavigationDropdown link={"#!"} label="Dropdown">
 * <NavigationLink dropdownLink link={"#!"} label={"Analysis & Recommendations"} />
 * <NavigationLink dropdownLink link={"#!"} label={"Table View"} />
 * <NavigationLink dropdownLink link={"#!"} label={"Add Test Results"} />
 * <NavigationLink dropdownLink link={"#!"} label={"Orders"} />
 * </NavigationDropdown>)
 */
const NavigationDropdown = (props: NavigationLinkPropsTypes) => {
  const { link, label, children } = props;
  const [active, setActive] = useState(false);
  const history = useHistory();
  // eslint-disable-next-line no-return-assign
  const goTo = () => history.push(link);

  useEffect(() => {
    const links = React.Children.map(children, (child) => child?.props?.link)
      .filter(Boolean);
    setActive(window.location.pathname === link || links.some((link) => window.location.pathname === link));
  }, [window.location.pathname]);

  return (
    <span
      onClick={goTo}
      onKeyDown={onKeyEnter(goTo)}
      role="link"
      tabIndex={0}
      className={`${styles.navlink} ${styles.dropdown} ${active ? styles.active : ''}`}
    >
      {label}
      <Icon className={styles.dropdownIcon} iconName="general_arrow"/>
      <ul className={styles.dropdownList}>
        {React.Children.map(children, (item) => (
          <li className={styles.dropdownItem}>
            {item}
          </li>
        ))}
      </ul>
    </span>
  );
};

export default NavigationDropdown;

import React from 'react';
import Spinner from '../Spinner/Spinner';

const componentsMap = new Map();

const getComponent = (componentModule: () => Promise<{ default: () => JSX.Element }>) => {
  if (componentsMap.has(componentModule)) return componentsMap.get(componentModule);

  const Component = React.lazy(componentModule);
  componentsMap.set(componentModule, Component);
  return Component;
};

const CachedLazy = (
  componentModule: () => Promise<{ default: () => JSX.Element }>,
  fallback: React.ReactNode = <Spinner show/>,
) => {
  const Component = getComponent(componentModule);

  return () => (
    <React.Suspense fallback={fallback}>
      <Component/>
    </React.Suspense>
  );
};

export default CachedLazy;

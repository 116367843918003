import { createAsyncThunk } from '@reduxjs/toolkit';
import recommendationsApi from "../../../services/Customer/recommendationsApi";
import TableViewApi from "../../../services/Customer/TableViewApi";

export const fetchBloodRecommendations = createAsyncThunk(
  'recommendations/fetchBloodRecommendations',
  async () => await recommendationsApi.getMarkerRecommendations(),
);

export const fetchMarkerExtraInformation = createAsyncThunk(
  'recommendations/fetchMarkerExtraInformation',
  async (markerAbbr: string) => await recommendationsApi.getMarkerExtraInformation(markerAbbr),
);

export const fetchCategoryExtraInformation = createAsyncThunk(
  'recommendations/fetchCategoryExtraInformation',
  async (categoryAbbr: string) => await recommendationsApi.getCategoryExtraInformation(categoryAbbr),
);

export const fetchAvailableMarkersAbbr = createAsyncThunk(
  'references/fetchAvailableMarkersAbbr',
  async () => {
    const [availableMarkersAbbr] = await Promise.all([
      TableViewApi.getAvailableMarkerAbbrs(),
    ]);

    return { availableMarkersAbbr };
  },
);
export default {
  fetchBloodRecommendations,
  fetchMarkerExtraInformation,
  fetchCategoryExtraInformation,
  fetchAvailableMarkersAbbr,
};

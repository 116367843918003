import { RootState } from '../index';

export const referralEngagementsSelector = ({ common }: RootState) => common.referralEngagements;
export const locationSelector = ({ common }: RootState) => common.userLocation;
export const configurationSelector = ({ common }: RootState) => common.configuration;
export const isRestrictedPopupOpen = ({ common }: RootState) => common.restrictedPopupOpen;
export const isIntBannerShown = ({ common }: RootState) => common.intBannerShown;

export default {
  referralEngagementsSelector,
  locationSelector,
  configurationSelector,
  isRestrictedPopupOpen,
};

import { Builder } from "@builder.io/react";

import themeLightBox from "./themeLightBox";

Builder.registerComponent(themeLightBox, {
  name: 'Theme LightBox',
  inputs: [
    {
      name: "image",
      friendlyName: "Video Thumbnail Image",
      type: "file",
      allowedFileTypes: ["jpeg", "jpg", "png"],
      defaultValue: "https://placehold.co/330x330",
      required: true
    },
    {
      name: "videoUrl",
      friendlyName: "Youtube Video URL",
      type: "string",
      required: true
    },
  ]
})
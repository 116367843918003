import { createAsyncThunk } from '@reduxjs/toolkit';
import profileApi from '../../../services/Customer/profileApi';
import onboardingApi from '../../../services/Customer/onboardingApi';

export const fetchSupplements = createAsyncThunk(
  'user/fetchSupplements',
  async () => await Promise.all([
    profileApi.getSupplements(),
    profileApi.getWillingToTake(),
  ]),
);

export const fetchPhysioMarkers = createAsyncThunk(
  'user/fetchPhysioMarkers',
  async () => await onboardingApi.getPhysioMarkers(),
);

export const fetchWomensHealth = createAsyncThunk(
  'user/fetchWomensHealth',
  async () => await onboardingApi.getWomensHealth(),
);

export const fetchLifeStyle = createAsyncThunk(
  'user/fetchLifeStyle',
  async () => await onboardingApi.getLifestyle(),
);

export const fetchDesiredFocusGoal = createAsyncThunk(
  'user/fetchDesiredFocusGoal',
  async () => await onboardingApi.getDesiredFocusGoal(),
);

export const fetchDesiredFocusValues = createAsyncThunk(
  'user/fetchDesiredFocusValues',
  async () => await onboardingApi.getDesiredFocusValues(),
);

export const fetchSportCategories = createAsyncThunk(
  'user/fetchSportCategories',
  async () => await profileApi.getSportCategories(),
);

export const fetchExercises = createAsyncThunk(
  'user/fetchExercises',
  async () => await Promise.all([
    profileApi.getExercises(),
    profileApi.getSports(),
  ]),
);

export const fetchDiets = createAsyncThunk(
  'user/fetchDiets',
  async () => await Promise.all([
    onboardingApi.getDietaryOptions(),
    onboardingApi.getSelectedDietary(),
  ]),
);

export const fetchFoods = createAsyncThunk(
  'user/fetchFoods',
  async () => {
    const res = await Promise.all([
      onboardingApi.getFoods(),
      onboardingApi.getFoodsValues(),
    ]);

    return {
      foods: res[0].foods.concat(res[0].restricted),
      omittedIds: res[0].restricted.map((food) => food.id),
      values: res[1],
    };
  },
);

export const fetchBeverages = createAsyncThunk(
  'user/fetchBeverages',
  async () => await Promise.all([
    onboardingApi.getBeverages(),
    onboardingApi.getBeveragesValues(),
  ]),
);

export const fetchTakeout = createAsyncThunk(
  'user/fetchTakeout',
  async () => await Promise.all([
    onboardingApi.getTakeout(),
    onboardingApi.getTakeoutValues(),
  ]),
);

export const fetchOnboardingFlow = createAsyncThunk(
  'user/fetchOnboardingFlow',
  async () => await onboardingApi.getOnboardingFlow(),
);

export const fetchOnboardingStatus = createAsyncThunk(
  'user/fetchOnboardingStatus',
  async () => await onboardingApi.getOnboardingStatus(),
);

export const fetchQuestFlow = createAsyncThunk(
  'user/fetchQuestFlow',
  async (arg, thunkAPI) => {
    thunkAPI.dispatch(fetchOnboardingStatus());
    return await onboardingApi.getQuestFlow();
  },
);

export default {
  fetchSupplements,
  fetchPhysioMarkers,
  fetchOnboardingFlow,
  fetchOnboardingStatus,
  fetchQuestFlow,
  fetchLifeStyle,
};

import FsLightbox from "fslightbox-react";
import React, { useState } from "react";

export default function ThemeLightBox({
  image,
  videoUrl,
}) {
  const [toggler, setToggler] = useState(false);
  return (
    <>
      <img src={image} alt="video Thumbnail" onClick={() => setToggler(!toggler)} role="button" onKeyUp={() => setToggler(!toggler)} style={{ cursor: "pointer" }}/>
      <FsLightbox sources={[videoUrl]} toggler={toggler} />
    </>
  );
}

import { useEffect, useState } from 'react';

/**
 * Сheck for mobile version
 * @category Helpers
 */
const useMobile = (size: number = 630) => {
  const [mobile, setMobile] = useState(false);

  const isMobile = () => setMobile(document.documentElement.clientWidth < size);

  useEffect(() => {
    isMobile();
    window.addEventListener('resize', isMobile);
    return () => {
      window.removeEventListener('resize', isMobile);
    };
  }, []);

  return mobile;
};

export default useMobile;

import { Builder } from "@builder.io/react";

import themeCardNew from "./themeCardNew";

Builder.registerComponent(themeCardNew, {
  name: "Theme Card New",
  inputs: [
    {
      name: "image",
      type: "file",
      friendlyName: "Image",
      allowedFileTypes: ["jpeg", "jpg", "png"],
      defaultValue: "https://placehold.co/340x210"
    },
    {
      name: "title",
      type: "text",
      friendlyName: "Title",
      defaultValue: "Explore our plans"
    },
    {
      name: "info",
      type: "longText",
      friendlyName: "Info",
      defaultValue: "Take the first step down your personalized path to living healthier longer."
    },
    {
      name: "link",
      type: "url",
      friendlyName: "URL",
      defaultValue: "https://www.insidetracker.com"
    },
    {
      name: "isInternal",
      type: "boolean",
      friendlyName: "Check if the Link is Internal",
      defaultValue: false,
    },
    {
      name: "pageName",
      type: "string",
      friendlyName: "Enter Page Name for Internal Link",
      helperText: "Only Applicable when the link is internal"
    },
    {
      name: "videoUrl",
      type: "text",
      friendlyName: "Paste Youtube Iframe Embed URL"
    }
  ]
})
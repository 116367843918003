import { NavHashLink as NavLink } from 'react-router-hash-link';
import React from 'react';
import styles from './NavigationLink.module.less';
import { NavigationLinkPropsTypes } from './NavigationLink';
import { closeMobileMenuEvent } from '../../../Header';

/**
 * @category Header
 * @subcategory Navigation Link
 * @borrows NavigationLinkPropsTypes as props
 * @component
 * @hideconstructor
 * @example
 * return <MobileNavigationlink link={"#!"} label="Mobile nav link"/>
 */
const MobileNavigationlink = (props: NavigationLinkPropsTypes) => {
  const { link, label } = props;
  /**
   * Function handler that scroll to target element with smooth scroll behavior
   * */
  const scrollBehavior = (el: any) => {
    window.scrollTo({ behavior: 'smooth', top: el.offsetTop - 50 });
  };
  const handleClick = () => {
    document.dispatchEvent(closeMobileMenuEvent);
  };

  return (
    <NavLink
      to={link}
      className={`${styles.navlink} ${styles.hashLink}`}
      exact
      isActive={(_, location) => location.hash === link}
      activeClassName={styles.active}
      scroll={scrollBehavior}
      onClick={handleClick}
    >
      {label}
    </NavLink>
  );
};

export default MobileNavigationlink;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from './types';

const initialState: AppState = {
  loadings: {},
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoadings: (
      state,
      { payload: { key, value } }: PayloadAction<{ key: string; value: boolean }>,
    ) => {
      state.loadings[key] = value;
    },
  },
});

export const {
  setLoadings,
} = appSlice.actions;

export default appSlice.reducer;

import { Builder, withChildren } from "@builder.io/react";

import ThemeScrollSection from "./themeScrollSection";

const themeScrollSectionWithChildren = withChildren(ThemeScrollSection);

Builder.registerComponent(themeScrollSectionWithChildren, {
  name: 'Theme Scroll Section',
  inputs: [
    {
      type: "color",
      name: "circleColor",
      friendlyName: "Indicators Circle Color",
      defaultValue: "#ffffff",
    },
    {
      type: "color",
      name: "circleBorderColor",
      friendlyName: "Indicators Circle Border Color",
      defaultValue: "#ffffff",
    }
  ],
  noWrap: true,
  canHaveChildren: true,
  defaultStyles: {
    position: "relative",
    width: "100%"
  },
  defaultChildren: [
    {
      '@type' : '@builder.io/sdk:Element',
      component: {
          name: 'Theme Container'
      }
    }
  ]
})
/* eslint-disable no-console */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrdersState } from './types';
import { Test } from '../../../interfaces/Orders/Tests';
import { fetchUserOrders } from './actions';
import ActivationStep from '../../../Constants/Customer/Orders/ActivationFlowSteps';

export const initialOrdersState: OrdersState = {
  availableTests: null,
  previousTests: null,
  activatedTest: null,
  activationFlow: {
    currentStep: null,
    testFromActivating: null,
    testForActivating: null,
  },
  diyOrderInfo: null,
  transferredTests: null,
};

const customerOrdersSlice = createSlice({
  name: 'userOrders',
  initialState: initialOrdersState,
  reducers: {
    resetActivation: (state) => {
      console.log('Activation flow cancelled');
      state.activationFlow = {
        testFromActivating: null,
        testForActivating: null,
        currentStep: null,
      };
    },

    moveOnActivation: (state, action: PayloadAction<ActivationStep>) => {
      const activationStep = action.payload;
      console.log(`Activation move on ${activationStep}`);
      state.activationFlow = {
        ...state.activationFlow,
        currentStep: activationStep,
      };
    },

    startActivation: (state, action: PayloadAction<Test>) => {
      console.log('Activation flow started');

      const { activatedTest } = state;
      const testForActivation = action.payload;

      const toStep = (step: ActivationStep) => {
        console.log(`Activation flow to ${step} step`);
        state.activationFlow = {
          testFromActivating: activatedTest,
          testForActivating: testForActivation,
          currentStep: step,
        };
      };

      if (testForActivation.notifyAboutPregnancy) {
        toStep(ActivationStep.PREGNANCY_STEP);
        return;
      }

      if (activatedTest && activatedTest.hasMbd && !activatedTest.isRescheduleAvailable) {
        toStep(ActivationStep.EXPIRE_STEP);
        return;
      }

      if (activatedTest) {
        toStep(ActivationStep.CONFIRM_ACTIVATING_STEP);
        return;
      }

      if (!testForActivation.hasHomeKit && !testForActivation.hasMbd && testForActivation.shouldPreventActivationWithoutMbd) {
        toStep(ActivationStep.CONFIRM_LAB_TEST);
        return;
      }

      toStep(ActivationStep.CONFIRM_ACTIVATING_STEP);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserOrders.fulfilled, ((state, action) => {
      const {
        availableTests, previousTests, transferredTests, diyOrderInfo,
      } = action.payload;
      state.availableTests = availableTests;
      state.previousTests = previousTests;
      state.transferredTests = transferredTests;
      state.diyOrderInfo = diyOrderInfo;

      state.activatedTest = availableTests.find((test: Test) => test.isActivated);
    }));
  },
});

export const {
  resetActivation,
  startActivation,
  moveOnActivation,
} = customerOrdersSlice.actions;

export default customerOrdersSlice.reducer;

import React from "react";

import { motion, animate } from "framer-motion";

import styles from "./fiberFruits.module.less";

export default function FiberFruits({ highIntensityImage }) {
  return (
    <motion.div
      className={`${styles.fruitBox}`}
      initial={{
        y: "30px",
        opacity: 0,
      }}
      animate={{
        y: "0px",
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
        delay: 0.8,
        ease: "easeInOut",
      }}
    >
      <div className={`${styles.fruitBoxImage}`}>
        <img src={highIntensityImage} alt="Include resistance training in your workouts" />
      </div>
      <div className={`${styles.fruitBoxInfo}`}>
        <motion.h3
          initial={{
            y: "5px",
            opacity: 0,
          }}
          animate={{
            y: "0px",
            opacity: 1,
          }}
          transition={{
            duration: 0.2,
            delay: 0.8,
            ease: "easeInOut",
          }}
        >
          Include resistance training in your workouts
        </motion.h3>
        <motion.span
          initial={{
            y: "5px",
            opacity: 0,
          }}
          animate={{
            y: "0px",
            opacity: 1,
          }}
          transition={{
            duration: 0.2,
            delay: 0.8,
            ease: "easeInOut",
          }}
        >
          9.9 Impact
        </motion.span>
      </div>
    </motion.div>
  );
}

import * as React from 'react';

// Styles
import { Helmet } from 'react-helmet';
import styles from '../terms.module.less';
import ObfuscatedEmail from '../../../../components/ObfuscatedEmail/ObfuscatedEmail';

const ResponsibleDisclosurePolicy = () => (
  <>
    <Helmet>
      <title>Responsible Disclosure Program</title>
    </Helmet>
    <div className={styles.SectionWrapper}>
      <section className={styles.SectionTitle}>
        <div className={styles.container1170}>
          <h2
            className={`${styles.heading30} ${styles.SectionTitleItem} ${styles.hideTablet} `}
          >
            Segterra (InsideTracker) - Responsible Disclosure Program
          </h2>
          <h2
            className={`${styles.heading30} ${styles.SectionTitleItem} ${styles.showTablet} `}
          >
            Responsible Disclosure Program
          </h2>
        </div>
      </section>
      <section className={styles.SectionTerms}>
        <div className={styles.TermsItem}>
          <div className={styles.container1170}>
            <div className={styles.TermsBlock}>
              <div className={styles.TermsBlockItem}>
                <p className={styles.text16}>
                  Last updated: October 12, 2022
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.TermsItem}>
          <div className={styles.container1170}>
            <h2 className={`${styles.heading30} ${styles.TermsItemTitle}`}>
              Responsible Disclosure Policy
            </h2>
            <div className={styles.TermsBlockItem}>
              <p className={styles.text16}>
                InsideTracker (Segterra) Security is continually adapting to the changing cybersecurity
                landscape and to stay ahead of bad actors and threats to our systems and applications.
                However, keeping our customer and employee information safe is not achieved by technology
                alone – it takes alerts from employees, customers and partners, who know how to
                recognize and report issues.
              </p>
            </div>
            <div className={styles.TermsBlockItem}>
              <p className={styles.text16}>
                InsideTracker (Segterra)’s Responsible Disclosure program allows our customers and
                partners to submit vulnerabilities that they may find on any public-facing websites
                or applications owned, operated or controlled by InsideTracker (Segterra).
                Any services provided or hosted by a third-party are not eligible. An application
                or system vulnerability is a system flaw or weakness that could be exploited to
                compromise the security of an application or system. If you are unsure if the
                vulnerability you are reporting meets this criteria, please contact us
                {' '}
                <ObfuscatedEmail
                  className={`${styles.linkPrimary}`}
                  emailName="info_security"
                  emailDomain="insidetracker.com"
                >
                  @
                </ObfuscatedEmail>
                {' '}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.TermsItem}>
          <div className={styles.container1170}>
            <h2 className={`${styles.heading30} ${styles.TermsItemTitle}`}>
              How to Submit a Vulnerability
            </h2>
            <div className={styles.TermsBlockItem}>
              <p className={styles.text16}>
                Vulnerability reports can be emailed to
                {' '}
                <ObfuscatedEmail
                  className={`${styles.linkPrimary}`}
                  emailName="info_security"
                  emailDomain="insidetracker.com"
                >
                  @
                </ObfuscatedEmail>
                {' '}
              </p>
              <p className={styles.text16}>
                The information you provide will be reviewed by members of InsideTracker (Segterra)
                Security Team. Your report should include the following information:
              </p>
              <ul className={`${styles.list} ${styles.TermsBlockList}`}>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  The mobile application, InsideTracker website, service, product, or system involved.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  The type of issue discovered.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  An estimated impact of the vulnerability.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Suggested mitigation or remediation.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  A detailed description of the potential vulnerability.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  A complete walk-through describing the steps necessary to reproduce the vulnerability.
                </li>
              </ul>
              <p className={styles.text16}>
                A detailed report is crucial to the team to remedy your submitted vulnerability.
                If the above requirements have been met, we will confirm receiving your report within
                three business days. The information you provide will be used to correct vulnerabilities
                and improve the security of our applications and infrastructure. A member of the
                Security team will reach out to you if additional details are needed.
              </p>
              <p className={styles.text16}>
                While confirming the vulnerability, InsideTracker (Segterra) will attempt to keep
                you informed of the status on a reasonable basis.
              </p>
              <p className={styles.text16}>
                By submitting your vulnerability disclosure to InsideTracker (Segterra) you agree
                that you will keep information related to the vulnerability confidential and not
                disclose the vulnerability to any third-party unless InsideTracker (Segterra) has
                provided you with written authorization to do so. Submission of this vulnerability
                report provides your permission for InsideTracker (Segterra) to use, create
                derivatives of, disclose, or modify any information that you have provided.
              </p>
              <p className={styles.text16}>
                InsideTracker (Segterra)
                {' '}
                <b>
                  assumes no obligation or responsibility for providing
                  financial or other types of compensation to you for reporting this vulnerability.
                </b>
                {' '}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.TermsItem}>
          <div className={styles.container1170}>
            <h2 className={`${styles.heading30} ${styles.TermsItemTitle}`}>
              Program Rules and Guidelines
            </h2>
            <div className={styles.TermsBlockItem}>
              <p className={styles.text16}>
                Compliance with this program requires that you read the following carefully and abide
                by all of the specific scoping guidelines. Questions regarding these restrictions can
                be sent to:
                {' '}
                <ObfuscatedEmail
                  className={`${styles.linkPrimary}`}
                  emailName="info_security"
                  emailDomain="insidetracker.com"
                >
                  @
                </ObfuscatedEmail>
                {' '}
              </p>
              <ul className={`${styles.list} ${styles.TermsBlockList}`}>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Violate privacy.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Negatively impact the user experience.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Destroy or manipulate data.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Use exploits unless they are absolutely necessary to confirm a vulnerability.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Exfiltrate data under any circumstances, establish command line access and/or
                  persistence, or "pivot" to other systems.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Once you've established a vulnerability exists or encountered personally identifiable,
                  financial, proprietary information, or trade secrets
                  {' '}
                  <b>
                    you must stop your test and
                    notify InsideTracker (Segterra) immediately.
                  </b>
                  {' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.TermsItem}>
          <div className={styles.container1170}>
            <div className={styles.TermsBlockItem}>
              <p className={styles.text16}>
                Do not perform any of the following actions:
              </p>
              <ul className={`${styles.list} ${styles.TermsBlockList}`}>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Destruction, alteration, disclosure, or access denial of InsideTracker (Segterra)
                  or customer data.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Causing or attempting to cause harm against InsideTracker (Segterra),
                  InsideTracker (Segterra) employees, affiliates, or customers.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Denial of service testing.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Social engineering (e.g. phishing, vishing), or any other non-technical
                  vulnerability testing.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Intentionally accessing any data or information being stored or transmitted
                  by InsideTracker (Segterra) with exception to what is absolutely necessary to
                  validate the existence of the vulnerability.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Exfiltration of data; customer, financial, intellectual property, personally
                  identifiable or otherwise.
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Public disclosure of the vulnerability without written consent from InsideTracker
                  (Segterra).
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Intentional compromise of sensitive or confidential data, intellectual property,
                  or financial interests of InsideTracker (Segterra), its third parties, or personnel.
                </li>
              </ul>
              <p className={styles.text16}>
                {' '}
                <b>
                  STOP
                </b>
                {' '}
                your activities and notify us
                {' '}
                <b>
                  immediately
                </b>
                {' '}
                if you encounter any of the information below while testing within the scope of this program:
              </p>
              <ul className={`${styles.list} ${styles.TermsBlockList}`}>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Personally identifiable information (PII), (PHI).
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Financial information (e.g. credit card or bank account numbers).
                </li>
                <li className={`${styles.listItem} ${styles.TermsBlockListItem}`}>
                  Proprietary information or trade secrets of companies of any party.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.TermsItem}>
          <div className={styles.container1170}>
            <h2 className={`${styles.heading30} ${styles.TermsItemTitle}`}>
              Authorization
            </h2>
            <div className={styles.TermsBlockItem}>
              <p className={styles.text16}>
                Responsible disclosure reports must be submitted by persons who are 13 years or older,
                following The Children’s Online Privacy Protection Act (COPPA) guidelines.
              </p>
            </div>
            <div className={styles.TermsBlockItem}>
              <p className={styles.text16}>
                You must comply with all applicable laws and regulations. InsideTracker (Segterra)
                does not permit, allow, or authorize any actions that are inconsistent with this program.
                InsideTracker (Segterra) reserves all legal rights in the event of noncompliance with these guidelines.
              </p>
            </div>
            <div className={styles.TermsBlockItem}>
              <p className={styles.text16}>
                If you make a good faith effort to comply with this program, we will work with you
                to understand and quickly resolve the issue, and InsideTracker (Segterra) will not
                recommend legal action in relation to your submitted vulnerability.
              </p>
            </div>
            <div className={styles.TermsBlockItem}>
              <p className={styles.text16}>
                InsideTracker (Segterra) may modify this program and associated terms at any time.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
);

export default ResponsibleDisclosurePolicy;

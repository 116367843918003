import { Builder } from "@builder.io/react";

import themeButton from "./themeButton";

Builder.registerComponent(themeButton, {
  name: "Theme Button",
  inputs: [
    {
      type: "object",
      name: "buttonInfo",
      friendlyName: "Button Information",
      defaultValue: {
        label: "Get Started",
        url: "https://www.insidetracker.com/"
      },
      subFields: [
        {
            name: "label",
            type: "string",
            required: true,
            friendlyName: "Button Label"
        },
        {
            name: "url",
            type: "url",
            required: true,
            friendlyName: "Button Link"
        },
        {
          name: "isInternal",
          type: "boolean",
          friendlyName: "Check if the Link is Internal",
          defaultValue: false,
        },
        {
          name: "pageName",
          type: "string",
          friendlyName: "Enter Page Name for Internal Link",
          helperText: "Only Applicable when the link is internal"
        }
      ]
    },
  ],
  noWrap: true,
  defaultStyles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    letterSpacing: "0.03px",
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "600",
    textAlign: "center",
    borderRadius: "3px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#00A050",
    color: "#ffffff",
    backgroundColor: "#00A050",
    textDecoration: "none",
    maxWidth: "max-content",
  },
  defaults: {
    responsiveStyles: {
      large: {
        fontSize: "20px",
        lineHeight: "30px",
        paddingTop: "12px",
        paddingRight: "40px",
        paddingBottom: "12px",
        paddingLeft: "40px",
      },
      medium: {
        fontSize: "20px",
        lineHeight: "30px",
        paddingTop: "12px",
        paddingRight: "40px",
        paddingBottom: "12px",
        paddingLeft: "40px",
      },
      small: {
        fontSize: "14px",
        lineHeight: "20px",
        paddingTop: "8px",
        paddingRight: "20px",
        paddingBottom: "8px",
        paddingLeft: "20px",
      }
    }
  }
});

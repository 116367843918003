import React, {
  lazy,
  Suspense,
} from 'react';
import Spinner from '../../../components/Spinner/Spinner';
import { CareerType } from '../../../interfaces/Public/CareerType';

const Job = lazy(() => import('./Job'));
export default () => {
  const careerInfo = JSON.parse(localStorage.getItem('careerPreview'));
  const jobInfo: CareerType = {
    id: -1,
    date: null,
    title: (careerInfo && careerInfo.title as string) || '',
    description: (careerInfo && careerInfo.description as string) || '',
    requirements: (careerInfo && careerInfo.requirements as string) || '',
    responsibilities: (careerInfo && careerInfo.responsibilities as string) || '',
    skills: (careerInfo && careerInfo.skills as string) || '',
    details: (careerInfo && careerInfo.details as string) || '',
    about: (careerInfo && careerInfo.about as string) || '',
  };

  return (
    <Suspense fallback={<Spinner show/>}>
      <Job jobInfo={jobInfo}/>
    </Suspense>
  );
};

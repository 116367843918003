import { Builder } from "@builder.io/react";

import themeProductCard from "./themeProductCard";

Builder.registerComponent(themeProductCard, {
  name: 'Theme Product Card',
  inputs: [
    {
      name: "productTag",
      friendlyName: "Product Tag",
      type: "string",
    },
    {
      name: "productTitle",
      friendlyName: "Product Title",
      type: "string",
      defaultValue: "Ultimate",
      required: true
    },
    {
      name: "productImage",
      friendlyName: "Product Image",
      type: "file",
      allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
      defaultValue: "https://placehold.co/300x300",
      required: true
    },
    {
      name: "productCatTitle",
      friendlyName: "Product Category Title",
      type: "string",
      defaultValue: "Healthspan categories included with Ultimate",
    },
    {
      name: "productCats",
      friendlyName: "Product Categories",
      type: "list",
      subFields: [
        {
          name: "cat",
          friendlyName: "Enter Category",
          type: "string"
        }
      ]
    },
    {
      name: "productPrice",
      friendlyName: "Product Price",
      type: "string",
      defaultValue: "$659"
    },
    {
      name: "productPerText",
      friendlyName: "Per Text",
      type: "string",
      defaultValue: "per test"
    },
    {
      name: "hasBloodTest",
      friendlyName: "Has Blood Test",
      type: "boolean",
      defaultValue: false
    },
    {
      name: "productUrl",
      friendlyName: "Product URL",
      type: "url",
      defaultValue: "#"
    }
  ]
})
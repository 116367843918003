import React from "react";

import styles from "./themeBlogCard.module.less";

export default function BlogCard({
  image,
  title,
  excerpt,
  authorInfo,
  contentColor,
  hasImageBorder,
}) {
  return (
    <div className={`${styles.blogCard}`} style={{ "--content-color": contentColor }}>
      <div className={`${styles.blogCardLink}`}>
        <div className={`${styles.blogCardImage} ${hasImageBorder ? styles.blogCardImageBorder : ""}`}>
          <img src={image} alt={title} />
        </div>
        <div className={`${styles.blogCardInfo}`}>
          <div className={`${styles.blogCardInfoTitle}`}>{title}</div>
          <div className={`${styles.blogCardInfoExcerpt}`}>{`${excerpt}`}</div>
          <div className={`${styles.blogCardAuthoInfo}`}>{authorInfo}</div>
        </div>
      </div>
    </div>
  );
}

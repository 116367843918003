import { Builder } from "@builder.io/react";

import themeBlogCard from "./themeBlogCard";

Builder.registerComponent(themeBlogCard, {
  name: "Theme Blog Card",
  inputs: [
    {
      name: "image",
      friendlyName: "Image",
      type: "file",
      allowedFileTypes: ["jpeg", "jpg", "png"],
      defaultValue: "https://placehold.co/330x330",
      required: true
    },
    {
      name: "title",
      friendlyName: "Title",
      type: "string",
      defaultValue: "Gideon Akande",
      required: true
    },
    {
      name: "excerpt",
      friendlyName: "Exceprt",
      type: "longText",
      defaultValue: "If you're looking for improved quality of life and a higher rate of longevity, then pooling the information from your genes, blood, and activity levels gives you the best chances to find it."
    },
    {
      name: "authorInfo",
      friendlyName: "Author Info",
      type: "string",
      defaultValue: "Fitness Consultant, Content Creator, and Coach"
    },
    {
      name: "contentColor",
      type: "color",
      friendlyName: "Content Color",
      defaultValue: "#005550"
    },
    {
      name: "hasImageBorder",
      type: "boolean",
      friendlyName: "Show Border in Blog Image",
      defaultValue: false
    }
  ],
});

import React, { useEffect, useState } from 'react';
import styles from './Message.module.less';

type FlashMessage = {
  text: string,
  type: 'success' | 'error',
};

declare global {
  interface Window {
    message: (text: string, type?: 'success' | 'error') => void;
  }
}

const Message = () => {
  const [message, setMessage] = useState<FlashMessage>({ text: null, type: 'success' });
  const [show, setShow] = useState(false);

  window.message = (text: string, type?: 'success' | 'error') => {
    setMessage({ text, type: type || 'success' });
  };

  useEffect(() => {
    if (message.text) setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [message]);

  return (
    <div
      className={`${styles.Message} ${styles[message.type]}${show ? ` ${styles.MessageShow}` : ''}`}
    >
      {message.text}
    </div>
  );
};

export default Message;

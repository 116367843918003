import { createAsyncThunk } from '@reduxjs/toolkit';
import referenceApi from '../../services/referenceApi';
import publicPageApi from '../../services/publicPageApi';

export const fetchAppInitialData = createAsyncThunk(
  'app/fetchAppInitialData',
  async () => {
    const [
      states,
      countries,
      products,
      ethnicities,
      referralEngagements,
      configuration,
    ] = await Promise.all([
      referenceApi.getStates(),
      referenceApi.getCountries(),
      referenceApi.getProducts(),
      referenceApi.getEthnicities(),
      publicPageApi.getReferralEngagements(),
      referenceApi.getConfiguration(),
    ]);

    return {
      states,
      countries,
      products,
      ethnicities,
      referralEngagements,
      configuration,
    };
  },
);

export default {
  fetchAppInitialData,
};

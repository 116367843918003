import { createSlice } from '@reduxjs/toolkit';
import { AdminInitialState } from './types';
import { loginAdminByEmail, fetchAdminProfile } from './actions';

const initialState: AdminInitialState = {
  admin: null,
  isLoggedIn: false,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    logoutAdmin: (() => {
      localStorage.removeItem('adminToken');
      return initialState;
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(loginAdminByEmail.fulfilled, (state, action) => {
      localStorage.setItem('adminToken', JSON.stringify(action.payload));
      state.isLoggedIn = true;
    });
    builder.addCase(fetchAdminProfile.fulfilled, (state, action) => {
      state.admin = action.payload;
      state.isLoggedIn = true;
    });
    builder.addCase(fetchAdminProfile.rejected, () => {
      localStorage.removeItem('adminToken');
      // history.push('/admin/login');
      return initialState;
    });
    builder.addCase(loginAdminByEmail.rejected, () => {
      localStorage.removeItem('adminToken');
      return initialState;
    });
  },
});

export const {
  logoutAdmin,
} = adminSlice.actions;

export default adminSlice.reducer;

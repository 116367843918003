import React, { useEffect } from "react";

import {
  motion,
  animate,
  useMotionValue,
  useTransform,
} from "framer-motion";

import styles from "./innerAgeAnimation.module.less";

export default function InnerAgeAnimation() {
  const count = useMotionValue(32.0);
  const rounded = useTransform(count, (latest) => latest.toFixed(1));
  useEffect(() => {
    const controls = animate(count, 38.3, {
      duration: 2,
      delay: 0.5,
      ease: "easeInOut",
    });
    return controls.stop;
  }, []);
  return (
    <motion.div
      className={`${styles.innerAgeBox}`}
      initial={{
        x: "-10px",
        scale: 0,
        opacity: 0,
      }}
      animate={{
        x: "0px",
        scale: 1,
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
        delay: 0.5,
        ease: "easeInOut",
      }}
    >
      <motion.span
        className={`${styles.innerAgeBoxTitle}`}
        initial={{
          y: "-10px",
          opacity: 0,
        }}
        animate={{
          y: "0px",
          opacity: 1,
        }}
        transition={{
          duration: 0.5,
          delay: 0.5,
          ease: "easeInOut",
        }}
      >
        InnerAge
      </motion.span>
      <motion.span className={`${styles.innerAgeBoxNumber}`}>
        {rounded}
      </motion.span>
      <div className={`${styles.innerAgeMeter}`}>
        <div className={`${styles.innerAgeGauge}`}>
          <motion.div
            className={`${styles.innerAgePointer}`}
            initial={{
              rotate: "-100deg",
            }}
            animate={{
              rotate: "48deg",
            }}
            transition={{
              duration: 2,
              delay: 0.5,
              ease: "easeInOut",
            }}
          />
          <svg
            className={`${styles.innerAgeGaugeMeterStick}`}
            width="35"
            height="21"
            viewBox="0 0 35 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fillRule="evenodd" clipRule="evenodd" d="M33.0106 16.8893C33.0022 16.8762 32.995 16.8628 32.986 16.8501C26.23 7.30879 15.6911 1.63881 4.07123 1.29376C3.03359 1.26387 2.1701 2.08195 2.13949 3.12349C2.10917 4.16474 2.92414 5.0339 3.96062 5.06466C14.3658 5.37372 23.8059 10.4401 29.8757 18.9671L33.0106 16.8893Z" fill="#C80000" stroke="#C80000" strokeWidth="2.50641"/>
          </svg>
        </div>
      </div>
      <motion.div
        className={`${styles.innerAgeText}`}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.2,
          delay: 0.5,
          ease: "easeInOut",
        }}
      >
        Your chronological age is: 32.4
      </motion.div>
    </motion.div>
  );
}

import React from 'react';
import styles from './NavigationLink.module.less';
import { NavigationLinkPropsTypes } from './NavigationLink';

/**
 * Return HTML Anchor element with 'noreferrer noopener' rel attribute
 * @category Header
 * @subcategory Navigation Link
 * @borrows NavigationLinkPropsTypes as props
 * @component
 * @hideconstructor
 * @example
 * return <ExternalNavigationLink link="#!" label="Navigation link"/>
 */
const ExternalNavigationLink = (props: Partial<NavigationLinkPropsTypes>) => {
  const {
    link, contactLink, className, label, inNewTab,
  } = props;

  const openInNewTab: boolean = inNewTab === undefined ? true : inNewTab;
  const targetVal = openInNewTab ? '_blank' : '';
  return (
    <a
      href={link}
      className={`${styles.navlink} ${contactLink && styles.contactsLink} ${className || ''}`}
      target={targetVal}
      rel="noopener noreferrer"
    >
      {label}
    </a>
  );
};

export default ExternalNavigationLink;

import React from 'react';
import styles from '../Footer.module.less';
import SocialIcon from '../../SocialIcon/SocialIcon';

const SocialIcons = () => (
  <nav className={styles.contactsIcons}>
    <SocialIcon
      testId="facebook"
      href="http://www.facebook.com/pages/InsideTracker-from-Segterra/271094716266705"
    >
      <svg className={styles.contactsIconsIcon} id="facebook" viewBox="0 0 177 177">
        <g transform="translate(-308 -345)">
          <g fill="#fff">
            <path id="4f30a" d="M364.77 496.42v-130.8h104.64v130.8z"/>
          </g>
        </g>
        <g transform="translate(-308 -345)">
          <g fill="#fff">
            <path id="4f30b" d="M351.46 521.64v-35.03h91.34v35.03z"/>
          </g>
        </g>
        <g transform="translate(-308 -345)">
          <g fill="#4267b2">
            <path
              id="4f30c"
              d="M447.922 345.72H344.27c-19.958 0-36.138 16.174-36.138 36.132V485.5c0 19.962 16.18 36.135 36.138 36.135h59.248v-69.97h-24.04v-27.086h24.04v-19.13h.004c.102-18.33 6.74-24.59 6.74-24.59 9.75-12.669 25.039-12.29 25.039-12.29H459.622v25.507h-16.23c-11.138-.192-12.09 9.235-12.157 11.372h.007v19.131h27.175l-3.324 27.085h-23.851v69.971h16.68c19.956 0 36.133-16.173 36.133-36.135V381.852c0-19.958-16.177-36.133-36.133-36.133z"
            />
          </g>
        </g>
      </svg>
    </SocialIcon>
    <SocialIcon testId="twitter" href="https://twitter.com/insidetracker">
      <svg className={styles.contactsIconsIcon} id="twitter" viewBox="0 0 177 177">
        <g transform="translate(-528 -557)">
          <g fill="#fff">
            <path id="xa4xa" d="M542.46 703.48V577.56h143.66v125.92z"/>
          </g>
        </g>
        <g transform="translate(-528 -557)">
          <g fill="#38a1f3">
            <path
              id="xa4xb"
              d="M668.152 557.258H564.514c-19.96 0-36.143 16.183-36.143 36.145V697.05c0 19.947 16.182 36.133 36.143 36.133h103.638c19.961 0 36.144-16.186 36.144-36.133V593.403c0-19.962-16.183-36.145-36.144-36.145zm-4.417 65.754c.054 1.007.07 2.022.07 3.04 0 31.106-23.676 66.985-66.98 66.985-13.297 0-25.668-3.898-36.084-10.583 1.848.219 3.718.329 5.612.329 11.031 0 21.184-3.76 29.238-10.077-10.294-.191-18.985-6.995-21.987-16.36 1.435.278 2.907.436 4.427.436 2.144 0 4.231-.296 6.195-.832-10.76-2.155-18.879-11.67-18.879-23.078 0-.101 0-.195.006-.301a23.36 23.36 0 0 0 10.665 2.949c-6.328-4.226-10.479-11.434-10.479-19.6a23.5 23.5 0 0 1 3.186-11.824c11.604 14.24 28.963 23.603 48.525 24.599a24.068 24.068 0 0 1-.604-5.376c0-12.99 10.533-23.534 23.534-23.534 6.78 0 12.88 2.86 17.184 7.44a47.401 47.401 0 0 0 14.951-5.72 23.69 23.69 0 0 1-10.353 13.023 47.226 47.226 0 0 0 13.513-3.706 47.725 47.725 0 0 1-11.74 12.19z"
            />
          </g>
        </g>
      </svg>
    </SocialIcon>
    <SocialIcon testId="linkedin" href="http://www.linkedin.com/company/insidetracker/">
      <svg className={styles.contactsIconsIcon} id="linkedin" viewBox="0 0 177 177">
        <g transform="translate(-310 -558)">
          <g fill="#fff">
            <path
              id="bnfca"
              d="M445.688 567.072H351.99c-18.043 0-32.67 14.632-32.67 32.671v93.702c0 18.046 14.627 32.665 32.67 32.665h93.7c18.045 0 32.665-14.619 32.665-32.665v-93.702c0-18.039-14.62-32.67-32.666-32.67z"
            />
          </g>
        </g>
        <g transform="translate(-310 -558)">
          <g fill="#0077b5">
            <path
              id="bnfcb"
              d="M450.662 558.632c19.96 0 36.133 16.184 36.133 36.138v103.65c0 19.96-16.172 36.132-36.133 36.132H347.017c-19.958 0-36.14-16.172-36.14-36.133V594.77c0-19.954 16.182-36.138 36.14-36.138zm-96.4 57.59c7.77 0 14.089-6.307 14.089-14.089 0-7.788-6.318-14.11-14.09-14.11-7.794 0-14.094 6.322-14.094 14.11 0 7.782 6.3 14.09 14.095 14.09zm12.158 88.938v-78.24H342.1v78.24zm91.095-42.914c0-21.063-4.547-37.262-29.175-37.262-11.828 0-19.762 6.48-23.006 12.636H405v-10.704h-23.33v78.244h24.31v-38.699c0-10.205 1.932-20.102 14.584-20.102 12.485 0 12.64 11.68 12.64 20.752v38.05h24.31z"
            />
          </g>
        </g>
      </svg>
    </SocialIcon>
    <SocialIcon testId="youtube" href="http://www.youtube.com/user/InsideTracker2?feature=watch">
      <svg className={styles.contactsIconsIcon} id="youtube" viewBox="0 0 177 177">
        <g transform="translate(-524 -144)">
          <g fill="#ed3833">
            <path
              id="u58ja"
              d="M560.661 320.71h103.65c19.95 0 36.133-16.183 36.133-36.14V180.92c0-19.958-16.184-36.132-36.133-36.132h-103.65c-19.96 0-36.138 16.174-36.138 36.132v103.65c0 19.957 16.178 36.14 36.138 36.14z"
            />
          </g>
        </g>
        <g transform="translate(-524 -144)">
          <g fill="#fff">
            <path
              id="u58jb"
              d="M577.115 271.617h72.238c14.034 0 18.837-11.381 18.837-25.424v-26.887c0-14.043-4.203-25.422-18.246-25.422h-73.43c-14.039 0-19.74 11.379-19.74 25.422v26.887c0 14.043 6.3 25.424 20.341 25.424zm24.202-53.817v-.24l30.348 15.841-30.348 15.835z"
            />
          </g>
        </g>
        <g transform="translate(-524 -144)">
          <g fill="#fff">
            <path id="u58jc" d="M631.665 233.4l-30.348-15.84v.209l2.344 1.018z"/>
          </g>
        </g>
      </svg>
    </SocialIcon>
    <SocialIcon testId="instagram" href="https://www.instagram.com/InsideTracker">
      <svg
        className={styles.contactsIconsIcon}
        id="instagram"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        xmlSpace="preserve"
      >
        <g>
          <radialGradient
            id="SVGID_1_"
            cx="166.9048"
            cy="274.3208"
            r="47.7212"
            gradientTransform="matrix(14.2175 0 0 -14.2171 -2223.0015 4451.686)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="9.693880e-02" style={{ stopColor: '#FFD87A' }}/>
            <stop offset="0.1426" style={{ stopColor: '#FCCE78' }}/>
            <stop offset="0.2263" style={{ stopColor: '#F5B471' }}/>
            <stop offset="0.3378" style={{ stopColor: '#EB8D65' }}/>
            <stop offset="0.449" style={{ stopColor: '#E36058' }}/>
            <stop offset="0.6786" style={{ stopColor: '#CD3694' }}/>
            <stop offset="1" style={{ stopColor: '#6668B0' }}/>
          </radialGradient>
          <path
            fill="url(#SVGID_1_)"
            d="M512,395.1c0,64.6-52.3,116.9-116.9,116.9H116.9C52.3,512,0,459.7,0,395.1V117C0,52.4,52.4,0,117,0
                h276.3C458.9,0,512,53.1,512,118.7V395.1z"
          />
          <g>
            <path
              fill="#FFFFFF"
              d="M327.2,70.6H184.8c-63.1,0-114.3,51.2-114.3,114.3v142.3c0,63.1,51.1,114.2,114.3,114.2h142.3
                        c63.1,0,114.2-51.1,114.2-114.2V184.9C441.4,121.7,390.3,70.6,327.2,70.6z M405.8,313.5c0,51-41.3,92.3-92.3,92.3h-115
                        c-51,0-92.3-41.3-92.3-92.3v-115c0-51,41.3-92.3,92.3-92.3h115c51,0,92.3,41.4,92.3,92.3V313.5z"
            />
            <path
              fill="#FFFFFF"
              d="M261,159c-54,0-97.7,43.7-97.7,97.7c0,53.9,43.7,97.7,97.7,97.7c53.9,0,97.7-43.7,97.7-97.7
                        C358.6,202.7,314.9,159,261,159z M261,315.4c-32.5,0-58.8-26.3-58.8-58.8s26.3-58.8,58.8-58.8c32.4,0,58.8,26.3,58.8,58.8
                        C319.7,289.1,293.4,315.4,261,315.4z"
            />
            <path
              fill="#FFFFFF"
              d="M376.7,157.5c0,13.7-11.1,24.8-24.8,24.8c-13.7,0-24.8-11.1-24.8-24.8s11.1-24.9,24.8-24.9
                        S376.7,143.7,376.7,157.5z"
            />
          </g>
        </g>
      </svg>
    </SocialIcon>
  </nav>
);

export default SocialIcons;

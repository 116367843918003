enum ActivationState {
  NONE = 'none',
  PURCHASE = 'purchase',
  GET_TESTED = 'get-tested', // статус онбординга, не используется больше
  DIY = 'diy',
  ACCOUNT = 'account', // статус онбординга, не используется больше
  PROFILE = 'profile', // статус онбординга, не используется больше
  SETTINGS = 'settings', // статус онбординга, не используется больше
  ACTIVE = 'active',
  SEGPLAN = 'segplan',
  DEMO = 'demo',
  TMID = 'tuckmed-id',
  WAITING_FOR_APPROVAL = 'waiting-for-approval',
  WBC_PDF = 'wbc-pdf',
  CRP_PDF = 'crp-pdf',
  TST_PDF = 'tst-pdf',
  ZNC_PDF = 'znc-pdf',
  FRT_PDF = 'frt-pdf',
  ENZ_PDF = 'enz-pdf',
  GOALGETTER = 'goalgetter',
  ONBOARDING_REQUIRED = 'onboarding',
  REQUIRED_DETAILS = 'required-details',
}

export default ActivationState;

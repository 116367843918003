import React, { PropsWithChildren } from 'react';
import ErrorPage from './ErrorPage';

type State = {
  hasError: boolean
};

class ErrorBoundary extends React.Component<PropsWithChildren, State> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage/>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

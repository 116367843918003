import React from 'react';

/* Styles */
import { useSelector } from "react-redux";
import styles from './MobileMenuDots.module.less';

/* Images */
import Icon from '../../../../Icons/Icon';
import { userSelector } from "../../../../../store/user/selectors";

/**
 * Mobile menu opener
 * @category Header
 * @subcategory Mobile menu
 * @component
 * @hideconstructor
 * @example
 * return <MobileMenuDots />
 */
const MobileMenuDots = (props: { onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void; }) => {
  const { onClick } = props;
  const user = useSelector(userSelector);

  return (
    <button className={`${styles.mobileMenuDots} ${user ? styles.mobileMenuDotsRight : ''}`} tabIndex={0} type="button" onClick={onClick}>
      <Icon className={styles.mobileMenuDot} iconName="general_menuSub"/>
    </button>
  );
};

export default MobileMenuDots;

import { createBaseAxios } from '../baseAxios';
import AuthToken from '../../interfaces/Auth/AuthToken';

const adminAuthAxios = createBaseAxios();

adminAuthAxios.interceptors.request.use((config) => {
  const token: AuthToken = JSON.parse(localStorage.getItem('adminToken'));
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token.id}`;
  }

  return config;
});

export default adminAuthAxios;

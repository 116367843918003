import React from 'react';

/* Styles */
import styles from './Sandwich.module.less';

/* Images */
import Icon from '../../../../Icons/Icon';

/**
 * Sandwich button
 * @category Header
 * @component
 * @example
 * return <Sandwich />
 */
const Sandwich = (props: { onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void, className?: string }) => {
  const { onClick, className } = props;
  return (
    <div className={`${styles.sandwich}${className ? ` ${className}` : ''}`}>
      <button onClick={onClick} type="button" tabIndex={0} className={styles.sandwichLink}>
        <Icon className={styles.sandwichLine} iconName="general_menu"/>
      </button>
    </div>
  );
};

export default Sandwich;

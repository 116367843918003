import React, { CSSProperties } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styles from './Link.module.less';
import getClasses from '../../helpers/getClasses';

/**
 * Link props types definitions
 */
export type LinkPropsTypes = {
  /**
   * Link value
   */
  href?: string;
  /**
   * Text value
   */
  children: React.ReactNode;
  /**
   * Additional classes
   */
  className?: string;
  /**
   * Add click event handler function
   */
  onClick?: (e?: any) => void;
  disabled?: boolean;
  /**
   * Add secondary class to link
   */
  secondary?: boolean;
  /**
   * Add secondaryLight class to link
   */
  secondaryLight?: boolean;
  /**
  * Add secondaryLightDecoration class to link
  */
  secondaryLightDecoration?: boolean;
  /**
   * Add secondaryDark class to link
   */
  secondaryDark?: boolean;
  /**
   * Add semibold class to link
   */
  semibold?: boolean;
  /**
   * Add centered class to link
   */
  centered?: boolean;
  id: string;
  target?: string;
  rel?: string;
  /**
   * Is button download
   */
  download?: string;
  external?: boolean;
  style?: CSSProperties;
};

export const isExternal = (href: string) => {
  if (typeof href?.match !== 'function') return false;
  return !!(href.match(/^(http(s)?|ftp(s))?:\/\//) || href.match(/^mailto:/));
};

/**
 * Simple link component
 * @component
 * @category Components
 * @subcategory Navigation
 * @hideconstructor
 * @borrows LinkPropsTypes as props
 * @example
 * return <Link>link default primary</Link>
 */
const Link = ({
  href,
  className,
  onClick,
  secondary,
  secondaryLight,
  secondaryDark,
  secondaryLightDecoration,
  semibold,
  centered,
  id,
  target,
  rel,
  download,
  external,
  style,
  ...restProps
}: LinkPropsTypes) => {
  const props = {
    className: getClasses({
      [styles.link]: true,
      [styles.linkPrimary]: !(secondary || secondaryLight || secondaryDark || secondaryLightDecoration),
      [styles.linkSecondary]: secondary,
      [styles.linkSecondaryLight]: secondaryLight,
      [styles.linkSecondaryLightDecoration]: secondaryLightDecoration,
      [styles.linkSecondaryDark]: secondaryDark,
      [styles.linkSemibold]: semibold,
      [styles.linkCentered]: centered,
      [className]: true,
    }),
    onClick,
    ...restProps,
  };

  if (onClick) {
    return <button id={id} style={style} type="button" {...props}/>;
  }

  if (external || isExternal(href)) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        id={id}
        href={href}
        download={download}
        target={target}
        rel={rel}
        style={style}
        {...props}
      />
    );
  }

  if (href.includes('#')) {
    return (
      <HashLink
        to={href}
        style={style}
        {...props}
      />
    );
  }

  return (
    <RouterLink
      to={href}
      style={style}
      {...props}
    />
  );
};

export default Link;

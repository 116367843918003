import { Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import HomePage from './Home/Home';
import AboutPage from './About/AboutLazy';
import TestimonialsPage from './Testimonials/TestimonialsLazy';
import Science from './Science/ScienceLazy';
import TermsOfService from './terms/TermsOfService/TermsOfServiceLazy';
import ProductConsent from './terms/ProductConsent/ProductConsentLazy';
import PrivacyPolicy from './terms/PrivacyPolicy/PrivacyPolicyLazy';
import ResearchConsent from './terms/ResearchConsent/ResearchConsentLazy';
import TeamPage from './Team/TeamLazy';
import ProSports from '../landing/ProSports/ProSportsLazy';
import Fitness from '../landing/Fitness/FitnessLazy';
import Military from '../landing/Military/MilitaryLazy';
import Endurance from '../landing/Endurance/EnduranceLazy';
import Careers from './Careers/CareersLazy';
import JobPreview from './Job/JobPreview';
import Job from './Job/JobLazy';
import PressPage from './Press/PressPageLazy';
import Tour from './Tour/TourLazy';
import PageNotFound from './PageNotFound/PageNotFoundLazy';
import { configurationSelector } from '../../store/common/selectors';
import ResponsibleDisclosurePolicy
  from './terms/ResponsibleDisclosurePolicy/ResponsibleDisclosurePolicy';
import DnaKitRegisterLazy from '../customer/DnaKit/parts/Register/DnaKitRegisterLazy';

const redirectComponent = (url: string) => (): null => {
  window.location.href = url;
  return null;
};

const PublicSection = () => {
  const configuration = useSelector(configurationSelector);

  return (
    <Switch>
      <Route exact path="/" component={HomePage}/>
      <Route exact path="/about" component={AboutPage}/>
      <Route
        exact
        path="/innerage"
        component={redirectComponent(`${configuration.shopifyUrl}/products/innerage-2-0`)}
      />
      <Route exact path="/testimonial" component={TestimonialsPage}/>
      <Route exact path="/science" component={Science}/>
      <Route exact path="/terms-of-service" component={TermsOfService}/>
      <Route exact path="/responsible-disclosure-policy" component={ResponsibleDisclosurePolicy}/>
      <Route exact path="/product-consent" component={ProductConsent}/>
      <Route exact path="/policy-privacy" component={PrivacyPolicy}/>
      <Route exact path="/research-consent" component={ResearchConsent}/>
      <Route exact path="/team" component={TeamPage}/>
      <Route
        exact
        path="/public/landing/dna-details"
        component={redirectComponent(`${configuration.shopifyUrl}/products/dna-data-upload`)}
      />
      <Route exact path="/pro-sports" component={ProSports}/>
      <Route exact path="/fit" component={Fitness}/>
      <Route exact path="/military" component={Military}/>
      <Route exact path="/endurance" component={Endurance}/>
      <Route
        exact
        path="/dna-kit/"
        component={redirectComponent(`${configuration.shopifyUrl}/products/dna-kit`)}
      />
      <Route
        exact
        path="/bloodresultsupload"
        component={redirectComponent(`${configuration.shopifyUrl}/products/blood-results-upload`)}
      />
      <Route exact path="/careers" component={Careers}/>
      <Route exact path="/careers/preview" component={JobPreview}/>
      <Route exact path="/careers/job/:career_id" component={Job}/>
      <Route exact path="/press-page" component={PressPage}/>
      <Route exact path="/press-releases">
        <Redirect to="/press-page"/>
      </Route>
      <Route exact path="/public/landing/dna-login" component={DnaKitRegisterLazy}/>
      <Route exact path="/diy">
        <Redirect to="/bloodresultsupload"/>
      </Route>
      <Route exact path="/public/landing/pro-sports">
        <Redirect to="/pro-sports"/>
      </Route>
      <Route exact path="/public/landing/fit">
        <Redirect to="/fit"/>
      </Route>
      <Route exact path="/public/landing/military">
        <Redirect to="/military"/>
      </Route>
      <Route exact path="/public/landing/endurance">
        <Redirect to="/endurance"/>
      </Route>
      <Route path="/tour" component={Tour}/>
      <Route
        exact
        path="/how-to-redeem-your-gift-card"
        component={redirectComponent(`${configuration.shopifyUrl}/products/digital-gift-card`)}
      />
      <Route component={PageNotFound}/>
    </Switch>
  );
};
export default PublicSection;

import React from 'react';
import { Provider } from 'react-redux';
// @ts-ignore
import TagManager from 'react-gtm-module';
import FullStory from 'react-fullstory';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import { datadogLogs } from '@datadog/browser-logs';
import {
  datadogRum,
  RumResourceEvent,
  RumFetchResourceEventDomainContext, RumXhrResourceEventDomainContext,
} from '@datadog/browser-rum';
import App from './App';
import { store } from './store/index';
import config from './config';

datadogRum.init({
  applicationId: '56c41179-02cd-4001-8b09-e413f54a1829',
  clientToken: 'pub0cd2cd324299f3a7540433fec80ddf18',
  site: 'datadoghq.com',
  service: 'it-react',
  env: config.hostName.replace(/https?:\/\//, ''),
  sampleRate: 100,
  sessionReplaySampleRate: 20,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  beforeSend(event, context) {
    if (event.type === 'resource') {
      if ((context as any)?.requestInit?.signal?.reason) {
        datadogLogs.logger.debug(
          `${event.resource?.type || 'Loading'} failed ${(context as RumFetchResourceEventDomainContext)?.requestInput}`,
          {
            error: {
              stack: (context as any)?.requestInit?.signal?.reason?.stack,
            },
          },
        );
      }
    }
  },
});
datadogRum.setGlobalContext({ host: config.hostName.replace(/https?:\/\//, '') });

datadogRum.startSessionReplayRecording();

datadogLogs.init({
  clientToken: 'pub21e47d3514c912779dd6b45611055efd',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  forwardConsoleLogs: 'all',
  service: 'it-react',
  // eslint-disable-next-line consistent-return
  beforeSend: (log) => {
    if ([
      '[HMR]',
      '[webpack-dev-server]',
      'Environment:',
    ].some((str) => log.message.startsWith(str))) {
      return false;
    }
  },
});

datadogLogs.setGlobalContext({ host: config.hostName.replace(/https?:\/\//, '') });

if (config.googleTagsKey) {
  const tagManagerArgs = {
    gtmId: config.googleTagsKey,
  };
  setTimeout(() => {
    TagManager.initialize(tagManagerArgs);
  }, 10000); // crutch to improve first paint
}

if (config.isProduction) {
  setTimeout(() => {
    // @ts-ignore
    import('./counters_and_trackers/impact');
    // @ts-ignore
    import('./counters_and_trackers/hubspot_tracking_code');
    // @ts-ignore
    import('./counters_and_trackers/criteo')
      .then((module) => module.initCriteo());
    // @ts-ignore
    import('./counters_and_trackers/podsights_pixel');
    // @ts-ignore
    import('./counters_and_trackers/shareasale_master_tracker');
    // @ts-ignore
    import('./counters_and_trackers/nativo_tracking');
    // @ts-ignore
    import('./counters_and_trackers/sleeknote_discount');
  }, 10000); // crutch to improve first paint
}
/*

if (config.facebookPixelKey) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _AdvancedMatching = { };
  const options = {
    autoConfig: true,
    debug: false,
  };

  // @ts-ignore
  ReactFacebookPixel.init(config.facebookPixelKey, _AdvancedMatching, options);
}
*/

// eslint-disable-next-line import/prefer-default-export
export const history = createBrowserHistory();

const root = createRoot(document.getElementById('app'));
root.render(
  <Provider store={store}>
    <Router history={history}>
      <App/>
      {config.isProduction && <FullStory org={config.fullStoryOrgId}/>}
    </Router>
  </Provider>,
);

import React from 'react';
import { useSelector } from 'react-redux';
import styles from './Footer.module.less';
import FooterMenu from './parts/FooterMenu/FooterMenu';
import { FooterCopyright } from './parts/FooterCopyright';
import SocialIcons from './parts/SocialIcons';
import { isRestrictedPopupOpen } from '../../store/common/selectors';

const Footer = () => {
  const restrictedPopupOpen = useSelector(isRestrictedPopupOpen);

  return (
    <footer className={`${styles.footer} ${restrictedPopupOpen ? ` ${styles.footerOver}` : ''}`}>
      <div className={styles.container1170}>
        <div className={styles.footerFlex}>
          <FooterMenu/>
          <div className={styles.footerContacts}>
            <div className={styles.contactsIconsWrap}>
              <p className={styles.footerTitle}>Subscribe</p>
              <SocialIcons/>
            </div>
            <div className={`${styles.footerContactsBlock} ${styles.footerContactsBlockDesktop}`}>
              <a
                data-test-id="supportCenter"
                target="_blank"
                rel="noreferrer"
                href="https://insidetracker-faq.gorgias.help/"
                className={styles.footerTitle}
              >
                Support center
              </a>
              <a
                data-test-id="contactus"
                href="https://insidetracker-faq.gorgias.help/en-US/how-can-i-contact-you-288947"
                className={`${styles.footerContactsItem} ${styles.linkSecondaryLight}`}
                target="_blank"
                rel="noreferrer"
              >
                Contact us
              </a>
            </div>
            <div className={`${styles.footerContactsBlock} ${styles.footerContactsBlockMobile}`}>
              <div className={styles.footerContactsBlock}>
                <p className={`${styles.footerContactsItem} ${styles.footerTitle}`}>Support center</p>
                <a
                  className={`${styles.footerContactsItem} ${styles.linkSecondaryLight}`}
                  href="//insidetracker-faq.gorgias.help/en-US/how-can-i-contact-you-288947"
                  data-test-id="contactus"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </div>
        <FooterCopyright/>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';

/**
 * Loader props types definitions
 */
type LoaderPropsTypes = {
  /**
   *  Rings color. Default - white
   */
  color?: string
};

/**
 * Loader component used in buttons to show loading state
 * @component
 * @category Components
 * @subcategory Utils
 * @borrows LoaderPropsTypes as props
 * @hideconstructor
 * @example
 * return <Loader color="#4268f6"/>
 */
const Loader = (props: LoaderPropsTypes) => {
  const { color } = props;
  return (
    <svg
      style={{ verticalAlign: 'middle', marginBottom: '2.8px' }}
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 30 30"
    >
      <g style={{ transformOrigin: 'center' }}>
        <circle
          cx={15}
          cy={15}
          r={13}
          fill="transparent"
          opacity={0.4}
          stroke={color || 'white'}
          strokeWidth={3}
        />
        <circle
          id="loadingRing"
          cx={15}
          cy={15}
          r={13}
          fill="transparent"
          stroke={color || 'white'}
          strokeWidth={3}
          strokeDashoffset={90}
          strokeDasharray={90}
        >
          <animate
            attributeName="stroke-dashoffset"
            repeatCount="indefinite"
            from={90}
            to={-90}
            dur="4s"
            fill="freeze"
          />
        </circle>
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0"
          to="360"
          begin="0"
          dur="1.5s"
          repeatCount="indefinite"
        />
      </g>
    </svg>
  );
};

export default Loader;

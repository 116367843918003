/**
 * Generates a className string conditionally
 * @param obj {Record<string, boolean>} - object with classes
 * @category Helpers
 * @returns {string} - string to use in className react prop
 * {
 *   class1: true,
 *   class2: false
 * }
 * will return string 'class1'
 */
const getClasses = (obj: Record<string, boolean> | string[]) => {
  if (typeof obj !== 'object') return '';

  if (obj instanceof Array) {
    return obj.filter(Boolean)
      .join(' ');
  }

  return Object.keys(obj)
    .filter((key) => key !== 'undefined')
    .map((key) => (obj[key] ? key : ''))
    .filter((val) => !!val)
    .join(' ');
};

export default getClasses;

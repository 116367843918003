import React, {
  lazy,
  Suspense,
  useEffect,
  useState,
} from 'react';
import Spinner from '../../../components/Spinner/Spinner';
import landingApi from '../../../services/landingApi';
import { CareerType } from '../../../interfaces/Public/CareerType';

const Job = lazy(() => import('./Job'));
export default (props: any) => {
  const {
    match,
  } = props;
  const careerId = +match.params.career_id;
  const [careerItem, setCareerItem] = useState({} as CareerType);

  useEffect(() => {
    (async () => {
      const response = await landingApi.getCareer(careerId);
      setCareerItem(response);
    })();
  }, []);

  return (
    <Suspense fallback={<Spinner show/>}>
      <Job jobInfo={careerItem}/>
    </Suspense>
  );
};

import React, { ReactElement, useState } from 'react';
import styles from './NavigationLink.module.less';
import Icon from '../../../../Icons/Icon';
import { onKeyEnter } from '../../../../../helpers/onKeyEnter';

/**
 * NavigationCollapsePropsTypes props types definitions
 */
type NavigationCollapsePropsTypes = {
  /** Collapse trigger visible name */
  label: string,
  /** Extra classes */
  className?: string,
  /** Handler collapse function */
  onCollapse?: () => void,
  /** Flag to show collapsed menu on first render */
  show?: boolean,
  /** Collection of children elements */
  children: ReactElement | ReactElement[]
};

/**
 * Navigation collapse menu component
 * @category Header
 * @subcategory Navigation Link
 * @borrows NavigationCollapsePropsTypes as props
 * @component
 * @hideconstructor
 * @example
 * return (<NavigationCollapse label="Bloodwork" show>
 * <NavigationLink link={"#!"} label={"Analysis & Recommendations"} />
 * <NavigationLink link={"#!"} label={"Table View"} />
 * <NavigationLink link={"#!"} label={"Add Test Results"} />
 * <NavigationLink link={"#!"} label={"Orders"} />
 * </NavigationCollapse>)
 */
const NavigationCollapse = (props: NavigationCollapsePropsTypes) => {
  const { children, label } = props;
  const [show, setShow] = useState(false);

  /**
   * Click handler that change show flag
   */
  const onCollapse = () => {
    setShow(!show);
  };

  return (
    <div
      onClick={onCollapse}
      onKeyDown={onKeyEnter(onCollapse)}
      role="button"
      tabIndex={0}
      className={`${styles.collapse}${show ? ` ${styles.show}` : ''}`}
    >
      <div className={`${styles.navlink}${show ? ` ${styles.active}` : ''}`}>
        <Icon className={styles.collapseIcon} iconName="general_arrow" />
        {label}
      </div>
      <ul className={styles.collapseList}>
        {React.Children.map(children, (item) => (
          <li className={styles.collapseItem}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavigationCollapse;

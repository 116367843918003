import { MarkerRange } from '../interfaces/Blood/MarkerRange';
import {
  STATE_NEAR_NORMAL_HIGH,
  STATE_BORDERLINE_LOW,
  STATE_LOW,
  STATE_NORMAL_HIGH,
  STATE_HIGH,
  STATE_NORMAL_LOW,
  STATE_BORDERLINE_HIGH,
  STATE_NEAR_NORMAL_LOW,
  STATE_OPTIMIZED,
  OptimizationState,
  ScoreState,
} from '../Constants/BloodResultStatus';

export const getBloodStatus = (value: number, ranges: MarkerRange) => {
  let state;
  const {
    maxA,
    minA,
    maxG,
    minG,
    maxBorderLine,
    minBorderLine,
    maxNearNormal,
    minNearNormal,
  } = ranges;

  if (value >= minBorderLine
    && value < minNearNormal) {
    // borderline: -3
    state = STATE_BORDERLINE_LOW;
  }

  if (value > maxNearNormal
    && value <= maxBorderLine) {
    // borderline: 3
    state = STATE_BORDERLINE_HIGH;
  }

  if (value > minNearNormal && value <= minA) {
    // nearNormal: -2
    state = STATE_NEAR_NORMAL_LOW;
  }

  if (value > maxA && value <= maxNearNormal) {
    // nearNormal: 2
    state = STATE_NEAR_NORMAL_HIGH;
  }

  if (value < minBorderLine) {
    // red: -4
    state = STATE_LOW;
  }

  if (value > maxBorderLine) {
    // red: 4
    state = STATE_HIGH;
  }

  if (value >= minA && value < minG) {
    // yellow: -1
    state = STATE_NORMAL_LOW;
  }

  if (value > maxG && value <= maxA) {
    // yellow: 1
    state = STATE_NORMAL_HIGH;
  }

  if (value >= minG && value <= maxG) {
    // green: 0
    state = STATE_OPTIMIZED;
  }

  return state;
};

export const getBloodValuesByStatus = (state: OptimizationState, ranges: MarkerRange | null | undefined) => {
  if (!ranges) {
    return [];
  }

  const {
    maxA,
    minA,
    maxG,
    minG,
    maxBorderLine,
    minBorderLine,
    maxNearNormal,
    minNearNormal,
  } = ranges;

  switch (state) {
    case STATE_BORDERLINE_LOW:
      return [minBorderLine, minNearNormal];
    case STATE_BORDERLINE_HIGH:
      return [maxNearNormal, maxBorderLine];
    case STATE_NEAR_NORMAL_LOW:
      return [minNearNormal, minA];
    case STATE_NEAR_NORMAL_HIGH:
      return [maxA, maxNearNormal];
    case STATE_LOW:
      return [0, minBorderLine];
    case STATE_HIGH:
      return [maxBorderLine, Infinity];
    case STATE_NORMAL_LOW:
      return [minA, minG];
    case STATE_NORMAL_HIGH:
      return [maxG, maxA];
    case STATE_OPTIMIZED:
      return [minG, maxG];
    default:
      return [];
  }
};

export const mapStateToScores: Record<ScoreState, [number, number]> = {
  0: [85, 100],
  1: [70, 85],
  2: [50, 70],
  3: [0, 50],
};

export const getStateByScore = (score?: number): ScoreState => (typeof score === 'number' ? +Object
  .entries(mapStateToScores)
  .find(([state, [minScore]]) => score >= minScore)[0] as ScoreState : null);

// returns OptimisationState with same color like passed ScoreState, for using in CSS
export const getScoreClass = (state: ScoreState) => state + Math.floor(state / 2);

import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styles from './FooterMenuItem.module.less';
import ITrackerLink from '../../../Link/Link';

/**
 * Footer menu props types definitions
 */
type FooterMenuItemPropsTypes = {
  /**
   * Link destination
   */
  link: string,
  /**
   * link visible label
   */
  label: string,
  /**
   * link open in new tab
   */
  inNewTab?: boolean,

  testId: string
};

export const FooterMenuItem = React.memo((props: FooterMenuItemPropsTypes) => {
  const { link, label, testId } = props;
  return (
    <Link data-test-id={testId} to={link} className={`${styles.footerMenuItem} ${styles.linkSecondary}`}>
      {label}
    </Link>
  );
});

export const ExternalFooterMenuItem = React.memo((props: FooterMenuItemPropsTypes) => {
  const {
    link, label, inNewTab, testId,
  } = props;
  const openInNewTab: boolean = inNewTab === undefined ? false : inNewTab;
  const targetVal = openInNewTab ? '_blank' : '';
  return (
    <ITrackerLink
      id={label}
      href={link}
      secondary
      className={`${styles.footerMenuItem}`}
      target={targetVal}
      rel="noopener noreferrer"
      data-test-id={testId}
    >
      {label}
    </ITrackerLink>
  );
});

// временно через a, поменять на hashlink
export const FooterMenuItemHash = (props: FooterMenuItemPropsTypes) => {
  const { link, label, testId } = props;
  return (
    <HashLink
      id={label}
      to={link}
      data-test-id={testId}
      className={`${styles.footerMenuItem} ${styles.linkSecondary}`}
      scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
    >
      {label}
    </HashLink>
  );
};

import { Builder, withChildren } from "@builder.io/react";

import themeButtonContainer from "./themeButtonContainer.jsx";

const themeButtonContainerWithChild = withChildren(themeButtonContainer);

Builder.registerComponent(themeButtonContainerWithChild, {
    name: "Theme Button Container",
    defaultChildren: [
        {
            '@type' : '@builder.io/sdk:Element',
            component: {
                name: 'Theme Button',
                options: {
                    buttonInfo: {
                        label: 'Get Started',
                        url: "#"
                    }
                }
            }
        }
    ],
    childRequirements: {
        message: 'You can only put Buttons or Theme Buttons in a Button Container',
        query: {
            'component.name': { $in: ['Button', 'Theme Button'] },
        },
    },
})
import userAuthorizedAxios from './userAuthorizedAxios';

export default {
  parseFiles: (fileIds: string[]): Promise<any> => userAuthorizedAxios
    .post<any>('/api/customer/v1/ocr/submit-files', { files: fileIds })
    .then((res) => res.data),

  submitResults: (groupId: number, results: any[]): Promise<any> => userAuthorizedAxios
    .post<any>('/api/customer/v1/ocr/confirm-results', {
    groupId,
    results,
  })
    .then((res) => res.data),
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpiredSessionPopup from './ExpiredSessionPopup';
import accountApi from '../../services/Customer/accountApi';
import { fetchUserProfile } from '../../store/user/actions';
import { userSelector } from '../../store/user/selectors';
import TimeoutSessionPopup from './TimeoutSessionPopup';

const secondsToShowExpirationPopup = 60;

const SessionPopupWidget = () => {
  const dispatch = useDispatch();
  const [expirationTimeout, setExpirationTimeout] = useState<number | null>(null);
  const [secondsToExpiration, setSecondsToExpiration] = useState(0);
  const [showExpirationPopup, setShowExpirationPopup] = useState(false);

  const user = useSelector(userSelector);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (secondsToExpiration > 0) {
        setSecondsToExpiration((seconds) => seconds - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [secondsToExpiration]);

  const callBack = async () => {
    let timeToLogout: number | null = null;
    try {
      const expirationResponse = await accountApi.checkAuthExpiration();
      timeToLogout = expirationResponse.value;
    } catch (e) {
      return;
    }

    if (!timeToLogout) {
      return;
    }

    if (timeToLogout > secondsToShowExpirationPopup) {
      const timeOut = window.setTimeout(callBack, (timeToLogout - secondsToShowExpirationPopup) * 1000);
      clearTimeout(expirationTimeout);
      setExpirationTimeout(timeOut);
      return;
    }
    clearTimeout(expirationTimeout);
    setSecondsToExpiration(timeToLogout);
    setShowExpirationPopup(true);
  };

  useEffect(() => {
    if (user === null) {
      clearTimeout(expirationTimeout);
      return;
    }

    if (expirationTimeout) {
      return;
    }

    (async () => {
      await callBack();
    })();
  }, [user]);
  const onContinueSession = async () => {
    await dispatch(fetchUserProfile());
    setShowExpirationPopup(false);
    await callBack();
  };

  return (
    <>
      {showExpirationPopup && secondsToExpiration !== 0 && (
      <TimeoutSessionPopup
        opened
        secondsToExpire={secondsToExpiration}
        onContinue={onContinueSession}
      />
      )}
      {showExpirationPopup && secondsToExpiration === 0 && (
      <ExpiredSessionPopup
        opened
        onClose={onContinueSession}
      />
      )}
    </>
  );
};

export default SessionPopupWidget;

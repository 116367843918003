import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { closePopup } from './index';
import coachApi from '../../services/Admin/coachApi';
import { Order, Team, UserInTeam } from './types';
import { RootState } from '../index';

const modifyArchivedUsersStatus = (user: UserInTeam) => ({
  ...user,
  status: !user.teamIds.length ? 'Archived' : user.status,
  initialStatus: user.status,
});

export const fetchUsers = createAsyncThunk(
  'coach/fetchUsers',
  async () => {
    const response = await coachApi.getUsers();
    return response.users.map(modifyArchivedUsersStatus);
  },
);

export const fetchTeams = createAsyncThunk(
  'coach/fetchTeams',
  async () => await coachApi.getTeams(),
);

export const fetchCoachInitialData = createAsyncThunk(
  'coach/fetchInitialData',
  async () => {
    const [
      profile,
      users,
      teams,
      availablePlans,
      orders,
    ] = await Promise.all([
      coachApi.getCoach(),
      coachApi.getUsers(),
      coachApi.getTeams(),
      coachApi.getAvailablePlans(),
      coachApi.getOrders(),
    ]);

    return {
      profile: profile.data,
      users: users.users.map(modifyArchivedUsersStatus),
      teams,
      availablePlans: availablePlans.data,
      orders: orders.data.tests as Order[],
    };
  },
);

export const addTeam = createAsyncThunk(
  'coach/fetchAddTeam',
  async (data: { name: string }, thunkAPI) => {
    await coachApi.addTeam(data.name);
    thunkAPI.dispatch(closePopup());
    thunkAPI.dispatch(fetchTeams());
  },
);

export const editTeam = createAsyncThunk(
  'coach/fetchEditTeam',
  async (team: Team) => {
    const response = await coachApi.editTeam(team.id, team.title);
    return {
      response,
      team,
    };
  },
);

export const fetchUploadTeamLogo = createAsyncThunk(
  'coach/fetchUploadTeamLogo',
  async (data: { teamId: number, formData: FormData }) => {
    await coachApi.uploadTeamLogo(data.teamId, data.formData);
    return data.teamId;
  },
);

export const fetchDeleteTeamLogo = createAsyncThunk(
  'coach/fetchDeleteTeamLogo',
  async (teamId: number) => {
    await coachApi.deleteTeamLogo(teamId);
    return teamId;
  },
);

export const fetchOrders = createAsyncThunk(
  'coach/fetchOrders',
  async () => {
    const orders = await coachApi.getOrders();
    return orders.data.tests as Order[];
  },
);

export const fetchMoveToTeam = createAsyncThunk(
  'coach/fetchMoveTeam',
  async (data: { customersIds: number[], teamIdFrom: number, teamIdTo: number }, thunkAPI) => {
    await Promise.all(data.customersIds.map((id) => coachApi.moveToTeam(
      id,
      data.teamIdFrom,
      data.teamIdTo,
    )));
    thunkAPI.dispatch(closePopup());
    thunkAPI.dispatch(fetchUsers());
  },
);

export const fetchRequestPermissions = createAsyncThunk(
  'coach/fetchRequestPermissions',
  async (data: { customerId: number, teamId: number }, thunkAPI) => {
    const dataResponse = await coachApi.requestPermissions(data.customerId, data.teamId);
    thunkAPI.dispatch(fetchUsers());

    return dataResponse;
  },
  {
    serializeError(error: AxiosError) {
      return {
        code: error?.code,
        name: error?.name,
        stack: error?.stack,
        message: (error?.response?.data as any)?.message || error?.message,
      };
    },
  },
);

export const fetchAssignTest = createAsyncThunk(
  'coach/fetchAssignTest',
  async (data: { customerId: number, testId: number }, thunkAPI) => {
    const { customerId, testId } = data;
    const res = await coachApi.assignTestToUser(testId, customerId);
    thunkAPI.dispatch(closePopup());
    thunkAPI.dispatch(fetchUsers());
    thunkAPI.dispatch(fetchOrders());
    return res;
  },
);

export const fetchArchiveUsers = createAsyncThunk(
  'coach/fetchArchivedUsers',
  async (data: { customersIds: number[], teamIdFrom: number }, thunkAPI) => {
    await Promise.all(data.customersIds.map((id) => coachApi.deleteUserFromTeam(id, data.teamIdFrom)));
    thunkAPI.dispatch(closePopup());
    thunkAPI.dispatch(fetchUsers());
  },
);

export const fetchDeleteTeam = createAsyncThunk(
  'coach/fetchDeleteTeam',
  async (_, thunkAPI) => {
    const {
      coach: {
        markedToDeleteTeamId,
        markedToArchiveUsersIds,
      },
    } = thunkAPI.getState() as RootState;
    if (markedToArchiveUsersIds.length) {
      await Promise.all(markedToArchiveUsersIds.map((id: number) => coachApi.deleteUserFromTeam(id, markedToDeleteTeamId)));
    }
    await coachApi.deleteTeam(markedToDeleteTeamId);
    thunkAPI.dispatch(fetchUsers());
  },
);

export const fetchAddUserToTeam = createAsyncThunk(
  'coach/fetchAddUserToTeam',
  async (data: { userId: number, teamId: number }, thunkAPI) => {
    const { userId, teamId } = data;
    const res = await coachApi.addUserToTeam(userId, teamId);
    thunkAPI.dispatch(closePopup());
    thunkAPI.dispatch(fetchUsers());
    return res;
  },
);

export const fetchMoveUsers = createAsyncThunk(
  'coach/fetchMoveUsers',
  async (data: { users: UserInTeam[], teamIdFrom: number, teamIdTo: number }, thunkAPI) => {
    const { users, teamIdFrom, teamIdTo } = data;
    await Promise.all(users.filter((user) => +user.teamIds[0] !== teamIdTo)
      .map((user) => {
        if (!user.teamIds.length) return coachApi.addUserToTeam(user.id, teamIdTo);
        if (teamIdTo === 0) return coachApi.deleteUserFromTeam(user.id, +user.teamIds[0]);
        return coachApi.moveToTeam(user.id, teamIdFrom, teamIdTo);
      }));
    thunkAPI.dispatch(closePopup());
    thunkAPI.dispatch(fetchUsers());
  },
);

export default {
  fetchCoachInitialData,
  fetchTeams,
  addTeam,
  editTeam,
  fetchUploadTeamLogo,
  fetchDeleteTeamLogo,
  fetchMoveToTeam,
  fetchAssignTest,
  fetchArchiveUsers,
  fetchMoveUsers,
};

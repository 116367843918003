import { createAsyncThunk } from '@reduxjs/toolkit';
import adminAuthApi from '../../services/Admin/adminAuthApi';
import coachApi from '../../services/Admin/coachApi';
import adminProfileApi from '../../services/Admin/adminProfileApi';

export const fetchAdminProfile = createAsyncThunk(
  'admin/fetchAdminProfile',
  async () => await adminProfileApi.getProfile(),
);

export const loginAdminByEmail = createAsyncThunk(
  'admin/fetchAdminLoginByEmail',
  async (authData: { email: string, password: string, stayLoggedIn: boolean }) => {
    const token = await adminAuthApi.loginByEmail(authData);
    return token.token;
  },
);

export const loginByGuid = createAsyncThunk(
  'admin/loginByGuid',
  async (guid: string) => {
    await coachApi.loginByGuid(guid);
  },
);

export default {
  fetchAdminProfile,
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import bruApi from "../../../services/Customer/bruApi";

export const parseFiles = createAsyncThunk(
  'user/parseFiles',
  async (fileIds: string[]) => await bruApi.parseFiles(fileIds),
);

export default {
  parseFiles,
};
